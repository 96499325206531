import { Typography, Grid, Paper, styled } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));
const ComingSoon = ({ text }: { text: string }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Item>
          <Typography variant="h4">Coming Soon</Typography>
          <p>{text}</p>
        </Item>
      </Grid>
    </Grid>
  );
};

export default ComingSoon;
