import { FC, useEffect, useState } from "react";
import FormItemContainer from "../../../atoms/FormItemContainer";
import {
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import {
  MAX_CHATBOX_TITLE_SIZE,
  MAX_DESCRIPTION_SIZE,
  MAX_WELCOME_MESSAGE_SIZE,
} from "../../../utils/config";
import { ChatbotFormType } from "..";
import { FeedbackViewEnum } from "../../../generated/graphql";

interface ConfigurationProps {
  formValues: ChatbotFormType;
  setFormValues: (formValue: ChatbotFormType) => void;
  handleChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    checked?: boolean | string
  ) => void;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  open: boolean;
}

const Configuration: FC<ConfigurationProps> = ({
  formValues,
  open,
  handleChange,
  onSubmit,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const chatboxTitleError =
    formValues.chatboxTitle.length < MAX_CHATBOX_TITLE_SIZE && isSubmitted;
  const descriptionError =
    formValues.description &&
    formValues.description.length < MAX_DESCRIPTION_SIZE &&
    isSubmitted;
  const welcomeMessageError =
    formValues.welcomeMessage.length < MAX_WELCOME_MESSAGE_SIZE && isSubmitted;
  const handleSubmit: React.FormEventHandler<HTMLFormElement> | undefined = (
    event
  ) => {
    event.preventDefault();
    setIsSubmitted(true);

    onSubmit(event);
  };

  useEffect(() => {
    if (open) {
      setIsSubmitted(true);
    }
  }, [open]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormItemContainer>
          <TextField
            label="Title"
            name="chatboxTitle"
            value={formValues.chatboxTitle}
            onChange={handleChange}
            error={chatboxTitleError}
            helperText={
              formValues.chatboxTitle.length < MAX_CHATBOX_TITLE_SIZE &&
              `Chatbox title size should have at least ${MAX_CHATBOX_TITLE_SIZE} characters. (${formValues.chatboxTitle.length}/${MAX_CHATBOX_TITLE_SIZE})`
            }
            size="small"
            fullWidth
            required
          />
        </FormItemContainer>
        <FormItemContainer>
          <TextField
            label="Welcome Message"
            name="welcomeMessage"
            value={formValues.welcomeMessage}
            onChange={handleChange}
            error={welcomeMessageError}
            helperText={
              formValues.welcomeMessage.length < MAX_WELCOME_MESSAGE_SIZE &&
              `Welcome message should have least ${MAX_WELCOME_MESSAGE_SIZE} characters (${formValues.welcomeMessage.length}/${MAX_WELCOME_MESSAGE_SIZE})`
            }
            fullWidth
            multiline
            required
            rows={4}
          />
        </FormItemContainer>

        <FormItemContainer>
          <TextField
            label="Description"
            name="description"
            value={formValues.description}
            onChange={handleChange}
            error={!!descriptionError}
            helperText={
              descriptionError &&
              `remain ${
                MAX_DESCRIPTION_SIZE - (formValues.description?.length ?? 0)
              }/${MAX_DESCRIPTION_SIZE}`
            }
            fullWidth
            multiline
            rows={4}
          />
        </FormItemContainer>
      </form>
    </>
  );
};

export default Configuration;
