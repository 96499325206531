import { Box, Tooltip } from "@mui/material";
import { FC, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

const BoxWithCopyButton: FC<{ text: string }> = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
      <div className="outline-none border border-gray-400 rounded-md p-2 flex flex-row" style={{alignItems: "center"}}>
        <span style={{overflowWrap: "anywhere"}}>{text}</span>
        <CopyToClipboard text={text} onCopy={handleCopy}>
        <Tooltip title={copied ? "Copied!" : "Copy"}>
          <span className={"ml-2 cursor-pointer"}>
            <ContentCopyIcon />
          </span>
        </Tooltip>
      </CopyToClipboard>
      </div>
  );
};

export default BoxWithCopyButton;
