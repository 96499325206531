import { MenuItem, Select, TextField } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { KnowledgeBaseProps } from ".";

export const SQL_TYPES = ["postgresql", "mysql"];

const SQLConfigForm: FC<KnowledgeBaseProps> = ({
  formValues,
  loading,
  setFormValues,
}) => {
  const { id } = useParams();

  const handleChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined = (e) => {
    const { name, value } = e.target;

    updateFormValues(name, value);
  };

  const updateFormValues = (name: string, value: string) => {
    if (formValues) {
      setFormValues((prevValues) => {
        if (prevValues.sqlConfig) {
          return {
            ...prevValues,
            sqlConfig: { ...prevValues.sqlConfig, [name]: value },
          };
        }
        return prevValues;
      });
    }
  };

  return (
    <form className="w-full">
      <div className="flex place-content-around">
        <TextField
          style={{ marginRight: 10 }}
          name="dbName"
          label="Database Name"
          required
          value={formValues.sqlConfig?.dbName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          size="small"
        />

        <TextField
          style={{ marginRight: 10 }}
          name="dbUser"
          label="Database User"
          required
          value={formValues.sqlConfig?.dbUser}
          onChange={handleChange}
          fullWidth
          margin="normal"
          size="small"
        />
        <TextField
          name="dbPass"
          type="password"
          label="Database Password"
          required
          value={formValues.sqlConfig?.dbPass}
          onChange={handleChange}
          fullWidth
          margin="normal"
          size="small"
        />
      </div>
      <div className="flex place-content-around">
        <TextField
          style={{ marginRight: 10 }}
          name="dbHost"
          label="Database Host"
          required
          value={formValues.sqlConfig?.dbHost}
          onChange={handleChange}
          fullWidth
          margin="normal"
          size="small"
        />
        <TextField
          name="dbPort"
          label="Database Port"
          required
          value={formValues.sqlConfig?.dbPort}
          onChange={handleChange}
          fullWidth
          margin="normal"
          size="small"
        />
      </div>
      <div className="flex place-content-around">
        <TextField
          name="dbDescription"
          label="Database Description"
          required
          value={formValues.sqlConfig?.dbDescription}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
        />
      </div>
      <div className="flex place-content-around">
        <div className="w-[50%] mr-2.5 flex items-center mt-2.5">
          <Select
            value={formValues.sqlConfig?.sqlType}
            onChange={(e) => updateFormValues("sqlType", e.target.value)}
            size="small"
            fullWidth
            defaultValue={SQL_TYPES[0]}
          >
            {SQL_TYPES.map((sqlType) => (
              <MenuItem key={sqlType} value={sqlType}>
                {sqlType}
              </MenuItem>
            ))}
          </Select>
        </div>
        <TextField
          name="dbListTables"
          label="Database Tables"
          value={formValues.sqlConfig?.dbListTables}
          onChange={handleChange}
          fullWidth
          margin="normal"
          size="small"
        />
      </div>
    </form>
  );
};

export default SQLConfigForm;
