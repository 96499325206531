import { FC, useState } from "react";
import {
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FormItemContainer from "../atoms/FormItemContainer";
import { useApp } from "../states/AppContext";
import { useUpdateOrganizationMutation } from "../generated/graphql";
import { useUser } from "../utils/auth";
import { Notification } from "../molecules/Notification";
import { INotification } from "../interfaces/General";
import { CreateBtn } from "../atoms/CreateBtn";
import { TimeZone } from "../atoms/TimeZone";

const Setting: FC = ({}) => {
  const { setUser, user } = useUser();
  const [state] = useApp();
  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [formValues, setFormValues] = useState({
    name: state.UserReducer.organization.name,
    id: state.UserReducer.organization.id,
    timezone: state.UserReducer.organization.timezone,
  });
  const [updateOrganization, { loading, error }] =
    useUpdateOrganizationMutation({
      variables: { name: formValues.name, timezone: formValues.timezone },
      onCompleted(data) {
        if (
          data.updateOrganization &&
          data.updateOrganization.organization?.id
        ) {
          setNotification({
            message: "Updated",
            open: true,
            type: "success",
          });
        } else {
          setNotification({
            message: error?.message ?? "Error",
            open: true,
            type: "error",
          });
        }
      },
      onError() {
        setNotification({
          message: error?.message ?? "",
          open: true,
          type: "error",
        });
      },
    });

  const handleChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined = (event) => {
      const { name, value } = event.target;
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

  const handleClick = async () => {
    if (
      formValues.name === user.organization.name &&
      formValues.timezone === user.organization.timezone
    )
      return;
    await updateOrganization();
    setUser({
      ...user,
      organization: {
        ...user.organization,
        name: formValues.name,
        timezone: formValues.timezone,
      },
    });
  };

  return (
    <div>
      <Notification {...notification} setOpen={setNotification} />
      <Typography variant="h6">Settings</Typography>
      <FormItemContainer>
        <TextField
          label="Organization Name"
          value={formValues.name}
          name="name"
          onChange={handleChange}
          size="small"
          fullWidth
        />
      </FormItemContainer>
      <FormItemContainer>
        <TimeZone formValues={formValues} setFormValues={setFormValues} />
      </FormItemContainer>
      <FormItemContainer>
        <TextField
          label="Organization Id"
          value={formValues.id}
          disabled
          size="small"
          onChange={handleChange}
          fullWidth
        />
      </FormItemContainer>
      <FormItemContainer>
        <CreateBtn
          variant="contained"
          color="primary"
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Update"}
        </CreateBtn>
      </FormItemContainer>
    </div>
  );
};

export default Setting;
