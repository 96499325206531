import { CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { CreateBtn } from "../atoms/CreateBtn";
import { INotification } from "../interfaces/General";
import { Notification } from "../molecules/Notification";
import { useNavigate, useParams } from "react-router-dom";
import { useResetUserPasswordMutation } from "../generated/graphql";
import { validatePassword } from "../utils/utils";

const ResetPassword = () => {
  const navigate = useNavigate();

  const { token, uid } = useParams();

  const [password, setPassword] = useState("");
  const [confirmError, setConfirmError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });

  const [resetPassword, { loading }] = useResetUserPasswordMutation({
    variables: { token: token ?? "", uid: uid ?? "", newPassword: password },
    onCompleted(data) {
      if (data.resetPassword?.success) {
        setNotification({
          type: "success",
          message: `Password reset successfully!`,
          open: true,
        });
        navigate("/login");
      } else {
        setNotification({
          type: "error",
          message: `Something is wrong!`,
          open: true,
        });
      }
    },
    onError(error) {
      setNotification({
        type: "error",
        message: error.message,
        open: true,
      });
    },
  });
  const handlePasswordChange:
    | React.ChangeEventHandler<HTMLInputElement>
    | undefined = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange:
    | React.ChangeEventHandler<HTMLInputElement>
    | undefined = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> | undefined = (
    event
  ) => {
    event.preventDefault();
    const passwordErrors = validatePassword(password);
    if (password !== confirmPassword || passwordErrors) {
      if (passwordErrors) {
        setNotification({ message: passwordErrors, open: true, type: "error" });
      } else {
        setConfirmError(true);
      }
    } else {
      resetPassword();
    }
  };

  return (
    <div className="flex justify-center">
      <Notification {...notification} setOpen={setNotification} />
      <form onSubmit={handleSubmit} className="w-full max-w-sm">
        <div className="mb-4">
          <TextField
            type="password"
            label="New Password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <div className="mb-4">
          <TextField
            type="password"
            label="Confirm Password"
            variant="outlined"
            fullWidth
            value={confirmPassword}
            error={confirmError}
            helperText={confirmError ? "Password does not match" : ""}
            onChange={handleConfirmPasswordChange}
          />
        </div>
        <div className="flex justify-center">
          <CreateBtn
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Reset Password"
            )}
          </CreateBtn>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
