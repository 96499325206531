import { Grid, Paper, Typography, styled } from "@mui/material";
import { FC } from "react";
import BoxWithCopyButton from "../molecules/Copy";
import { useApp } from "../states/AppContext";
import { useParams } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const HowDeploy: FC = () => {
  const [state] = useApp();
  const { id } = useParams();
  const chatbot = state.ChatbotReducer.chatbots.find((item) => item.id === id);

  let channel = chatbot?.chatbotChannels.find(
    (item) => item.id === state.ChatbotReducer.channelId
  );

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <Typography style={{ marginBottom: 10 }} variant="h4">
              Launch on Your Website
            </Typography>
            <Typography style={{ marginBottom: 20 }} variant="body1">
              Just copy and paste following html tag to your website and the bot
              you've just tested will be deployed there. Just make sure, that
              the domain you've selected during the bot creation corresponds
              with the domain of your website.
            </Typography>
            <BoxWithCopyButton text={channel?.scriptTag ?? ""} />
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowDeploy;
