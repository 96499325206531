import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from "@mui/material";
import { useMostFrequentlyQuestionsLazyQuery } from "../../generated/graphql";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { questionLessMessage } from "./NotEnoughQuestion";
import { UsageTabsEnum } from "../../pages/ChatbotPage/Usage";

const Queries: FC<{ tab: UsageTabsEnum }> = ({ tab }) => {
  const { id } = useParams();
  const [getChatbotMostFrequentlyQuestions, { loading, data }] =
    useMostFrequentlyQuestionsLazyQuery({
      variables: { chatbotId: id },
    });

  const questions = data?.mostFrequentlyQuestions?.questions;
  const total = data?.mostFrequentlyQuestions?.total;

  useEffect(() => {
    if (tab === UsageTabsEnum.QUERIES) {
      getChatbotMostFrequentlyQuestions();
    }
  }, [tab]);

  if (loading) {
    return <div>Generating report...</div>;
  }
  return (
    <div>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <div className="font-semibold">Most frequently question asked</div>
      </Toolbar>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Total Questions</TableCell>
              <TableCell>Question</TableCell>
              <TableCell>Number of asked</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {total}
              </TableCell>
              <TableCell>
                {questions?.[0]?.question ?? questionLessMessage}
              </TableCell>
              <TableCell>{questions?.[0]?.count}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <hr className="my-10" />
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <div className="font-semibold">Most frequently questions</div>
      </Toolbar>
      {(questions?.length ?? 0) >= 2 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Question</TableCell>
                <TableCell>Number of asked</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questions?.map((question, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {question?.question}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {question?.count}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="pl-4">{questionLessMessage}</div>
      )}
    </div>
  );
};

export default Queries;
