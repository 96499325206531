export const urlRegex = /((https):\/\/)?(.*)(:[0-9]{1,5})?(\/[^\s]*)?/i;

export const cleanUrl = (url: string) => {
  if (!url.includes("https")) {
    return `https://${url.replace("http://", "")}`;
  }
  return url;
};

export const combineReducers = (slices: any) => (state: any, action: any) =>
  Object.keys(slices).reduce((acc, prop) => {
    const current = slices[prop](acc[prop], action);
    if (current) {
      return { ...acc, [prop]: current };
    }
    return { ...acc };
  }, state);

export const validatePassword = (password: string) => {
  const errors: Array<string> = [];

  if (password.length < 8) {
    errors.push("Password must be at least 8 characters long.");
  }

  if (!/[A-Z]/.test(password)) {
    errors.push("Password must contain at least one uppercase letter.");
  }

  if (!/[a-z]/.test(password)) {
    errors.push("Password must contain at least one lowercase letter.");
  }

  if (!/\d/.test(password)) {
    errors.push("Password must contain at least one number.");
  }

  if (!/[^a-zA-Z\d]/.test(password)) {
    errors.push("Password must contain at least one symbol.");
  }

  return errors.join(",");
};
