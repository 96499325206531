import { FC, useEffect, useState } from "react";
import { CreateBtn } from "../../atoms/CreateBtn";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  RoleType,
  useDeleteOrganizationRoleMutation,
  useRolesLazyQuery,
} from "../../generated/graphql";
import AlertDialog from "../../molecules/Dialog";

const RoleList: FC = () => {
  const [role, setRole] = useState<RoleType>();
  const [open, setOpen] = useState(false);
  const [roles, setRoles] = useState<RoleType[]>([]);
  const [deleteRole] = useDeleteOrganizationRoleMutation({
    variables: { id: role?.id },
    onCompleted(data) {
      if (data.deleteRole?.success) {
        setRoles(roles.filter((item) => item.id !== role?.id));
        setRole(undefined);
      }
    },
  });
  const [getRoles] = useRolesLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data.roles && data.roles.length) {
        setRoles(data.roles as RoleType[]);
      }
    },
  });

  const handleDeleteRole = () => {
    deleteRole();
  };

  useEffect(() => {
    getRoles();
  }, []);
  return (
    <div className="p-4">
      <AlertDialog
        description="Are you sure you want to delete this?"
        title={`Delete ${role?.role}`}
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={() => {
          handleDeleteRole();
          setOpen(false);
        }}
        maxWidth="md"
        fullWidth
      />
      <Link to="/dashboard/admin/roles">
        <CreateBtn
          variant="contained"
          color="primary"
          className="mb-2"
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Role
        </CreateBtn>
      </Link>
      <TableContainer component={Paper} className="mt-4">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Role</TableCell>
              <TableCell>Users</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role) => (
              <TableRow key={role.id}>
                <TableCell>{role.role}</TableCell>
                <TableCell>
                  {role.customerRoles.map((item) => item.email).join(",")}
                </TableCell>
                <TableCell>
                  <Link
                    to={`/dashboard/admin/roles/${role.id}`}
                    className="mr-4"
                  >
                    <IconButton edge="end" title="edit role">
                      <EditIcon />
                    </IconButton>
                  </Link>
                  <IconButton
                    edge="end"
                    title={
                      role.customerRoles.length > 0
                        ? "Role can't be deleted, since users are attached to it"
                        : "delete role"
                    }
                    disabled={role.customerRoles.length > 0}
                    onClick={() => {
                      setOpen(true);
                      setRole(role);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  {!!role.customerRoles.length &&
                    "Role can't be deleted, since users are attached to it"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RoleList;
