import ChatbotTabs from "../../organisms/UI/ChatbotTabs";
import { IChatbotTabs } from "../../interfaces/Chatbot";
import Statistics from "../../organisms/Usage/Statistics";
import Queries from "../../organisms/Usage/Queries";
import Conversations from "../../organisms/Usage/Conversations";

export enum UsageTabsEnum {
  STATISTICS = 0,
  QUERIES,
  CONVERSATIONS,
}

const Usage = () => {
  const tabs: IChatbotTabs = [
    {
      label: "Statistics",
      component: () => <Statistics tab={UsageTabsEnum.STATISTICS} />,
    },
    {
      label: "Queries",
      component: () => <Queries tab={UsageTabsEnum.QUERIES} />,
    },
    {
      label: "Conversations",
      component: () => <Conversations tab={UsageTabsEnum.CONVERSATIONS} />,
    },
  ];

  return (
    <div className="flex item-center w-full flex-col">
      <ChatbotTabs tabs={tabs} />
    </div>
  );
};

export default Usage;
