import { Chip, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useState } from "react";
import { cleanUrl } from "../utils/utils";

interface InputTagsProps {
  label: string;
  placeholder?: string;
  helperText?: string;
  initialTags?: Array<string>;
  onSubmitTag?: (value: string) => boolean;
  getTags?: (tags: Array<string>) => void;
}

const InputTags: FC<InputTagsProps> = ({
  label,
  placeholder,
  helperText,
  initialTags = [],
  onSubmitTag,
  getTags,
}) => {
  const [tags, setTags] = useState<Array<string>>(initialTags);
  const [value, setValue] = useState<string>("");
  const [error, setError] = useState(false);
  const handleChange: React.ChangeEventHandler<HTMLInputElement> | undefined = (
    e
  ) => {
    setValue(e.target.value);
    if (error) {
      setError(false);
    }
  };

  const handleDelete = (value: string) => {
    const newtags = tags.filter((val) => val !== value);
    setTags(newtags);
    if (getTags) {
      getTags(newtags);
    }
  };
  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> | undefined = (
    e
  ) => {
    e.preventDefault();
    if (onSubmitTag) {
      const isVerfied = onSubmitTag(value);
      if (isVerfied) {
        setTags([...tags, value]);
        getTags && getTags([...tags, value]);
        setValue("");
      } else {
        setError(true);
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <form onSubmit={handleOnSubmit}>
        <TextField
          value={value}
          label={label}
          fullWidth
          error={error}
          helperText={error ? helperText : ""}
          variant="outlined"
          size="small"
          required
          //sx={{ margin: "1rem 0" }}
          margin="none"
          placeholder={tags.length < 5 ? placeholder : ""}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !value.length) {
              handleDelete(tags[tags.length - 1]);
            }
          }}
          onChange={handleChange}
          InputProps={{
            startAdornment: tags.length ? (
              <Box sx={{ margin: "0 0.2rem 0 0", display: "flex" }}>
                {tags.map((data, index) => {
                  return (
                    <Chip
                      label={cleanUrl(data)}
                      onDelete={() => handleDelete(data)}
                      key={index}
                      size="small"
                    />
                  );
                })}
              </Box>
            ) : null,
          }}
        />
      </form>
    </Box>
  );
};

export default InputTags;
