import { MenuItem, Select } from "@mui/material";
import { FC } from "react";
import { PriceTierTypeEnum } from "../interfaces/Organization";

interface PriceTierProps {
  formValues: any;
  setFormValues: (formValue: any) => void;
}

export const PriceTier: FC<PriceTierProps> = ({
  formValues,
  setFormValues,
}) => {
  return (
    <Select
      size="small"
      fullWidth
      value={formValues.priceTierType}
      onChange={(event) => {
        setFormValues({
          ...formValues,
          priceTierType: event.target.value,
        });
      }}
    >
      {Object.keys(PriceTierTypeEnum).map((key) => {
        const enumKey = key as keyof typeof PriceTierTypeEnum;
        return (
          <MenuItem key={key} value={PriceTierTypeEnum[enumKey]}>
            {PriceTierTypeEnum[enumKey]}
          </MenuItem>
        );
      })}
    </Select>
  );
};
