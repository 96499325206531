import { useLocation, useNavigate } from "react-router-dom";
import { useApp } from "../states/AppContext";

export const useChatboNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const organizationId = queryParams.get("organization_id");
  const [state] = useApp();
  const overviewPage = `/dashboard/chatbot/${state.ChatbotReducer.chatbotSelected}/overview?organization_id=${organizationId}`;
  const leaveCreate = () => {
    navigate(overviewPage);
  };

  return {
    overviewPage,
    leaveCreate,
  };
};
