import { Typography } from "@mui/material";
import { FC } from "react";
import { useApp } from "../states/AppContext";

const ChatbotTitle: FC = () => {
  const [state] = useApp();

  const chatbot = state.ChatbotReducer.chatbots.find(
    (item) => item.id === state.ChatbotReducer.chatbotSelected
  );
  return <Typography variant="h3">{chatbot?.chatboxTitle}</Typography>;
};

export default ChatbotTitle;
