import { FC } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useApp } from "../../states/AppContext";
import { useParams } from "react-router-dom";

const Personalise: FC = () => {
  const [state] = useApp();
  const { id } = useParams();
  const chatbot = state.ChatbotReducer.chatbots.find((item) => item.id === id);
  const channel = chatbot?.chatbotChannels[0];
  const regex = /apiKey=([\w-]+)/;

  const match = channel?.scriptTag.match(regex);

  const apiKey = match ? match[1] : null;
  return (
    <div>
      <div className="font-bold text-xl mb-6">
        LokiBots Personalization Chatbot Integration Manual
      </div>
      <div className="text-lg font-bold mb-1">Introduction</div>
      <p className="mb-4 text-gray-800">
        The LokiBots Personalization Chatbot is a powerful tool that allows you
        to enhance user interaction and provide personalized experiences in your
        web application. This document will guide you through the process of
        integrating the LokiBots script into your web app and using the chatter
        function to initialize the chatbot with user information.
      </p>
      <div className="text-lg font-bold mb-1">Integration Steps</div>
      <div className="text-base font-medium mb-1">
        Step 1: Obtaining the LokiBots Script
      </div>
      <p className="mb-4 text-gray-800">
        Contact the LokiBots support team or visit their website to obtain the
        LokiBots script. They will provide you with a script tag that you need
        to add to your web app's HTML code.
      </p>
      <div className="text-base font-medium mb-1">
        Step 2: Adding the LokiBots Script to your Web App
      </div>
      <p className="mb-4 text-gray-800">
        Open your web app's HTML file and locate the
        <span className="font-bold pl-1">&lt;/body&gt;</span> section. Insert
        the LokiBots script tag provided by the LokiBots team before
        <span className="font-bold pl-1">&lt;/body&gt;</span> section. It should
        look like this:
      </p>
      <div className="mb-4">
        <SyntaxHighlighter language="html" style={prism}>
          {`<!DOCTYPE html>
  <html>
  <head>
    <!-- Your existing code goes here -->
  
  </head>
  <body>
    <!-- Your web app's body content goes here -->
  
    <!-- Add the LokiBots script tag below -->
    ${channel?.scriptTag ?? "Will be provided as soon as Chatbot is created"}
  </body>
  </html>`}
        </SyntaxHighlighter>
      </div>
      <div className="text-base font-medium mb-1">
        Step 3: Initializing the Chatbot with User Information (
        <span className="text-red-500">name</span> and{" "}
        <span className="text-red-500">email</span> <b>Must</b> be passed)
      </div>
      <p className="text-gray-800 mb-4">
        To personalize the chatbot experience, you can initialize the chatbot
        with the user's name and email using the chatter function provided by
        LokiBots. Here's an example of how to use it:
      </p>
      <div className="mb-4">
        <SyntaxHighlighter language="js" style={prism}>
          {`
  // Call the chatter function with the user's name and email
  LokiChats_LokiBots_Chatter.chatter("User logged in name", "user_logged_in_email@example.com");`}
        </SyntaxHighlighter>
      </div>
      <p className="text-gray-800 mb-4">
        You can place this code in your web app's JavaScript file or within a
        <span className="font-bold pl-1">&lt;script&gt;</span> tag in your HTML
        file.
      </p>
      <div className="text-lg font-bold mb-1">Example Usage</div>
      <p className="text-gray-800 mb-4">
        Here's an example of how you can integrate the LokiBots script and
        initialize the chatbot with user information:
      </p>
      <div className="mb-4">
        <SyntaxHighlighter language="html" style={prism}>
          {`<!DOCTYPE html>
  <html>
  <head>
    <!-- Your existing code goes here -->
  
  
  </head>
  <body>
    <!-- Your web app's body content goes here -->
  
    <!-- Add the LokiBots script tag below -->
    ${channel?.scriptTag ?? "Will be provided as soon as Chatbot is created"}
  
    <!-- This could be in any part of your code -->
    <script>
      function openChatbot() {
        const name = "John Doe";
        const email = "johndoe@example.com";
        LokiChats_LokiBots_Chatter.chatter(name, email);
      }
    </script>
  </body>
  </html>`}
        </SyntaxHighlighter>
      </div>
      <div className="text-lg font-bold mb-1">REST API / GQL Endpoint</div>
      <p className="text-gray-800 mb-4">
        To obtain the token for automatic user login, you can make a request to
        the following REST API or GQL endpoint:
      </p>
      <div className="mb-4">
        <SyntaxHighlighter language="plaintext" style={prism}>
          {`POST /signin_chatbot_user
  Headers:
    authorization: ${apiKey}
  
  Parameters:
    name: String! (User's name, required)
    email: String! (User's email address, required)
    extractedData: JSONString (Extracted data in JSON string format)`}
        </SyntaxHighlighter>
      </div>
      <div className="text-lg font-bold mb-1">GraphQL Mutation Example</div>
      <p className="text-gray-800 mb-4">
        To sign in a chatbot user and retrieve the token using a GraphQL
        mutation, you can use the following example:
      </p>
      <div className="mb-4">
        <SyntaxHighlighter language="graphql" style={prism}>
          {`mutation signinChatbotUser($name: String!, $email: String!, $extractedData: JSONString) {
  signinChatbotUser(name: $name, email: $email, extractedData: $extractedData) {
    token
  }
}`}
        </SyntaxHighlighter>
      </div>
      <div className="text-lg font-bold mb-1">
        Adding Token to Chatbot JS URL
      </div>
      <p className="text-gray-800 mb-4">
        Once you receive the token from the API response, you need to add it to
        the chatbot JavaScript URL in the following format:
      </p>
      <div className="mb-4">
        <SyntaxHighlighter language="html" style={prism}>
          {channel?.scriptTag?.replace("><", "&token={token}><") ??
            "Will be provided as soon as Chatbot is created"}
        </SyntaxHighlighter>
      </div>
      <div className="text-lg font-bold mb-1">Conclusion</div>
      <p className="text-gray-800">
        Congratulations! You have successfully integrated the LokiBots
        Personalization Chatbot into your web app and learned how to initialize
        it with user information. By obtaining the token through the
        `signinChatbotUser` endpoint and adding it to the chatbot JavaScript
        URL, you can enable automatic user login.
      </p>
    </div>
  );
};

export default Personalise;
