import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import HorizontalLinearStepper from "../UI/Stepper";
import { TABENUM, getChatbotSteps } from "./steps";
import {
  ChatbotInput,
  ChatbotType,
  Exact,
  SqlConfigFieldsFragment,
  OpenSearchConfigFieldsFragment,
  HelperQuestionsFieldsFragment,
  useCreateChatbotMutation,
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useDeleteHelperQuestionsMutation,
  useCreateQuestionPairsMutation,
  useCreateSqlConfigMutation,
  useCreateOpensearchConfigMutation,
  useUpdateChatbotMutation,
  useUpdateChannelProfileMutation,
  useUpdateSqlConfigMutation,
  useUpdateOpensearchConfigMutation,
  useCreateHelperQuestionsMutation,
  HelperQuestionsType,
  Maybe,
  FeedbackViewEnum,
  useOrganizationStatsLazyQuery,
  ChannelInput,
  ChannelType,
  useCreateOrUpdateChannelMutation,
  ChanelModeEnum,
  ChannelProfileInput,
} from "../../generated/graphql";

import { useApp } from "../../states/AppContext";
import { useChatbot } from "../../utils/chatbot";
import { INotification } from "../../interfaces/General";
import { Notification } from "../../molecules/Notification";
import {
  IDocument,
  IQuestionPairs,
  IHelperQuestions,
} from "../../interfaces/Documents";
import {
  defaultColor,
  defaultHoverColor,
  defaultLogoSize,
  defaultTitleColor,
  defaultTitleSize,
  defaultFont,
} from "../Design";
import { ChatbotAction } from "../../interfaces/Chatbot";
import { isQuestionPair, verifyChatbotForm } from "./utils";
import AlertDialog from "../../molecules/Dialog";
import { useChatboNavigate } from "../../hooks/navigate";
import { SQL_TYPES } from "./KnowledgeBase/SQLConfig";
import { UserAction } from "../../interfaces/Users";

const fillQuestionPairs = (chatbot: ChatbotType | undefined) =>
  JSON.parse(
    chatbot?.documents.find((item) => isQuestionPair(item))?.questionPairs ??
      "[]"
  );

const fillHelperQuestions = (chatbot: ChatbotType | undefined) =>
  JSON.parse(
    chatbot?.chatbotHelperQuestions
      ? chatbot?.chatbotHelperQuestions[0]?.questions ?? "[]"
      : "[]"
  );

const fillSQLConfig = (chatbot: ChatbotType | undefined) =>
  chatbot?.chatbotSqlConfig[0] ?? {
    id: "",
    dbHost: "",
    dbName: "",
    dbPass: "",
    dbUser: "",
    dbPort: "",
    dbDescription: "",
    dbListTables: "",
    sqlType: SQL_TYPES[0],
  };

const fillOpenSearchConfig = (chatbot: ChatbotType | undefined) =>
  chatbot?.chatbotOpensearchConfig[0] ?? {
    id: "",
    dbHost: "",
    dbName: "",
    dbPass: "",
    dbUser: "",
    dbPort: "",
    dbResponse: "",
    dbAdditionalResponse: "",
  };
interface ChatbotCreationProps {
  id: string;
  tab?: TABENUM;
}

export interface IChannelProfileDesign {
  color: string;
  hoverColor: string;
  logoSize: number;
  chatbotTitleSize: number;
  chatbotTitleColor: string;
  chatbotFont: string;
  hideTopBar?: boolean;
  hideSources?: boolean;
  hideSystemMessage?: boolean;
  onLoad?: boolean;
  logo: IDocument[];
  file?: File;
  feedbackView?: Maybe<FeedbackViewEnum>;
}

interface ExtendedChannelInput extends ChannelInput {
  id?: string;
}

interface ExtendedChannelProfileDesign extends IChannelProfileDesign {
  channelId: string;
}

interface IChatbotConfig {
  design: IChannelProfileDesign;
  helpQuestions: IHelperQuestions[];
  documents: IDocument[];
  channels: ExtendedChannelInput[];
  channelProfiles: ExtendedChannelProfileDesign[];
  questionPairs: IQuestionPairs[];
  sqlConfig: SqlConfigFieldsFragment | undefined;
  opensearchConfig: OpenSearchConfigFieldsFragment | undefined;
}

export type ChatbotFormType = ChatbotInput & IChatbotConfig & ChannelInput;

const isFormChanged = (formValues: ChatbotFormType) => {
  if (
    formValues.chatboxTitle ||
    formValues.description ||
    formValues.design.color !== defaultColor ||
    formValues.design.hoverColor !== defaultHoverColor ||
    formValues.design.logoSize !== defaultLogoSize ||
    formValues.design.chatbotTitleSize !== defaultTitleSize ||
    formValues.design.chatbotTitleColor !== defaultTitleColor ||
    formValues.design.chatbotFont !== defaultFont ||
    formValues.design.hideSources ||
    formValues.design.hideSystemMessage ||
    formValues.design.hideTopBar ||
    formValues.design.onLoad ||
    formValues.domains ||
    formValues.welcomeMessage ||
    formValues.documents.length ||
    formValues.design.file ||
    formValues.questionPairs?.length ||
    formValues.helpQuestions?.length ||
    //Object.values(formValues.helpQuestions ?? {}).length ||
    Object.values(formValues.sqlConfig ?? {}).length ||
    Object.values(formValues.opensearchConfig ?? {}).length
  ) {
    return true;
  }
  return false;
};

export const setDefaultDesign = (): IChannelProfileDesign => ({
  color: defaultColor,
  hoverColor: defaultHoverColor,
  logo: [],
  logoSize: defaultLogoSize,
  chatbotTitleSize: defaultTitleSize,
  chatbotTitleColor: defaultTitleColor,
  chatbotFont: defaultFont,
  hideTopBar: false,
  hideSystemMessage: false,
  hideSources: false,
  onLoad: false,
  feedbackView: FeedbackViewEnum.Thumb,
});

const ChatbotCreation: FC<ChatbotCreationProps> = ({ id, tab }) => {
  const [state, dispatch] = useApp();
  const [step, setStep] = useState(-1);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const organizationId = queryParams.get("organization_id");

  const { leaveCreate } = useChatboNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const chatbot = state.ChatbotReducer.chatbots.find((item) => item.id === id);
  const channelId = state.ChatbotReducer.channelId;

  const { getOneChatbot } = useChatbot();
  const navigate = useNavigate();
  const [createOrUpdateChannel] = useCreateOrUpdateChannelMutation({
    onCompleted(data) {
      if (data.createOrUpdateChannel?.channel?.id) {
        setNotification({
          type: "success",
          message: "Channel was added successfully",
          open: true,
        });
      } else {
        setNotification({
          type: "error",
          message: "Something happened during add Channel",
          open: true,
        });
      }
    },
  });
  const [formValues, setFormValues] = useState<ChatbotFormType>({
    chatbotId: "",
    chatboxTitle: "",
    welcomeMessage: "",
    domains: "",
    mode: ChanelModeEnum.Chatbox,
    personalised: false,
    extractionAgent: false,
    design: setDefaultDesign(),
    description: "",
    documents: [],
    channels: [],
    channelProfiles: [],
    questionPairs: fillQuestionPairs(chatbot),
    sqlConfig: fillSQLConfig(chatbot),
    opensearchConfig: fillOpenSearchConfig(chatbot),
    helpQuestions: fillHelperQuestions(chatbot),
    name: "",
  });
  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [files, setFiles] = useState<Array<File>>([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [savingText, setSavingText] = useState(
    !id ? "Adding Chatbot..." : "Updating Chatbot..."
  );
  const [createChatbot, { error: errorChatbot }] = useCreateChatbotMutation({});
  const [createDocument, { error: errorDocument }] =
    useCreateDocumentMutation();

  const [getOrganizationStats] = useOrganizationStatsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(data) {
      dispatch({
        type: UserAction.SET_USER_DATA,
        payload: {
          ...state.UserReducer,
          organization: {
            ...state.UserReducer.organization,
            numberOfChatbots: data.organization?.numberOfChatbots
              ? data.organization?.numberOfChatbots
              : 0,
            monthlyQuestionsLimit: data.organization?.monthlyQuestionsLimit
              ? data.organization?.monthlyQuestionsLimit
              : 0,
            storageLimit: data.organization?.storageLimit
              ? data.organization?.storageLimit
              : 0,
          },
        },
      });
      console.log(state.UserReducer.organization);
    },
  });
  const [updateChatbot, { error: errorUpdate }] = useUpdateChatbotMutation({
    onCompleted(data) {
      if (data.updateChatbot?.chatbot?.id) {
        // updateState();
      }
    },
    onError(error) {
      setLoading(false);
      setNotification({
        message: error.message,
        open: true,
        type: "error",
      });
    },
  });
  const [createSqlConfig] = useCreateSqlConfigMutation({
    onCompleted(data) {
      if (data.createSqlConfig?.sqlConfig?.id) {
        setNotification({
          type: "success",
          message: "Sql config pais was added successfully",
          open: true,
        });
      } else {
        setNotification({
          type: "error",
          message: "Something happened during add Sql Config",
          open: true,
        });
      }
    },
    onError(error) {
      setNotification({
        type: "error",
        message: error.message,
        open: true,
      });
    },
  });
  const [createOpenSearchConfig] = useCreateOpensearchConfigMutation({
    onCompleted(data) {
      if (data.createOpensearchConfig?.opensearchConfig?.id) {
        setNotification({
          type: "success",
          message: "Opensearch config pais was added successfully",
          open: true,
        });
      } else {
        setNotification({
          type: "error",
          message: "Something happened during add Opensearch Config",
          open: true,
        });
      }
    },
    onError(error) {
      setNotification({
        type: "error",
        message: error.message,
        open: true,
      });
    },
  });

  const [updateSqlConfig] = useUpdateSqlConfigMutation({
    onCompleted(data) {
      if (data.updateSqlConfig?.sqlConfig?.id) {
        setNotification({
          type: "success",
          message: "Sql config pais was updated successfully",
          open: true,
        });
      } else {
        setNotification({
          type: "error",
          message: "Something happened during update Sql Config",
          open: true,
        });
      }
    },
    onError(error) {
      setNotification({
        type: "error",
        message: error.message,
        open: true,
      });
    },
  });
  const [updateOpenSearchConfig] = useUpdateOpensearchConfigMutation({
    onCompleted(data) {
      if (data.updateOpensearchConfig?.opensearchConfig?.id) {
        setNotification({
          type: "success",
          message: "Opensearch config pais was updated successfully",
          open: true,
        });
      } else {
        setNotification({
          type: "error",
          message: "Something happened during update Opensearch Config",
          open: true,
        });
      }
    },
    onError(error) {
      setNotification({
        type: "error",
        message: error.message,
        open: true,
      });
    },
  });

  const [createQuestionPairs] = useCreateQuestionPairsMutation({
    onCompleted(data) {
      if (data.createQuestionPairs?.document) {
        setNotification({
          type: "success",
          message: "Q&A paris was added successfully",
          open: true,
        });
      } else {
        setNotification({
          type: "error",
          message: "Something happened during add Q&A pairs",
          open: true,
        });
      }
    },
    onError(error) {
      setNotification({
        type: "error",
        message: error.message,
        open: true,
      });
    },
  });
  const [createHelperQuestions] = useCreateHelperQuestionsMutation({
    onCompleted(data) {
      if (data.createHelperQuestions?.helperQuestions?.id) {
        setNotification({
          type: "success",
          message: "Questions added successfully",
          open: true,
        });
        updateState("", null, [
          data.createHelperQuestions.helperQuestions,
        ] as HelperQuestionsType[]);
      } else {
        setNotification({
          type: "error",
          message: "Something happened when saving questions",
          open: true,
        });
      }
      setLoading(false);
    },
    onError(error) {
      setNotification({
        type: "error",
        message: error.message,
        open: true,
      });
    },
  });
  const [deleteHelperQuestions] = useDeleteHelperQuestionsMutation({
    onCompleted(data) {
      if (data.deleteHelperQuestions?.success) {
        setNotification({
          type: "success",
          message: "Questions updated successfully",
          open: true,
        });
        updateState("", null, [] as HelperQuestionsType[]);
      } else {
        setNotification({
          type: "error",
          message: "Something happened when saving questions",
          open: true,
        });
      }
      setLoading(false);
    },
    onError(error) {
      setNotification({
        type: "error",
        message: error.message,
        open: true,
      });
    },
  });
  const [deleteDocumentData] = useDeleteDocumentMutation({
    onCompleted(data) {
      if (data.deleteDocument?.success) {
        setNotification({
          type: "success",
          message: "Q&A Pairs were successfuly removed",
          open: true,
        });
      } else {
        setNotification({
          type: "error",
          message: "Something happened during remove",
          open: true,
        });
      }
    },
    onError(error) {
      setNotification({
        type: "error",
        message: error.message,
        open: true,
      });
    },
  });
  const [updateChannelProfile] = useUpdateChannelProfileMutation({
    onCompleted(data) {
      if (
        tab === TABENUM.CHANNEL ||
        tab === TABENUM.CONFIGURATION_QUESTION_HELPER
      ) {
        setLoading(false);
      }
      if (data.updateChannelProfile?.channelProfile?.id) {
        if (!id && formValues.questionPairs.length) {
          setSavingText("Creating Q&A pairs...");
        } else if (!id) {
          setSavingText("Uploading Documents...");
        }
        setNotification({
          message: "Chatbot design successfully updated.",
          open: true,
          type: "success",
        });

        if (state.ChatbotReducer.chatbots && id) {
          updateState(
            data.updateChannelProfile?.channelProfile?.logo ?? "",
            data.updateChannelProfile?.channelProfile.channel as ChannelType
          );
        }
      } else {
        setNotification({
          message: "Chatbot design couldn't changed, try again",
          open: true,
          type: "error",
        });
      }
    },
    onError(e) {
      setNotification({
        message: e.message,
        open: true,
        type: "error",
      });
    },
  });
  const handleChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined = (event, checked = undefined) => {
    const { name, value } = event.target;

    setFormValues((prevState) => ({
      ...prevState,
      ...(checked !== undefined
        ? { design: { ...prevState.design, [name]: checked } }
        : { [name]: value }),
    }));
  };

  const fillChannelProfileColor = (
    channelProfileBody: IChannelProfileDesign
  ) => {
    channelProfileBody.color = formValues.design.color;
    channelProfileBody.hoverColor = formValues.design.hoverColor;
    channelProfileBody.logoSize = formValues.design.logoSize;
    channelProfileBody.chatbotTitleColor = formValues.design.chatbotTitleColor;
    channelProfileBody.chatbotTitleSize = formValues.design.chatbotTitleSize;
    channelProfileBody.chatbotFont = formValues.design.chatbotFont;

    return channelProfileBody;
  };

  const fillChatbotConfiguration = (
    channelProfileBody: IChannelProfileDesign
  ) => {
    channelProfileBody.hideSources = formValues.design.hideSources ?? false;
    channelProfileBody.hideSystemMessage =
      formValues.design.hideSystemMessage ?? false;
    channelProfileBody.hideTopBar = formValues.design.hideTopBar ?? false;
    channelProfileBody.onLoad = formValues.design.onLoad ?? false;
    channelProfileBody.feedbackView =
      formValues.design.feedbackView ?? FeedbackViewEnum.Thumb;
    return channelProfileBody;
  };

  const updateChannelProfileData = async (
    channelId: string,
    chatbotId: string
  ) => {
    let channelProfileBody: IChannelProfileDesign = {
      color: "",
      hoverColor: "",
      logo: [],
      logoSize: 0,
      chatbotTitleColor: "",
      chatbotTitleSize: 0,
      chatbotFont: "",
    };
    if (id) {
      setSavingText("Updating Chatbot design...");
    } else {
      setSavingText("Saving Chatbot design...");
    }
    channelProfileBody = fillChannelProfileColor(channelProfileBody);
    channelProfileBody = fillChatbotConfiguration(channelProfileBody);
    // await updateHelperQuestions(chatbotId);
    updateChannelProfile({
      variables: {
        channelProfileData: {
          channelId,
          ...channelProfileBody,
          ...(formValues.design.file
            ? { logo: formValues.design.file }
            : { logo: "" }),
        },
      },
    });
  };

  const updateState = (
    logo = "",
    channel: ChannelType | null = null,
    helperQuestions: HelperQuestionsType[] = []
  ) => {
    dispatch({
      type: ChatbotAction.SET_CHATBOT_DATA,
      payload: {
        ...state.ChatbotReducer,
        chatbots: state.ChatbotReducer.chatbots.map((item) => {
          if (item.id === id) {
            let updatedChannels = item.chatbotChannels;
            console.log(updatedChannels, "updated channels");
            if (updatedChannels.length === 0) {
              updatedChannels = [
                {
                  id: channel?.id ?? "",
                  channelProfile: {
                    color: formValues.design.color,
                    hoverColor: formValues.design.hoverColor,
                    logo: logo,
                    logoSize: formValues.design.logoSize,
                    chatbotTitleColor: formValues.design.chatbotTitleColor,
                    chatbotTitleSize: formValues.design.chatbotTitleSize,
                    chatbotFont: formValues.design.chatbotFont,
                    hideSources: formValues.design.hideSources,
                    hideSystemMessage: formValues.design.hideSystemMessage,
                    hideTopBar: formValues.design.hideTopBar,
                    onLoad: formValues.design.onLoad,
                    feedbackView: formValues.design.feedbackView,
                  },
                  scriptTag: channel?.scriptTag ?? "",
                  domains: formValues.domains,
                  mode: formValues.mode,
                  name: formValues.name,
                },
              ] as ChannelType[];
            } else {
              updatedChannels = updatedChannels.map((channel) => {
                if (channel.id === channelId) {
                  return {
                    ...channel,
                    channelProfile: {
                      ...channel.channelProfile,
                      color: formValues.design.color,
                      hoverColor: formValues.design.hoverColor,
                      logo: logo ? logo : channel?.channelProfile?.logo,
                      logoSize: formValues.design.logoSize,
                      chatbotTitleColor: formValues.design.chatbotTitleColor,
                      chatbotTitleSize: formValues.design.chatbotTitleSize,
                      chatbotFont: formValues.design.chatbotFont,
                      hideSources: formValues.design.hideSources,
                      hideSystemMessage: formValues.design.hideSystemMessage,
                      hideTopBar: formValues.design.hideTopBar,
                      onLoad: formValues.design.onLoad,
                      feedbackView: formValues.design.feedbackView,
                    },
                  };
                }
                return channel;
              }) as ChannelType[];
            }
            return {
              ...item,
              chatbotHelperQuestions: helperQuestions,
              description: formValues.description,
              chatboxTitle: formValues.chatboxTitle,
              welcomeMessage: formValues.welcomeMessage,
              domains: formValues.domains,
              name: formValues.name,
              chatbotChannels: updatedChannels,
            };
          }
          return item;
        }),
      },
    });
  };

  const uploadDocuments = async (chatbotId: string) => {
    return Promise.all(
      files.map(
        async (file) => await createDocument({ variables: { file, chatbotId } })
      )
    ).catch((error) =>
      setNotification({
        message: error.message,
        open: true,
        type: "error",
      })
    );
  };

  const updateDocuments = async (chatbotId: string) => {
    if (formValues.questionPairs.length) {
      if (id) {
        setSavingText("Creating Q&A pairs...");
      }
      await updateQuestionPairs(chatbotId);
    }
    if (formValues.questionPairs.length === 0) {
      if (id) {
        setSavingText("Updating knowledge base...");
      }
      await deleteQuestionPairs(chatbotId);
    }
    if (
      formValues.sqlConfig?.dbName &&
      formValues.sqlConfig.dbUser &&
      formValues.sqlConfig.dbPass &&
      formValues.sqlConfig.dbHost &&
      formValues.sqlConfig.dbPort &&
      formValues.sqlConfig.sqlType &&
      formValues.sqlConfig.dbDescription
    ) {
      if (!id || !formValues.sqlConfig?.id) {
        setSavingText("Creating SQL Config...");
        await createSqlConfig({
          variables: {
            dbDescription: formValues.sqlConfig.dbDescription,
            dbUser: formValues.sqlConfig.dbUser,
            dbPass: formValues.sqlConfig.dbPass,
            dbHost: formValues.sqlConfig.dbHost,
            dbPort: formValues.sqlConfig.dbPort,
            dbName: formValues.sqlConfig.dbName,
            dbListTables: formValues.sqlConfig.dbListTables ?? "",
            sqlType: formValues.sqlConfig.sqlType,
            chatbotId,
          },
        });
      } else if (id && formValues.sqlConfig?.id) {
        setSavingText("Updating SQL Config...");
        await updateSqlConfig({
          variables: {
            id: formValues.sqlConfig?.id,
            dbDescription: formValues.sqlConfig?.dbDescription ?? "",
            dbUser: formValues.sqlConfig?.dbUser ?? "",
            dbPass: formValues.sqlConfig?.dbPass ?? "",
            dbHost: formValues.sqlConfig?.dbHost ?? "",
            dbPort: formValues.sqlConfig?.dbPort ?? "",
            dbName: formValues.sqlConfig?.dbName ?? "",
            dbListTables: formValues.sqlConfig?.dbListTables ?? "",
            sqlType: formValues.sqlConfig?.sqlType ?? "",
            chatbotId,
          },
        });
      }
    }

    if (
      formValues.opensearchConfig?.dbName &&
      formValues.opensearchConfig?.dbUser &&
      formValues.opensearchConfig?.dbPass &&
      formValues.opensearchConfig?.dbHost &&
      formValues.opensearchConfig?.dbPort &&
      formValues.opensearchConfig?.dbResponse &&
      formValues.opensearchConfig?.dbAdditionalResponse
    ) {
      if (!id || !formValues.opensearchConfig?.id) {
        setSavingText("Creating Opensearch Config...");
        await createOpenSearchConfig({
          variables: {
            dbResponse: formValues.opensearchConfig.dbResponse,
            dbUser: formValues.opensearchConfig.dbUser,
            dbPass: formValues.opensearchConfig.dbPass,
            dbHost: formValues.opensearchConfig.dbHost,
            dbPort: formValues.opensearchConfig.dbPort,
            dbName: formValues.opensearchConfig.dbName,
            dbAdditionalResponse:
              formValues.opensearchConfig.dbAdditionalResponse ?? "",
            chatbotId,
          },
        });
      } else if (id && formValues.opensearchConfig?.id) {
        setSavingText("Updating Opensearch Config...");
        await updateOpenSearchConfig({
          variables: {
            id: formValues.opensearchConfig?.id,
            dbResponse: formValues.opensearchConfig?.dbResponse ?? "",
            dbUser: formValues.opensearchConfig?.dbUser ?? "",
            dbPass: formValues.opensearchConfig?.dbPass ?? "",
            dbHost: formValues.opensearchConfig?.dbHost ?? "",
            dbPort: formValues.opensearchConfig?.dbPort ?? "",
            dbName: formValues.opensearchConfig?.dbName ?? "",
            dbAdditionalResponse:
              formValues.opensearchConfig?.dbAdditionalResponse ?? "",
            chatbotId,
          },
        });
      }
    }
    if (files.length) {
      setSavingText("Uploading Documents...");
      await uploadDocuments(chatbotId);
    }
    setNotification({
      message: "succesfully added",
      open: true,
      type: "success",
    });
    await getOneChatbot(chatbotId, organizationId ?? "");
    setLoading(false);
    setProgress(100);
    if (steps.length > 1) {
      navigate(
        `/dashboard/chatbot/${chatbotId}/overview?organization_id=${organizationId}`
      );
    }
  };

  const cleanFormValues = (
    formValues: Partial<ChatbotFormType>
  ): ChatbotInput => {
    return {
      chatboxTitle: formValues.chatboxTitle ?? "",
      welcomeMessage: formValues.welcomeMessage ?? "",
      description: formValues.description,
      extractionAgent: formValues.extractionAgent,
      personalised: formValues.personalised,
    };
  };

  const updateHelperQuestions = async (chatbotId: string) => {
    const chatbot = state.ChatbotReducer.chatbots.find(
      (item) => item.id === id
    );
    if (formValues.helpQuestions.length > 0) {
      const variables:
        | Exact<{
            id?: string;
            chatbotId: string;
            questions: string;
            organizationId?: string;
          }>
        | undefined = {
        chatbotId: id ? id : chatbotId,
        organizationId: organizationId ?? "",
        questions: JSON.stringify(
          formValues.helpQuestions.map((item) => ({
            question: item.question,
          }))
        ),
      };
      if (chatbot?.chatbotHelperQuestions[0] && id) {
        variables.id = chatbot?.chatbotHelperQuestions[0]?.id;
      }

      return createHelperQuestions({
        variables,
      });
    } else {
      const variables:
        | Exact<{
            id?: string;
            chatbotId: string;
          }>
        | undefined = {
        chatbotId: id ? id : chatbotId,
      };
      if (chatbot?.chatbotHelperQuestions[0] && id) {
        variables.id = chatbot?.chatbotHelperQuestions[0]?.id;
      }
      if (variables.id) {
        return deleteHelperQuestions({
          variables: variables as Exact<{ id: any; chatbotId: any }>,
        });
      } else {
        if (id) {
          updateState("", null, [] as HelperQuestionsType[]);
          setLoading(false);
        }
      }
    }
  };

  const updateQuestionPairs = async (chatbotId: string) => {
    const chatbot = state.ChatbotReducer.chatbots.find(
      (item) => item.id === id
    );
    let documentId = "";
    if (chatbot) {
      const questionPair = chatbot.documents.filter((item) =>
        isQuestionPair(item)
      );
      if (questionPair?.length) {
        documentId = questionPair[0].id;
      }
    }
    if (formValues.questionPairs.length > 0) {
      const variables:
        | Exact<{
            id?: string;
            chatbotId: string;
            questionPairs: string;
          }>
        | undefined = {
        chatbotId: id ? id : chatbotId,
        questionPairs: JSON.stringify(
          formValues.questionPairs.map((item) => ({
            question: item.question,
            answer: item.answer,
          }))
        ),
      };
      if (id) {
        variables.id = documentId;
      }
      return createQuestionPairs({
        variables,
      });
    }
  };

  const deleteQuestionPairs = async (chatbotId: string) => {
    const chatbot = state.ChatbotReducer.chatbots.find(
      (item) => item.id === id
    );
    let documentId = "";
    if (chatbot) {
      const questionPair = chatbot.documents.filter((item) =>
        isQuestionPair(item)
      );
      if (questionPair?.length) {
        documentId = questionPair[0].id;
      }
    }
    if (formValues.questionPairs.length === 0 && documentId !== "") {
      const document = await deleteDocumentData({
        variables: { id: documentId },
      });
      if (document.data?.deleteDocument?.success) {
        dispatch({
          type: ChatbotAction.SET_CHATBOT_DATA,
          payload: {
            ...state.ChatbotReducer,
            chatbots: state.ChatbotReducer.chatbots.map((item) => {
              if (chatbot?.id === item.id) {
                item.documents = item.documents.filter(
                  (doc) => doc.id !== documentId
                );
              }
              return item;
            }),
          },
        });
      }
    }
  };

  const createChannels = async (chatbotId: string) => {
    formValues.channelProfiles = formValues.channelProfiles.map(
      ({ file, ...rest }) => ({
        ...rest,
        logo: file as any,
      })
    );
    for (const channel of formValues.channels) {
      const channelCreated = await createOrUpdateChannel({
        variables: {
          id: "",
          input: {
            name: channel.name,
            domains: channel.domains,
            chatbotId: chatbotId,
            mode: channel.mode,
          },
        },
      });
      const updatedChannelId =
        channelCreated.data?.createOrUpdateChannel?.channel?.id;
      if (updatedChannelId) {
        await updateChannelProfile({
          variables: {
            channelProfileData: {
              ...(formValues.channelProfiles.find(
                (item) => item.channelId === channel.id
              ) as ChannelProfileInput),
              channelId: updatedChannelId,
            },
          },
        });
      }
    }
  };

  const startProgress = () => {
    const interval = setInterval(() => {
      if (progress < 90) {
        setProgress((oldProgress) => {
          if (oldProgress < 100) {
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 100);
          }
          clearInterval(interval);
          return 100;
        });
      } else {
        clearInterval(interval);
      }
    }, 1200);
  };

  const setUrl = (step: number) => {
    searchParams.set("step", (step + 1).toString());
    setSearchParams(searchParams);
  };
  const onSubmit: React.FormEventHandler<HTMLFormElement> | undefined = async (
    e
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const chatbot = state.ChatbotReducer.chatbots.find(
      (item) => item.id === state.ChatbotReducer.chatbotSelected
    );

    const channelId = state.ChatbotReducer.channelId;

    const formValidator = verifyChatbotForm(
      formValues,
      id ?? "",
      chatbot,
      files,
      (tab ?? 0) - 1
    );

    if (formValidator.status) {
      setLoading(true);
      startProgress();

      if (id) {
        if (tab === TABENUM.KNOWLEDGE_BASE) {
          updateDocuments(id);
          getOrganizationStats({
            variables: {
              organizationId: state.UserReducer.organization.id,
            },
          });
        } else {
          const { data: chatbot } = await updateChatbot({
            variables: {
              chatbotData: cleanFormValues(formValues),
              id,
            },
          });

          if (chatbot?.updateChatbot?.chatbot?.id) {
            const chatbotId = chatbot.updateChatbot.chatbot.id;
            if (tab === TABENUM.CHANNEL) {
              const channelCreated = await createOrUpdateChannel({
                variables: {
                  id: channelId,
                  input: {
                    name: formValues.name,
                    domains: formValues.domains,
                    chatbotId,
                    mode: formValues.mode,
                  },
                },
              });
              const updatedChannelId =
                channelCreated.data?.createOrUpdateChannel?.channel?.id;

              updateChannelProfileData(updatedChannelId ?? "", chatbotId);
            } else if (tab === TABENUM.CONFIGURATION_QUESTION_HELPER) {
              await updateHelperQuestions(chatbotId);
            } else {
              setLoading(false);
              setSavingText("Updating Chatbot...");
              updateState();
            }
          }
        }
      } else {
        const { data: chatbot } = await createChatbot({
          variables: {
            chatbotData: cleanFormValues(formValues),
            organizationId,
          },
        });
        if (chatbot?.createChatbot?.chatbot) {
          const chatbotId = chatbot.createChatbot.chatbot.id;
          await createChannels(chatbotId);
          await updateHelperQuestions(chatbotId);
          updateDocuments(chatbotId);
          getOrganizationStats({
            variables: {
              organizationId: state.UserReducer.organization.id,
            },
          });
        }
      }
    } else {
      setNotification({
        message: formValidator.reason.join("<br />"),
        open: true,
        type: "error",
      });
      setUrl(formValidator.step);
      setStep(formValidator.step);
    }
  };

  const getChatbot = () => {
    const chatbot = state.ChatbotReducer.chatbots.find(
      (item) => item.id === id
    );
    const channel = chatbot?.chatbotChannels.find(
      (item) => item.id === channelId
    );
    if (chatbot) {
      setFormValues((prevState) => ({
        ...prevState,
        chatboxTitle: chatbot.chatboxTitle,
        welcomeMessage: chatbot.welcomeMessage,
        description: chatbot.description,
        questionPairs: fillQuestionPairs(chatbot),
        sqlConfig: fillSQLConfig(chatbot),
        opensearchConfig: fillOpenSearchConfig(chatbot),
        helpQuestions: fillHelperQuestions(chatbot),
      }));
    }

    if (channel) {
      setFormValues((prevState) => ({
        ...prevState,
        domains: channel.domains,
        mode: channel.mode,
        name: channel.name,
        design: {
          ...prevState.design,
          hideSources: channel.channelProfile?.hideSources ?? false,
          hideSystemMessage: channel.channelProfile?.hideSystemMessage ?? false,
          hideTopBar: channel.channelProfile?.hideTopBar ?? false,
          onLoad: channel.channelProfile?.onLoad ?? false,
          feedbackView:
            channel.channelProfile?.feedbackView ?? FeedbackViewEnum.Thumb,
        },
      }));
    }
    // if (channel && chatbot) {
    //   setFormValues({
    //     ...formValues,
    //     chatboxTitle: chatbot.chatboxTitle,
    //     domains: channel.domains,
    //     welcomeMessage: chatbot.welcomeMessage,
    //     description: chatbot.description,
    //     mode: channel.mode,
    //     name: channel.name,
    //     design: {
    //       ...formValues.design,
    //       hideSources: channel.channelProfile?.hideSources ?? false,
    //       hideSystemMessage: channel.channelProfile?.hideSystemMessage ?? false,
    //       hideTopBar: channel.channelProfile?.hideTopBar ?? false,
    //       onLoad: channel.channelProfile?.onLoad ?? false,
    //       feedbackView:
    //         channel.channelProfile?.feedbackView ?? FeedbackViewEnum.Thumb,
    //     },
    //     questionPairs: fillQuestionPairs(chatbot),
    //     sqlConfig: fillSQLConfig(chatbot),
    //     opensearchConfig: fillOpenSearchConfig(chatbot),
    //     helpQuestions: fillHelperQuestions(chatbot),
    //   });
    // }
  };

  const handleClose = () => {
    if (isFormChanged(formValues)) {
      setOpen(true);
    } else {
      leaveCreate();
    }
  };

  const steps = getChatbotSteps({
    formValues,
    handleChange,
    onSubmit,
    open: notification.open,
    setFormValues,
    setFiles,
    files,
    loading,
  }).filter((item) =>
    tab ? item.tab === tab : item.tab !== TABENUM.CONFIGURATION_QUESTION_HELPER
  );

  useEffect(() => {
    if (id) {
      getChatbot();
    }
  }, [id, state.ChatbotReducer.chatbots, channelId]);

  return (
    <>
      {(errorChatbot || errorDocument || notification.open || errorUpdate) && (
        <Notification
          message={
            errorChatbot?.message ||
            errorDocument?.message ||
            errorUpdate?.message ||
            notification.message
          }
          open={notification.open}
          setOpen={setNotification}
          type={notification.type}
        />
      )}
      <AlertDialog
        open={open}
        description="Are you sure you want to leave, your changes will be gone?"
        title="Cancel"
        handleClose={() => setOpen(false)}
        handleConfirm={leaveCreate}
      />
      {!!state.ChatbotReducer.chatbots.length &&
        steps.length > 1 &&
        !loading && (
          <div className="flex flex-row-reverse p-2">
            <CloseIcon className="cursor-pointer" onClick={handleClose} />
          </div>
        )}
      <HorizontalLinearStepper
        key={step}
        onFinish={onSubmit}
        formValues={formValues}
        files={files}
        chatbot={chatbot}
        setNotification={setNotification}
        steps={steps}
        loading={loading}
        progress={progress}
        savingText={savingText}
      />
    </>
  );
};

export default ChatbotCreation;
