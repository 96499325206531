export const customFonts = [
    {
        name: "Raleway",
        fontFamily: "Raleway,sans-serif",
    },
    {
        name: "Arial",
        fontFamily: "Arial, sans-serif",
    },
    {
        name: "Helvetica",
        fontFamily: "Helvetica, sans-serif",
    },
    {
        name: "Courier",
        fontFamily: "courier, 'courier new', monospace",
    },
    {
        name: "Palatino",
        fontFamily: "'palatino linotype', palatino, serif",
    },
    {
        name: "Georgia",
        fontFamily: "georgia, serif",
    },
    {
        name: "Times New Roman",
        fontFamily: "'times new roman', times, serif",
    },
    {
        name: "FreeMono",
        fontFamily: "FreeMono, monospace",
    }
]