import { FC } from "react";
import { useApp } from "../states/AppContext";

const ChatbotAuthor: FC = () => {
  const [state] = useApp();
  const chatbot = state.ChatbotReducer.chatbots.find(
    (item) => item.id === state.ChatbotReducer.chatbotSelected
  );
  const name = chatbot?.user?.name ? chatbot?.user?.name : "Admin User";

  return <div className="text-xs"> {name} - {new Date(chatbot?.created).toLocaleString(undefined, {
    dateStyle: "medium",
    timeStyle: "short",
  })} </div>;

};

export default ChatbotAuthor;
