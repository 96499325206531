import { useLocation, useNavigate } from "react-router-dom";
import { useMeLazyQuery } from "../generated/graphql";
import { IUser, UserAction } from "../interfaces/Users";
import { useApp } from "../states/AppContext";

export const setToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

export const checkToken = () => {
  return !!localStorage.getItem("token");
};

export const useUser = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useApp();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const organizationId = queryParams.get("organization_id");
  const setUser = (user: IUser) => {
    dispatch({
      type: UserAction.SET_USER_DATA,
      payload: {
        dateJoined: user.dateJoined,
        email: user.email,
        name: user.name,
        isActive: user.isActive,
        isOwner: user.isOwner,
        isMaster: user.isMaster,
        organization: {
          id: user.organization.id,
          name: user.organization.name,
          numberOfChatbots: user.organization.numberOfChatbots,
          monthlyQuestionsLimit: user.organization.monthlyQuestionsLimit,
          storageLimit: user.organization.storageLimit,
          timezone: user.organization.timezone,
          priceTierConfig: {
            numberOfChatbots:
              user.organization.priceTierConfig.numberOfChatbots,
            numberOfFilePerBot:
              user.organization.priceTierConfig.numberOfFilePerBot,
            fileSizeLimit: user.organization.priceTierConfig.fileSizeLimit,
            storageLimit: user.organization.priceTierConfig.storageLimit,
            monthlyQuestionsLimit:
              user.organization.priceTierConfig.monthlyQuestionsLimit,
            chabotBranding: {
              logo: user.organization.priceTierConfig.chabotBranding.logo,
              colors: user.organization.priceTierConfig.chabotBranding.colors,
            },
            acl: user.organization.priceTierConfig.acl,
            price: user.organization.priceTierConfig.price,
          },
          priceTierType: user.organization.priceTierType,
        },
        id: user.id,
      },
    });
  };
  const [me, { loading }] = useMeLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data.me && data.me.organization) {
        setUser(data.me as IUser);
        if (organizationId === "null") {
          navigate(
            `${location.pathname}?organization_id=${data.me.organization.id}`
          );
        }
      }
    },
    onError() {
      logout();
      navigate("/login");
    },
  });
  const loginUser = (orgId: string, token?: string) => {
    if (token) {
      setToken(token);
    }
    return me({
      variables: {
        organizationId:
          orgId ??
          (organizationId !== null && organizationId !== "null"
            ? organizationId
            : ""),
      },
    });
  };

  const logout = () => {
    removeToken();
  };

  return {
    user: state.UserReducer,
    isAuthenticated: checkToken(),
    loginUser,
    loading,
    setUser,
    logout,
  };
};
