import { Box, Menu, MenuItem, Modal } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { FC, useEffect, useState } from "react";
import Website, { ChatbotModalProps } from "./Website";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { CreateBtn } from "../../atoms/CreateBtn";
import { verifyChatbotForm } from "./utils";
import { INotification } from "../../interfaces/General";
import { Notification } from "../../molecules/Notification";
import { useParams } from "react-router-dom";
import { useApp } from "../../states/AppContext";
import Channels from "../../molecules/Channels";
import { ChatbotAction } from "../../interfaces/Chatbot";
import { ChanelModeEnum } from "../../generated/graphql";
import { setDefaultDesign } from ".";

const style = {
  position: "absolute" as "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -20%)",
  width: 800,
  height: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,

  p: 4,
};

export enum configModalEnum {
  CHATBOX = "CHATBOX",
  FULL_SCREEN = "FULL_SCREEN",
  API = "API",
  CONFIG = "CONFIG",
  DESIGN = "DESIGN",
}
const Channel: FC<ChatbotModalProps> = (props) => {
  const { id } = useParams();
  const [state, dispatch] = useApp();

  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<configModalEnum | null>(null);
  const [isChangedForm, setIsChangedForm] = useState(false);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : // close menu when already open
          null
    );
  };

  const handleOpen = () => setOpen(true);
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleOpenChatbox = () => {
    handleCloseContextMenu();
    setActiveTab(configModalEnum.CHATBOX);
    dispatch({
      type: ChatbotAction.SET_CHANNEL,
      payload: {
        ...state.ChatbotReducer,
        channelId: "",
      },
    });
    if (!id) {
      props.setFormValues({
        ...props.formValues,
        name: "",
        domains: "",
        mode: ChanelModeEnum.Chatbox,
        design: setDefaultDesign(),
      });
    }
  };
  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const handleClose: React.MouseEventHandler<HTMLButtonElement> | undefined = (
    e
  ) => {
    const formValidator = verifyChatbotForm(
      props.formValues,
      "",
      undefined,
      undefined,
      4
    );
    if (formValidator.status) {
      setOpen(false);
      setActiveTab(null);
      if (id) {
        props.onSubmit(e as unknown as React.FormEvent<HTMLFormElement>);
      } else {
        const channelFakeId = uuidv4();
        props.setFormValues({
          ...props.formValues,
          channels: [
            ...props.formValues.channels,
            {
              name: props.formValues.name,
              domains: props.formValues.domains,
              mode: ChanelModeEnum.Chatbox,
              id: channelFakeId,
              chatbotId: "",
            },
          ],
          channelProfiles: [
            ...props.formValues.channelProfiles,
            {
              channelId: channelFakeId,
              color: props.formValues.design.color,
              hoverColor: props.formValues.design.hoverColor,
              logoSize: props.formValues.design.logoSize,
              chatbotTitleColor: props.formValues.design.chatbotTitleColor,
              chatbotTitleSize: props.formValues.design.chatbotTitleSize,
              chatbotFont: props.formValues.design.chatbotFont,
              file: props.formValues.design.file,
              logo: props.formValues.design.logo,
              hideSources: props.formValues.design.hideSources,
              hideSystemMessage: props.formValues.design.hideSystemMessage,
              hideTopBar: props.formValues.design.hideTopBar,
              onLoad: props.formValues.design.onLoad,
              feedbackView: props.formValues.design.feedbackView,
            },
          ],
        });
      }
    } else {
      setNotification({
        message: formValidator.reason.join("<br />"),
        open: true,
        type: "error",
      });
    }
  };

  const checkFormChanged = () => {
    const chatbot = state.ChatbotReducer.chatbots.find(
      (item) => item.id === state.ChatbotReducer.chatbotSelected
    );

    const channeldId = state.ChatbotReducer.channelId;

    const channel = chatbot?.chatbotChannels.find(
      (item) => item.id === channeldId
    );

    if (
      (props.formValues.design.color !== channel?.channelProfile?.color ||
        props.formValues.design.hoverColor !==
          channel?.channelProfile?.hoverColor ||
        props.formValues.design.logoSize !==
          channel?.channelProfile?.logoSize ||
        props.formValues.design.chatbotTitleColor !==
          channel?.channelProfile?.chatbotTitleColor ||
        props.formValues.design.chatbotTitleSize !==
          channel?.channelProfile?.chatbotTitleSize ||
        props.formValues.design.chatbotFont !==
          channel?.channelProfile?.chatbotFont ||
        props.formValues.design.logo[0]?.name !==
          channel?.channelProfile?.logo ||
        props.formValues.design.file?.name !==
          channel?.channelProfile?.fileName ||
        props.formValues.domains !== channel?.domains ||
        props.formValues.name !== channel?.name) &&
      props.formValues.name !== "" &&
      props.formValues.domains !== ""
    ) {
      setIsChangedForm(true);
    } else {
      setIsChangedForm(false);
    }
  };

  useEffect(() => {
    checkFormChanged();
  }, [props.formValues]);

  useEffect(() => {
    if (activeTab) {
      handleOpen();
    }
  }, [activeTab]);

  return (
    <div className="flex w-full h-full flex-col">
      <Notification {...notification} setOpen={setNotification} />
      <Modal open={open}>
        <Box sx={style}>
          <Website {...props} activeTab={activeTab} />

          <div className="flex absolute bottom-0 p-6 w-[90%] flex-row-reverse ">
            <div className="ml-2">
              <CreateBtn
                onClick={() => {
                  setOpen(false);
                  setActiveTab(null);
                }}
              >
                Close
              </CreateBtn>
            </div>

            <CreateBtn onClick={handleClose} disabled={!isChangedForm}>
              Done
            </CreateBtn>
          </div>
        </Box>
      </Modal>
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleOpenChatbox}>Chatbox</MenuItem>
        <MenuItem onClick={handleCloseContextMenu} disabled>
          Full screen
        </MenuItem>
        <MenuItem onClick={handleCloseContextMenu} disabled>
          API
        </MenuItem>
      </Menu>

      <Channels
        setActiveTab={setActiveTab}
        formValues={props.formValues}
        setFormValues={props.setFormValues}
      />
      <div className="flex items-center justify-center mt-4">
        <CreateBtn onClick={handleContextMenu}>
          <AddCircleOutlineIcon className="mr-1" />
          New Channel
        </CreateBtn>
      </div>
    </div>
  );
};

export default Channel;
