import { Grid, Paper, styled, CircularProgress } from "@mui/material";
import { useApp } from "../states/AppContext";
import {
  useDeleteChatbotMutation,
  useDocumentsLazyQuery,
  useOrganizationStatsLazyQuery,
} from "../generated/graphql";
import { useEffect, useState } from "react";

import { DeleteModifyActionBtn } from "../atoms/DeleteModifyActionBtn";
import { useChatbot } from "../utils/chatbot";
import AlertDialog from "./Dialog";
import DocumentsAccordion from "./DocumentsAccordion";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { Link, useLocation, useParams } from "react-router-dom";
import ChatbotTitle from "../atoms/ChatbotTitle";
import ChatbotAuthor from "../atoms/ChatbotAuthor";
import LoadingFade from "./LoadingFade";
import { Notification } from "./Notification";
import { INotification } from "../interfaces/General";
import { UserAction } from "../interfaces/Users";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const Overview = () => {
  const [state, dispatch] = useApp();
  const { id } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const organizationId = queryParams.get("organization_id");
  const { deleteChatbot } = useChatbot();
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const chatbot = state.ChatbotReducer.onlyChatbots.find(
    (item) => item.id === state.ChatbotReducer.chatbotSelected
  );
  const [getOrganizationStats] = useOrganizationStatsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(data) {
      dispatch({
        type: UserAction.SET_USER_DATA,
        payload: {
          ...state.UserReducer,
          organization: {
            ...state.UserReducer.organization,
            numberOfChatbots: data.organization?.numberOfChatbots
              ? data.organization?.numberOfChatbots
              : 0,
            monthlyQuestionsLimit: data.organization?.monthlyQuestionsLimit
              ? data.organization?.monthlyQuestionsLimit
              : 0,
            storageLimit: data.organization?.storageLimit
              ? data.organization?.storageLimit
              : 0,
          },
        },
      });
    },
  });
  const [deleteCurrentChatbot, { loading: loadingDelete }] =
    useDeleteChatbotMutation({
      variables: { id: chatbot?.id },
      onCompleted(data) {
        if (data.deleteChatbot?.id) {
          deleteChatbot(data.deleteChatbot?.id);

          setOpen(false);
          getOrganizationStats({
            variables: {
              organizationId: state.UserReducer.organization.id,
            },
          });
        } else {
          setNotification({
            message: "Error deleting chatbot",
            open: true,
            type: "success",
          });
        }
      },
      onError(error) {
        setNotification({
          message: "You don't have permission to delete this chatbot",
          open: true,
          type: "error",
        });
      },
    });

  const [getKGraph, { data }] = useDocumentsLazyQuery({
    variables: { chatbotId: state.ChatbotReducer.chatbotSelected },
  });

  const getKGraphConfig = async () => {
    getKGraph();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getFileName = (filename: string) => {
    const regex = /^pairs[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}\.txt$/;
    const isQA = regex.test(filename);
    if (isQA) {
      return "Q&A";
    }
    return filename;
  };
  useEffect(() => {
    if (state.ChatbotReducer.chatbotSelected) {
      getKGraphConfig();
    }
  }, [state.ChatbotReducer.chatbotSelected]);

  return (
    <>
      {notification.open && (
        <Notification
          message={notification.message}
          open={notification.open}
          setOpen={setNotification}
          type={notification.type}
        />
      )}
      <AlertDialog
        description={
          "Are you sure do you want to delete '" +
          chatbot?.chatboxTitle +
          "' bot?"
        }
        title="Delete Chatbot"
        open={open}
        handleClose={handleClose}
        handleConfirm={() => deleteCurrentChatbot()}
        maxWidth="md"
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <ChatbotTitle />
            <ChatbotAuthor />
            <div className="pt-3 flex justify-center">
              <Link
                to={`/dashboard/chatbot/${chatbot?.id}/channels?organization_id=${organizationId}`}
              >
                <DeleteModifyActionBtn
                  startIcon={
                    <RocketLaunchIcon style={{ color: "rgb(47, 128, 237)" }} />
                  }
                  children={"Deploy"}
                />
              </Link>
              <DeleteModifyActionBtn
                startIcon={
                  <DeleteOutlineOutlinedIcon
                    style={{ color: "rgb(47, 128, 237)" }}
                  />
                }
                onClick={() => setOpen(true)}
                children={
                  loadingDelete ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Delete"
                  )
                }
              />
            </div>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <p className="text-left pb-3">
              Following is a list of uploaded documents. When you click on a
              filename, you will see a document's summary.
            </p>
            {data?.documents ? (
              data?.documents.map((item) => (
                <DocumentsAccordion
                  name={getFileName(item?.fileName ?? "")}
                  summary={item?.summary ?? ""}
                  key={item?.file}
                />
              ))
            ) : (
              <LoadingFade count={2} />
            )}
          </Item>
        </Grid>
      </Grid>
    </>
  );
};

export default Overview;
