import { ChatbotFormType } from ".";
import {
  ChatbotType,
  DocumentType,
  HelperQuestionsType,
} from "../../generated/graphql";
import {
  MAX_CHATBOX_TITLE_SIZE,
  MAX_DESCRIPTION_SIZE,
  MAX_DOMAIN_SIZE,
  MAX_WELCOME_MESSAGE_SIZE,
} from "../../utils/config";

export const verifyChatbotForm = (
  formValues: ChatbotFormType,
  id: string,
  chatbot: ChatbotType | undefined,
  files?: File[],
  step?: number
) => {
  const verifyStep0 = () => {
    const reason = [];
    if (formValues.chatboxTitle.length < MAX_CHATBOX_TITLE_SIZE)
      reason.push("Chatbox title is empty");
    if (
      (formValues.description?.length ? formValues.description?.length : 100) <
      MAX_DESCRIPTION_SIZE
    ) {
      reason.push("Description length is too short");
    }
    if (formValues.welcomeMessage.length < MAX_WELCOME_MESSAGE_SIZE) {
      reason.push("Welcome message length is too short");
    }
    if (reason.length) {
      return {
        status: false,
        step: 0,
        reason,
      };
    }
    return { status: true, step: 0, reason };
  };

  const verifyStep1 = () => {
    // if (
    //   (!id && !files?.length) ||
    //   (id && !chatbot?.documents?.length && !files?.length)
    // )
    //   return {
    //     status: false,
    //     step: 1,
    //     reason: ["Knowledge base needs at least 1 document"],
    //   };
    return { status: true, step: 1, reason: [] };
  };

  const verifyStep4 = () => {
    const reason = [];
    if (formValues.domains.length < MAX_DOMAIN_SIZE)
      reason.push("Domains can't be empty");
    if (reason.length) {
      return {
        status: false,
        step: 5,
        reason,
      };
    }
    return { status: true, step: 4, reason };
  };

  switch (step) {
    case 0:
    case 6:
      return verifyStep0();

    case 1:
      return verifyStep1();
    case 2:
    case 3:
    case 4:
    case 6:
      return { status: true, step, reason: [] };
    case 5:
      return verifyStep4();
    default:
      const step0 = verifyStep0();
      const step1 = verifyStep1();
      const step4 = verifyStep4();
      return {
        status: step0.status && step1.status && step4.status,
        reason: step0.reason.concat(step1.reason, step4.reason),
        step: !step0.status ? 0 : !step1.status ? 1 : 5,
      };
  }
};

export const isQuestionPair = (document: DocumentType) =>
  Object.entries(JSON.parse(document.questionPairs)).length;
