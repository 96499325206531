import { FC } from "react";
import Design from "../../Design";
import { ChatbotModalProps } from ".";
import FormItemContainer from "../../../atoms/FormItemContainer";
import { FormControlLabel, MenuItem, Select, Switch } from "@mui/material";
import { FeedbackViewEnum } from "../../../generated/graphql";

const ChatbotDesign: FC<ChatbotModalProps> = (props) => {
  return (
    <div className="overflow-y-auto h-[615px]">
      <FormItemContainer>
        <FormControlLabel
          className="w-full flex p-0 justify-end"
          style={{ marginLeft: "0px" }}
          control={
            props.formValues.design.feedbackView ? (
              <Select
                name="feedbackView"
                className="w-1/2"
                value={props.formValues.design.feedbackView}
                onChange={(event) =>
                  props.handleChange &&
                  props.handleChange(
                    {
                      target: {
                        name: "feedbackView",
                        value: event.target.value,
                      },
                    } as React.ChangeEvent<HTMLInputElement>,
                    event.target.value as FeedbackViewEnum
                  )
                }
              >
                <MenuItem value={FeedbackViewEnum.Star}>Star(⭐)</MenuItem>
                <MenuItem value={FeedbackViewEnum.Thumb}>
                  Thumb(👍, 👎)
                </MenuItem>
                <MenuItem value={FeedbackViewEnum.Smile}>
                  Smile(🙂, 😐, 🙁)
                </MenuItem>
              </Select>
            ) : (
              <></>
            )
          }
          label={<div className="mr-2">Feedback Format</div>}
          labelPlacement="start"
        />
      </FormItemContainer>
      <FormItemContainer>
        <FormControlLabel
          control={
            <Switch
              name="hideSystemMessage"
              defaultChecked={!props.formValues.design.hideSystemMessage}
              checked={!props.formValues.design.hideSystemMessage}
              onChange={(e) =>
                props.handleChange && props.handleChange(e, !e.target.checked)
              }
            />
          }
          label="System Messages&nbsp;&nbsp;&nbsp;"
        />

        <FormControlLabel
          control={
            <Switch
              name="hideSources"
              defaultChecked={!props.formValues.design.hideSources}
              checked={!props.formValues.design.hideSources}
              onChange={(e) =>
                props.handleChange && props.handleChange(e, !e.target.checked)
              }
            />
          }
          label="Source Citations"
        />
        {props.formValues.design.onLoad ? (
          <>
            <FormControlLabel
              control={
                <Switch
                  name="hideTopBar"
                  defaultChecked={!props.formValues.design.hideTopBar}
                  checked={!props.formValues.design.hideTopBar}
                  onChange={(e) => {
                    props.handleChange &&
                      props.handleChange(e, !e.target.checked);
                  }}
                />
              }
              label="Chat window top bar"
            />
          </>
        ) : (
          <></>
        )}
        <FormControlLabel
          control={
            <Switch
              name="onLoad"
              defaultChecked={props.formValues.design.onLoad}
              checked={props.formValues.design.onLoad}
              onChange={(e) => {
                props.handleChange && props.handleChange(e, e.target.checked);
                if (!e.target.checked) {
                  props.handleChange &&
                    props.handleChange(
                      {
                        target: { name: "hideTopBar" },
                      } as React.ChangeEvent<HTMLInputElement>,
                      false
                    );
                }
              }}
            />
          }
          label="Open chat window automatically when page has loaded"
        />
      </FormItemContainer>
      <Design {...props} />
    </div>
  );
};

export default ChatbotDesign;
