import { ChatbotType } from "../generated/graphql";
import { TABENUM } from "../organisms/ChatbotCreation/steps";
import { IUser } from "./Users";

export interface IChatbot {
  id: string;
  chatboxTitle: string;
  welcomeMessage: string;
  domains: string;
  personalised: boolean;
  extractionAgent: boolean;
  created: string;
  user: IUser;
}

export interface IChatbotDispatchState {
  type: ChatbotAction;
  payload: IChatbotState;
}

export enum ChatbotAction {
  SET_CHATBOT_DATA,
  SET_ONLY_CHATBOT_DATA,
  SET_CHANNEL,
}

export interface IChatbotState {
  onlyChatbots: Array<ChatbotType>;
  chatbots: Array<ChatbotType>;
  chatbotSelected: string;
  channelId?: string;
}

export interface ISTEPPER {
  label: string;
  isOptional?: boolean;
  tab: TABENUM;
  component: () => JSX.Element;
}

export type IChatbotTabs = Array<{
  label: string;
  component: (activeTab: number) => JSX.Element;
}>;

export interface IChatbotTabsProps {
  tabs: IChatbotTabs;
}
