import { useEffect, useState } from "react";
import { CircularProgress, TextField } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { INotification } from "../../interfaces/General";
import { Notification } from "../../molecules/Notification";
import FormItemContainer from "../../atoms/FormItemContainer";
import { CreateBtn } from "../../atoms/CreateBtn";
import {
  useOrganizationLazyQuery,
  useSignupOrganizationWithAdminMutation,
  useUpdateOrganizationMutation,
} from "../../generated/graphql";
import { PriceTierTypeEnum } from "../../interfaces/Organization";
import { TimeZone } from "../../atoms/TimeZone";
import { PriceTier } from "../../atoms/PriceTier";
import { useUser } from "../../utils/auth";

const CreateOrganization = () => {
  const navigate = useNavigate();
  const { loginUser } = useUser();
  const { orgId: id } = useParams();
  const buttonValue = id ? "Edit" : "Create";
  const [formValues, setFormValues] = useState({
    name: "",
    priceTierType: PriceTierTypeEnum.BRONZE,
    timezone: "UTC",
    adminName: "",
    adminEmail: "",
  });
  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "error",
  });

  const [updateOrganization] = useUpdateOrganizationMutation({
    variables: {
      id: id,
      priceTierType: formValues.priceTierType,
      timezone: formValues.timezone,
    },
    async onCompleted(data) {
      if (data.updateOrganization?.organization?.id) {
        await loginUser(id ?? "");
        setNotification({
          message: "You have successfully updated your Organization!",
          open: true,
          type: "success",
        });
        setTimeout(() => {
          navigate("/login/master-admin-select-organization");
        }, 1500);
      } else {
        setNotification({
          message:
            "Error happened while updating organization, contact to admin",
          open: true,
          type: "error",
        });
      }
    },
    onError(error) {
      setNotification({
        message: error.message,
        open: true,
        type: "error",
      });
    },
  });

  const [getOrganization] = useOrganizationLazyQuery({
    variables: { id: id },
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (data.organization) {
        setFormValues({
          ...formValues,
          name: data.organization.name,
          priceTierType: data.organization.priceTierType as PriceTierTypeEnum,
          timezone: data.organization.timezone || "UTC",
        });
      }
    },
  });
  const [signupOrganization, { loading }] =
    useSignupOrganizationWithAdminMutation({
      variables: {
        name: formValues.name,
        priceTierType: formValues.priceTierType,
        timezone: formValues.timezone,
        adminName: formValues.adminName,
        adminEmail: formValues.adminEmail,
      },
      async onCompleted(data) {
        if (data.signupOrganization?.success) {
          setNotification({
            message:
              "You have successfully registered your Organization! Please ask admin to check their email for further steps.",
            open: true,
            type: "success",
          });
          setTimeout(() => {
            navigate("/login/master-admin-select-organization");
          }, 1500);
        } else {
          setNotification({
            message:
              "Error happened while register organization, contact to admin",
            open: true,
            type: "error",
          });
        }
      },
      onError(error) {
        setNotification({
          message:
            error.message === "Please use your business email address"
              ? error.message
              : error.message,
          open: true,
          type: "error",
        });
      },
    });

  const handleChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClick = async () => {
    if (!id) {
      await signupOrganization();
    } else {
      await updateOrganization();
    }
  };

  useEffect(() => {
    if (id) {
      getOrganization();
    }
  }, []);

  return (
    <div>
      <Notification {...notification} setOpen={setNotification} />
      <div className="flex pl-4 pb-4">
        <Link to="/login/master-admin-select-organization">
          &#8592; Go back
        </Link>
      </div>
      <FormItemContainer>
        <TextField
          disabled={id ? true : false}
          label="Organization"
          value={formValues.name}
          name="name"
          onChange={handleChange}
          size="small"
          required
          fullWidth
        />
      </FormItemContainer>
      <FormItemContainer>
        <PriceTier formValues={formValues} setFormValues={setFormValues} />
      </FormItemContainer>
      <FormItemContainer>
        <TimeZone formValues={formValues} setFormValues={setFormValues} />
      </FormItemContainer>
      {!id && (
        <>
          <FormItemContainer>
            <TextField
              label="Admin Work Email ID"
              value={formValues.adminEmail}
              name="adminEmail"
              onChange={handleChange}
              size="small"
              required
              fullWidth
            />
          </FormItemContainer>
          <FormItemContainer>
            <TextField
              label="Admin Name"
              value={formValues.adminName}
              name="adminName"
              required
              onChange={handleChange}
              size="small"
              fullWidth
            />
          </FormItemContainer>
        </>
      )}
      <FormItemContainer>
        <CreateBtn
          variant="contained"
          color="primary"
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            buttonValue
          )}
        </CreateBtn>
      </FormItemContainer>
    </div>
  );
};

export default CreateOrganization;
