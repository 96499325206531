import { FC, useEffect, useState } from "react";
import FormItemContainer from "../../atoms/FormItemContainer";
import { CircularProgress, TextField } from "@mui/material";
import {
  useCreateRoleMutation,
  useRoleLazyQuery,
  useUpdateRoleMutation,
} from "../../generated/graphql";
import { CreateBtn } from "../../atoms/CreateBtn";
import { Notification } from "../../molecules/Notification";
import { INotification } from "../../interfaces/General";
import { Link, useNavigate, useParams } from "react-router-dom";

const CreateRole: FC = () => {
  const { roleId } = useParams();
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [getRole] = useRoleLazyQuery({
    variables: { id: roleId },
    onCompleted(data) {
      if (data.role && data.role.role) {
        setRole(data.role.role);
      }
    },
  });
  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [updateRole, { loading: updateLoading }] = useUpdateRoleMutation({
    variables: { id: roleId, role },
    onCompleted(data) {
      if (data.updateRole?.role?.id) {
        setNotification({
          type: "success",
          open: true,
          message: "Role was successfully updated",
        });
      }
    },
    onError(err) {
      setNotification({
        type: "error",
        open: true,
        message: err.message,
      });
    },
  });
  const [createRole, { loading }] = useCreateRoleMutation({
    variables: { role },
    onCompleted(data) {
      if (data.createRole?.role?.id) {
        setNotification({
          type: "success",
          open: true,
          message: "Role was successfully added",
        });
      }
    },
    onError(err) {
      setNotification({
        type: "error",
        open: true,
        message: err.message,
      });
    },
  });
  const handleChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined = (e) => {
    setRole(e.target.value);
  };

  const handleCreateRole = async () => {
    if (roleId) {
      await updateRole();
    } else await createRole();
    setTimeout(() => {
      navigate("/dashboard/admin/roles-list");
    }, 1000);
  };

  useEffect(() => {
    if (roleId) {
      getRole();
    }
  }, []);
  return (
    <>
      <Notification {...notification} setOpen={setNotification} />
      <FormItemContainer>
        <TextField
          label="Role Name"
          name="role"
          value={role}
          onChange={handleChange}
          helperText="set a new role name and then use it in assign role page"
          size="small"
          fullWidth
          required
        />
      </FormItemContainer>
      <FormItemContainer>
        <CreateBtn
          variant="contained"
          color="primary"
          onClick={handleCreateRole}
          disabled={loading || updateLoading}
        >
          {loading || updateLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Save"
          )}
        </CreateBtn>

        <Link to="/dashboard/admin/roles-list" className="ml-4">
          <CreateBtn
            variant="contained"
            color="primary"
            disabled={loading || updateLoading}
          >
            Cancel
          </CreateBtn>
        </Link>
      </FormItemContainer>
    </>
  );
};

export default CreateRole;
