import { FC, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface DescriptionProps {
  description: string | JSX.Element;
  title: string;
  yesLabel?: string;
  noLabel?: string;
  maxWidth?: "lg" | "sm" | "xl" | "md" | "xs";
  fullWidth?: boolean;
  open: boolean;
  isRemoveNo?: boolean;
  handleClose: (decline?: boolean) => void;
  handleConfirm?: () => void;
}

const AlertDialog: FC<DescriptionProps> = ({
  description,
  title,
  yesLabel,
  noLabel,
  maxWidth,
  fullWidth,
  open,
  isRemoveNo,
  handleClose,
  handleConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(true)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth ? maxWidth : "sm"}
      fullWidth={fullWidth}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="min-w-[400px]">{description}</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!isRemoveNo ? (
          <Button onClick={() => handleClose(true)}>
            {noLabel ? noLabel : "No"}
          </Button>
        ) : null}
        <Button
          onClick={() => {
            handleClose(false);
            handleConfirm && handleConfirm();
          }}
          autoFocus
        >
          {yesLabel ? yesLabel : "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
