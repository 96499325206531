import { FC } from "react";
import ChatbotTabs from "../../UI/ChatbotTabs";
import { IChatbotTabs } from "../../../interfaces/Chatbot";

import { ChatbotFormType } from "..";
import KnowledgeBaseFiles from "./KnowledgeBaseFiles";
import FAQ from "./FAQ";
import SQLConfigForm from "./SQLConfig";
import OpenSearchConfigForm from "./OpenSearchConfig";
export interface KnowledgeBaseProps {
  setFormValues: React.Dispatch<React.SetStateAction<ChatbotFormType>>;
  setFiles: React.Dispatch<React.SetStateAction<Array<File>>>;
  formValues: ChatbotFormType;
  files: Array<File>;
  open: boolean;
  loading: boolean;
}
const getTabList = (props: KnowledgeBaseProps): IChatbotTabs => [
  { label: "Files", component: () => <KnowledgeBaseFiles {...props} /> },
  { label: "Q&A pair", component: () => <FAQ {...props} /> },
  { label: "SQL Config", component: () => <SQLConfigForm {...props} /> },
  { label: "OpenSearch Config", component: () => <OpenSearchConfigForm {...props} /> },
];

const KnowledgeBase: FC<KnowledgeBaseProps> = (props) => {
  const tabs = getTabList(props);
  return <ChatbotTabs tabs={tabs} />;
};

export default KnowledgeBase;
