import Overview from "../../molecules/Overview";

const OverviewPage = () => {
  return (
    <div className="pl-20 pr-20">
      <Overview />
    </div>
  );
};

export default OverviewPage;
