import {
  ChatbotAction,
  IChatbotDispatchState,
  IChatbotState,
} from "../../interfaces/Chatbot";

export const chatbotInitialState: IChatbotState = {
  onlyChatbots: [],
  chatbots: [],
  chatbotSelected: "",
};

export const ChatbotReducer = (
  state: IChatbotState = chatbotInitialState,
  action: IChatbotDispatchState
) => {
  switch (action.type) {
    case ChatbotAction.SET_CHATBOT_DATA:
      const chatbots = action?.payload?.chatbots;
      const chatbotSelected = action?.payload?.chatbotSelected;

      return {
        ...state,
        chatbots: chatbots ?? state.chatbots,
        chatbotSelected,
        onlyChatbots: state.onlyChatbots.map((item) => {
          if (chatbots?.length && item.id === chatbots[0]?.id) {
            return chatbots[0];
          }
          return item;
        }),
      };
    case ChatbotAction.SET_ONLY_CHATBOT_DATA:
      return {
        ...state,
        onlyChatbots: action?.payload?.onlyChatbots,
        chatbotSelected:
          action?.payload?.chatbotSelected ?? state.chatbotSelected,
      };

    case ChatbotAction.SET_CHANNEL:
      return {
        ...state,
        channelId: action?.payload?.channelId,
      };
  }
};
