import {
  CircularProgress,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  ChannelInput,
  ChannelType,
  useChannelsLazyQuery,
  useDeleteChannelMutation,
  useUpdateChannelMutation,
} from "../../generated/graphql";
import { useLocation, useParams } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { configModalEnum } from "../../organisms/ChatbotCreation/Channel";
import AlertDialog from "../Dialog";
import { useApp } from "../../states/AppContext";
import { ChatbotAction } from "../../interfaces/Chatbot";
import { Notification } from "../Notification";
import { INotification } from "../../interfaces/General";
import {
  ChatbotFormType,
  IChannelProfileDesign,
} from "../../organisms/ChatbotCreation";

const ActionContainer: FC<React.HTMLAttributes<HTMLSpanElement>> = ({
  children,
  ...props
}) => {
  return (
    <span
      {...props}
      className={`font-bold mx-1 ${props.className} cursor-pointer hover:underline
      `}
    >
      {children}
    </span>
  );
};

interface ChannelsProps {
  setActiveTab: React.Dispatch<React.SetStateAction<configModalEnum | null>>;
  formValues: ChatbotFormType;
  setFormValues: React.Dispatch<React.SetStateAction<ChatbotFormType>>;
}

const Channels: FC<ChannelsProps> = ({
  formValues,
  setFormValues,
  setActiveTab,
}) => {
  const [state, dispatch] = useApp();
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [updateChannel, { loading: loadingUpdate }] = useUpdateChannelMutation({
    onCompleted: () => {
      setNotification({
        message: "Channel updated successfully",
        open: true,
        type: "success",
      });
      getChannels({ variables: { chatbotId: id, organizationId } });
    },
    onError: (error) => {
      setNotification({
        message: error.message,
        open: true,
        type: "error",
      });
    },
  });
  const [channels, setChannels] = useState<ChannelType[]>([]);
  const { id } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const organizationId = queryParams.get("organization_id");
  const [deleteChannel, { loading: loadingDelete }] = useDeleteChannelMutation({
    onCompleted: (data) => {
      if (data.deleteChannel?.success) {
        setNotification({
          message: "Channel deleted successfully",
          open: true,
          type: "success",
        });
        getChannels({ variables: { chatbotId: id, organizationId } });
        setOpen(false);
        setChannelId("");
      } else {
        setNotification({
          message: "Something went wrong",
          open: true,
          type: "error",
        });
      }
    },
    onError: (error) => {
      setNotification({
        message: error.message,
        open: true,
        type: "error",
      });
    },
  });
  const [channelId, setChannelId] = useState<string>();
  const [getChannels, { loading }] = useChannelsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const filteredChannels = data.channels?.filter(Boolean);
      if (filteredChannels) {
        setChannels(filteredChannels as ChannelType[]);
      }
    },
  });

  const handleAction = (action: configModalEnum, channelId: string) => {
    setActiveTab(action);
    const channel = formValues.channels.find((item) => item.id === channelId);
    const channelProfile = formValues.channelProfiles.find(
      (item) => item.channelId === channelId
    );
    const toUpdateFormValues = {
      ...formValues,
      name: channel?.name ?? "",
      domains: channel?.domains ?? "",
      mode: channel?.mode,
    };

    if (channelProfile) {
      toUpdateFormValues.design = channelProfile as IChannelProfileDesign;
    }

    setFormValues(toUpdateFormValues);
    dispatch({
      type: ChatbotAction.SET_CHANNEL,
      payload: {
        ...state.ChatbotReducer,
        channelId,
      },
    });
  };

  const handleActive = (channel: ChannelType, active: boolean) => {
    updateChannel({
      variables: {
        id: channel.id,
        input: {
          active,
          chatbotId: id,
          domains: channel.domains,
          name: channel.name,
          organizationId,
        },
      },
    });
  };

  useEffect(() => {
    if (id) {
      getChannels({ variables: { chatbotId: id, organizationId } });
    }
  }, [id, getChannels]);

  useEffect(() => {
    if (formValues.channels.length) {
      setChannels(formValues.channels as unknown as ChannelType[]);
    }
  }, [formValues.channels]);
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <div className="w-full">
      <Notification {...notification} setOpen={setNotification} />
      <AlertDialog
        open={open}
        description="Are you sure you want to delete this file?"
        title={loadingDelete ? "Deleting..." : "Delete"}
        handleClose={() => setOpen(false)}
        handleConfirm={() => {
          if (id) {
            if (!loadingDelete) {
              deleteChannel({
                variables: {
                  id: channelId,
                  organizationId: organizationId || "",
                },
              });
            }
          } else {
            const updatedChannels = formValues.channels.filter(
              (item) => item.id !== channelId
            );
            const updatedChannelProfiles = formValues.channelProfiles.filter(
              (item) => item.channelId !== channelId
            );
            setFormValues({
              ...formValues,
              channels: updatedChannels,
              channelProfiles: updatedChannelProfiles,
            });
            setOpen(false);
            setChannelId("");
          }
        }}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Mode</TableCell>
              <TableCell>Domain(s)</TableCell>
              <TableCell>Configuration</TableCell>
              {id && <TableCell>Active</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {channels.map((channel) => (
              <TableRow key={channel.id}>
                <TableCell>{channel.name}</TableCell>
                <TableCell>{channel.mode}</TableCell>
                <TableCell className="max-w-[150px]">
                  {channel.domains}
                </TableCell>
                <TableCell>
                  <ActionContainer
                    onClick={() =>
                      handleAction(configModalEnum.DESIGN, channel.id)
                    }
                  >
                    Design
                  </ActionContainer>
                  /
                  <ActionContainer
                    onClick={() =>
                      handleAction(configModalEnum.CONFIG, channel.id)
                    }
                  >
                    Deploy
                  </ActionContainer>
                  /
                  <ActionContainer
                    onClick={() => {
                      setOpen(true);
                      setChannelId(channel.id);
                    }}
                    className="text-red-500"
                  >
                    Delete
                  </ActionContainer>
                </TableCell>
                {id && (
                  <TableCell>
                    {loadingUpdate ? (
                      <CircularProgress size={24} />
                    ) : (
                      <FormControlLabel
                        control={
                          <Switch
                            defaultChecked={channel.active}
                            checked={channel.active}
                            onChange={(e) =>
                              handleActive(channel, e.target.checked)
                            }
                          />
                        }
                        label=""
                      />
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default Channels;
