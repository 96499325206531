import { FC, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { KnowledgeBaseProps } from ".";
import { TextField } from "@mui/material";
import { CreateBtn } from "../../../atoms/CreateBtn";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AlertDialog from "../../../molecules/Dialog";

const FAQ: FC<KnowledgeBaseProps> = ({
  formValues,
  loading,
  setFormValues,
}) => {
  const [open, setOpen] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);

  const handleAddRow = () => {
    setFormValues({
      ...formValues,
      questionPairs: [
        ...formValues.questionPairs,
        { question: "", answer: "", id: "" },
      ],
    });
  };

  const handleQuestionChange = (index: number, value: string) => {
    const updatedData = [...formValues.questionPairs];
    updatedData[index].question = value;
    setFormValues({ ...formValues, questionPairs: updatedData });
  };

  const handleAnswerChange = (index: number, value: string) => {
    const updatedData = [...formValues.questionPairs];
    updatedData[index].answer = value;
    setFormValues({ ...formValues, questionPairs: updatedData });
  };

  const handleRemoveRow = (index: number) => {
    const updatedData = [...formValues.questionPairs];
    updatedData.splice(index, 1);
    setFormValues({ ...formValues, questionPairs: updatedData });
  };

  const handleDelete = (index: number) => {
    if (
      formValues.questionPairs[index].answer ||
      formValues.questionPairs[index].question
    ) {
      setOpen(true);
      setQuestionIndex(index);
    } else {
      handleRemoveRow(index);
    }
  };

  return (
    <div className="space-y-4">
      <AlertDialog
        open={open}
        description="Are you sure you want to delete this question?"
        title="Delete"
        handleClose={() => setOpen(false)}
        handleConfirm={() => handleRemoveRow(questionIndex)}
      />

      {/* <h2 className="text-center text-gray-500">
        <span className="font-black text-gray-900">H</span>UMAN IN THE{" "}
        <span className="font-black text-gray-900">L</span>
        OOP
      </h2> */}
      {formValues.questionPairs.map((row, index) => (
        <div
          key={row.id}
          className="flex space-x-4 items-center hover:bg-gray-100 p-2"
        >
          <div style={{ flex: 2 }}>
            <div className="flex items-center">
              <span className="mr-1"> {index + 1}.</span>
              <TextField
                label="Question"
                variant="outlined"
                multiline
                size="small"
                value={row.question}
                fullWidth
                onChange={(e) => handleQuestionChange(index, e.target.value)}
              />
            </div>
          </div>

          <TextField
            label="Answer"
            variant="outlined"
            multiline
            size="small"
            value={row.answer}
            style={{ flex: 4 }}
            onChange={(e) => handleAnswerChange(index, e.target.value)}
          />

          {!loading && (
            <HighlightOffIcon
              onClick={() => handleDelete(index)}
              className="cursor-pointer"
            />
          )}
        </div>
      ))}
      <div className="flex ">
        <CreateBtn
          variant="outlined"
          startIcon={<AddCircleOutlineIcon />}
          disabled={loading}
          fullWidth
          onClick={handleAddRow}
        >
          Add New Q&A pair
        </CreateBtn>
      </div>
    </div>
  );
};

export default FAQ;
