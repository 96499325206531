import { Parser } from "@json2csv/plainjs";

export const generateCSVFile = (fileData: any[]) => {
  const formatted_messages = [];
  for (let c = 0; c < fileData.length; c++) {
    for (let i = 0; i < fileData[c].conversationChatbotUsers.length; i++) {
      for (
        let j = 0;
        j < fileData[c].conversationChatbotUsers[i].messages.length;
        j++
      ) {
        const author =
          fileData[c].conversationChatbotUsers[i].messages[j]["author"];
        const created = new Date(
          fileData[c].conversationChatbotUsers[i].messages[j]["updated"]
        ).toLocaleString(undefined, {
          dateStyle: "medium",
          timeStyle: "short",
        });
        const msg =
          fileData[c].conversationChatbotUsers[i].messages[j]["author"] !==
          "user"
            ? FormatText(
                fileData[c].conversationChatbotUsers[i].messages[j]["text"]
              )
            : fileData[c].conversationChatbotUsers[i].messages[j]["text"];
        const rating =
          fileData[c].conversationChatbotUsers[i].messages[j]["rating"];
        formatted_messages.push({
          author: author,
          created,
          message: msg,
          rating: rating,
        });
      }
    }
  }

  if (formatted_messages.length !== 0) {
    const opts = {};
    const parser = new Parser(opts);
    const csv = parser.parse(formatted_messages);
    return csv;
  }
  return "";
};

export const FormatText = (input: any) => {
  if (!input) {
    return "";
  }
  let jsonString = input.match(/'text': '(.*?)'}/);
  if (!jsonString) {
    jsonString = input.match(/'text': "(.*?)"}/);
  }
  if (!jsonString) {
    return input.replace(/<br>/g, " ");
  }
  const text = jsonString[1].replace(/<br>/g, " ");
  return text;
};
