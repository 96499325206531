import { FC, useState } from "react";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from "@mui/icons-material/Folder";
import { IDocument } from "../interfaces/Documents";
import AlertDialog from "./Dialog";

interface DocumentListProps {
  documentList: IDocument[];
  onDelete: (file: IDocument) => void;
}

const DocumentList: FC<DocumentListProps> = ({ documentList, onDelete }) => {
  const [open, setOpen] = useState(false);
  const [document, setDocument] = useState<IDocument | null>(null);
  return (
    <Paper>
      <AlertDialog
        open={open}
        description="Are you sure you want to delete this file?"
        title="Delete"
        handleClose={() => setOpen(false)}
        handleConfirm={() => document && onDelete(document)}
      />
      <List dense>
        {documentList.map((document) => (
          <ListItem
            key={document.id}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  setOpen(true);
                  setDocument(document);
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                {document.url ? (
                  <img
                    width={24}
                    height={24}
                    src={
                      (process.env.REACT_APP_GCS_BASE_URL ?? "") +
                      (process.env.REACT_APP_CHATBOT_BUCKET ?? "") +
                      "/" +
                      document.url
                    }
                  />
                ) : (
                  <FolderIcon />
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              title={document.name}
              primary={document.name.slice(0, 60)}
              secondary={""}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default DocumentList;
