import { ChatbotFormType } from ".";
import { ISTEPPER } from "../../interfaces/Chatbot";
import Automation from "./Automation";

import Channel, { configModalEnum } from "./Channel";
import KnowledgeBase from "./KnowledgeBase";
import Personalise from "./Personalise";
import HelperQuestions from "./HelperQuestions";
import Configuration from "./Configuration/Configuration";
import ConfigurationContainer from "./Configuration";

export enum TABENUM {
  CONFIGURATION = 1,
  KNOWLEDGE_BASE = 2,
  HELPER_QUESTIONS = 3,
  AUTOMATION = 4,
  PERSONALISE = 5,
  CHANNEL = 6,
  CONFIGURATION_QUESTION_HELPER = 7,
}

interface IGET_CHATBOT {
  handleChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    checked?: boolean | string
  ) => void;
  formValues: ChatbotFormType;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  open: boolean;
  setFormValues: React.Dispatch<React.SetStateAction<ChatbotFormType>>;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  files: File[];
  loading: boolean;
}

export const getChatbotSteps = ({
  formValues,
  handleChange,
  onSubmit,
  open,
  setFormValues,
  setFiles,
  files,
  loading,
}: IGET_CHATBOT): ISTEPPER[] => [
  {
    label: "Configuration",
    tab: TABENUM.CONFIGURATION,
    component: () => (
      <Configuration
        formValues={formValues}
        setFormValues={setFormValues}
        handleChange={handleChange}
        onSubmit={onSubmit}
        open={open}
      />
    ),
  },
  {
    label: "Configuration & QuestionHelper",
    tab: TABENUM.CONFIGURATION_QUESTION_HELPER,
    component: () => (
      <ConfigurationContainer
        formValues={formValues}
        setFormValues={setFormValues}
        handleChange={handleChange}
        onSubmit={onSubmit}
        open={open}
        loading={loading}
      />
    ),
  },
  {
    label: "Knowledge Base",
    tab: TABENUM.KNOWLEDGE_BASE,
    component: () => (
      <KnowledgeBase
        setFormValues={setFormValues}
        setFiles={setFiles}
        formValues={formValues}
        files={files}
        open={open}
        loading={loading}
      />
    ),
  },
  {
    label: "Suggested Questions",
    isOptional: true,
    tab: TABENUM.HELPER_QUESTIONS,
    component: () => (
      <HelperQuestions
        formValues={formValues}
        setFormValues={setFormValues}
        handleChange={handleChange}
        onSubmit={onSubmit}
        open={open}
        loading={loading}
      />
    ),
  },
  {
    label: "Automation",
    isOptional: true,
    tab: TABENUM.AUTOMATION,
    component: () => <Automation />,
  },
  {
    label: "Personalise",
    isOptional: true,
    tab: TABENUM.PERSONALISE,
    component: () => <Personalise />,
  },
  {
    label: "Channel",
    tab: TABENUM.CHANNEL,
    component: () => (
      <Channel
        formValues={formValues}
        handleChange={handleChange}
        onSubmit={onSubmit}
        open={open}
        setFormValues={setFormValues}
        activeTab={configModalEnum.CHATBOX}
      />
    ),
  },
];
