import { useState } from "react";
import FormItemContainer from "../atoms/FormItemContainer";
import { CircularProgress, TextField } from "@mui/material";
import { useSignupUserMutation } from "../generated/graphql";
import { Notification } from "../molecules/Notification";
import { INotification } from "../interfaces/General";
import { CreateBtn } from "../atoms/CreateBtn";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    organization: "",
    designation: "",
    usecaseFunction: "",
  });
  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "error",
  });

  const [signupUser, { loading }] = useSignupUserMutation({
    variables: {
      email: formValues.email,
      name: formValues.name,
      organization: formValues.organization,
      designation: formValues.designation,
      usecaseFunction: formValues.usecaseFunction,
    },
    onCompleted(data) {
      if (data.signup?.success) {
        setNotification({
          message:
            "You have successfully registered! Please check your email for further steps.",
          open: true,
          type: "success",
        });
        setTimeout(() => {
          navigate("/login");
        }, 1500);
      } else {
        setNotification({
          message: "Error happened while register, contact to admin",
          open: true,
          type: "error",
        });
      }
    },
    onError(error) {
      setNotification({
        message:
          error.message === "Please use your business email address"
            ? error.message
            : "Error happened while register, contact to admin",
        open: true,
        type: "error",
      });
    },
  });

  const handleChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClick = async () => {
    await signupUser();
  };

  return (
    <div>
      <Notification {...notification} setOpen={setNotification} />
      <FormItemContainer>
        <TextField
          label="Organization"
          value={formValues.organization}
          name="organization"
          onChange={handleChange}
          size="small"
          required
          fullWidth
        />
      </FormItemContainer>
      <FormItemContainer>
        <TextField
          label="Work Email ID"
          value={formValues.email}
          name="email"
          onChange={handleChange}
          size="small"
          required
          fullWidth
        />
      </FormItemContainer>
      <FormItemContainer>
        <TextField
          label="Name"
          value={formValues.name}
          name="name"
          required
          onChange={handleChange}
          size="small"
          fullWidth
        />
      </FormItemContainer>

      <FormItemContainer>
        <TextField
          label="Designation"
          placeholder="Optional"
          value={formValues.designation}
          name="designation"
          onChange={handleChange}
          size="small"
          fullWidth
        />
      </FormItemContainer>

      <FormItemContainer>
        <TextField
          label="Use Case & Function"
          placeholder="Optional"
          value={formValues.usecaseFunction}
          name="usecaseFunction"
          onChange={handleChange}
          size="small"
          fullWidth
          multiline
          rows={4}
        />
      </FormItemContainer>

      <FormItemContainer>
        <CreateBtn
          variant="contained"
          color="primary"
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Sign up"}
        </CreateBtn>
      </FormItemContainer>
    </div>
  );
};

export default Signup;
