import { FC } from "react";
import { Link } from "react-router-dom";

export enum UpgradeActionEnum {
  CREATE_BOT = "create more bots",
  UPLOAD_MORE_FILE = "upload more files",
  CHATBOT_BRANDING = "configure chabot design",
}

interface IUpgrade {
  action: UpgradeActionEnum;
}

export const UpgradeLokichat: FC<IUpgrade> = ({ action }) => {
  return (
    <div className="text-red-500 bg-white px-4 py-1 rounded shadow">
      Your plan has reached to its limits. To be able to {action}{" "}
      <Link
        to="/upgrade"
        className="text-blue-500 underline hover:text-blue-700"
      >
        upgrade
      </Link>{" "}
      to higher plan or contact sales.
    </div>
  );
};
