import { useParams } from "react-router-dom";
import ChatbotCreation from "../../organisms/ChatbotCreation";
import { TABENUM } from "../../organisms/ChatbotCreation/steps";

const Personalisation = () => {
  const { id } = useParams();
  return <ChatbotCreation id={id ?? ""} tab={TABENUM.PERSONALISE} />;
};

export default Personalisation;
