import { FC, useEffect, useState } from "react";
import { FileDownload as FD } from "@mui/icons-material";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import { UsageTabsEnum } from "../../pages/ChatbotPage/Usage";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Pagination,
  Typography,
} from "@mui/material";
import {
  useChatbotUserConversationLazyQuery,
  useGenerateReportConversationLazyQuery,
} from "../../generated/graphql";
import { useParams } from "react-router-dom";
import FileDownload from "../../molecules/FileDownload";
import ActionButton from "../../molecules/ActionButton";
import { generateCSVFile } from "./utils";
import { useApp } from "../../states/AppContext";
import AlertDialog from "../../molecules/Dialog";
import { Notification } from "../../molecules/Notification";
import { INotification } from "../../interfaces/General";
const FormatText = (input: any) => {
  try {
    let jsonString = input.match(/'text': '(.*?)'}/);
    if (!jsonString) {
      jsonString = input.match(/'text': "(.*?)"}/);
    }
    const paragraphs = jsonString[1]?.split(/<br>/g);
    return (
      <>
        {paragraphs.map((paragraph: string, index: number) => (
          <p key={index}>{paragraph}</p>
        ))}
      </>
    );
  } catch (e) {
    return <p>{input}</p>;
  }
};
const UserMessageChat = (text: string, timestamp: any, rating: number) => (
  <>
    <div className="flex justify-end mb-1">
      <div className="bg-gray-200 rounded-lg p-4">
        <p className="text-gray-800">{text}</p>
      </div>
    </div>
    <div className="flex justify-end mb-4 px-2 text-xs text-gray-500">
      {new Date(timestamp).toLocaleString(undefined, {
        dateStyle: "medium",
        timeStyle: "short",
      })}
    </div>
  </>
);

const BotMessageChat = (text: string, timestamp: any, rating: number) => (
  <>
    <div className="flex mb-1">
      <div className="bg-blue-500 rounded-lg p-4">
        <p className="text-white">{FormatText(text)}</p>
      </div>
    </div>
    <div className="flex mb-4 px-2 text-xs text-gray-500">
      {new Date(timestamp).toLocaleString(undefined, {
        dateStyle: "medium",
        timeStyle: "short",
      })}

      {rating === 0 || rating === 1 ? (
        <>
          <br />
          Rating: {rating}
        </>
      ) : (
        ""
      )}
    </div>
  </>
);
const Conversations: FC<{ tab: UsageTabsEnum }> = ({ tab }) => {
  const { id } = useParams();
  const [state] = useApp();
  const [open, setOpen] = useState(false);
  const itemsPerPage = 100;

  // add state for the current page
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [generateConversation] = useGenerateReportConversationLazyQuery({
    fetchPolicy: "no-cache",
    variables: { chatbotId: id },
    onCompleted(data) {
      if (data.generateReportConversation) {
        setNotification({
          type: "success",
          message:
            "File is being generated successfully, We will notify you via email once it's ready",
          open: true,
        });
      }
    },
  });
  const [chatbotUserConversations, setChatbotUserConversations] = useState<{
    [key: string]: Array<any>;
  }>({});
  const chatbot = state.ChatbotReducer.chatbots.find(
    (item) => item.id === state.ChatbotReducer.chatbotSelected
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const downloadLink =
    (process.env.REACT_APP_GCS_BASE_URL ?? "") +
    (process.env.REACT_APP_CHATBOT_REPORT_BUCKET ?? "") +
    "conversations/conversations-" +
    chatbot?.id +
    ".zip?time-stamp=" +
    new Date().toISOString();

  const [expanded, setExpanded] = useState<string | boolean>();
  const [subExpanded, setSubExpanded] = useState<string | boolean>();
  const [getChatbotUserConversation, { loading, data }] =
    useChatbotUserConversationLazyQuery({
      variables: { chatbotId: id, page, pageSize: itemsPerPage },
      onCompleted(data) {
        setTotal(data.chatbotUserConversation?.totalCount ?? 0);
        const groupedByEmail = data.chatbotUserConversation?.data?.reduce(
          (result: { [key: string]: Array<any> }, item) => {
            const email = item?.email?.toLowerCase() ?? "";
            const { ...rest } = item;
            if (result[email]) {
              result[email].push(rest);
            } else {
              result[email] = [rest];
            }
            return result;
          },
          {}
        );
        if (groupedByEmail) {
          setChatbotUserConversations(groupedByEmail);
        }
      },
    });

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleChangeSub =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setSubExpanded(newExpanded ? panel : false);
    };

  const downloadAll = async () => {
    setOpen(true);
  };

  const downloadAllConversations = async () => {
    try {
      const zip = new JSZip();
      Object.values(chatbotUserConversations).map((conversations) => {
        console.log(conversations, "the conversations");
        const csv = generateCSVFile(conversations);
        const fileName = `${conversations[0]?.name}(${conversations[0]?.email}).csv`;
        console.log(fileName, "the file name");

        zip.file(fileName, csv);
      });

      const blob = await zip.generateAsync({ type: "blob" });
      saveAs(blob, `conversations-${chatbot?.chatboxTitle}.zip`);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (tab === UsageTabsEnum.CONVERSATIONS) {
      getChatbotUserConversation();
    }
  }, [tab]);

  if (loading) {
    return <div>Generating report...</div>;
  }

  return (
    <div>
      <Notification {...notification} setOpen={setNotification} />
      <AlertDialog
        open={open}
        description="Do you want to generate new file?"
        title="Generate File"
        handleClose={(decline) => {
          setOpen(false);
          if (decline) window.open(downloadLink, "_blank");
        }}
        handleConfirm={() => generateConversation()}
        yesLabel="Yes"
        noLabel="Download Existing File"
      />
      <div className="mb-4 flex flex-row-reverse">
        <ActionButton onClick={downloadAll}>
          Download All <FD />
        </ActionButton>
      </div>
      {Object.values(chatbotUserConversations).map((conversations) => {
        return (
          <Accordion
            expanded={expanded === conversations[0].id}
            onChange={handleChange(conversations[0].id)}
            key={conversations[0].id}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography style={{ overflowWrap: "anywhere" }}>
                {conversations[0].name}({conversations[0].email})
              </Typography>
            </AccordionSummary>
            <FileDownload fileData={conversations} label="Download" />
            <AccordionDetails>
              {conversations.map((conversation) => {
                return (
                  <div className="w-full">
                    {conversation.conversationChatbotUsers.map((item: any) => {
                      return (
                        <Accordion
                          expanded={subExpanded === item.id}
                          onChange={handleChangeSub(item.id)}
                          key={item.id}
                          style={{ marginLeft: "20px" }}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography style={{ overflowWrap: "anywhere" }}>
                              {new Date(item.updated).toLocaleString(
                                undefined,
                                {
                                  dateStyle: "medium",
                                  timeStyle: "short",
                                }
                              )}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {item.messages.map((message: any) => {
                              if (message?.author === "user") {
                                return UserMessageChat(
                                  message?.text ?? "",
                                  message?.updated ?? "",
                                  message?.rating ?? ""
                                );
                              }
                              return BotMessageChat(
                                message?.text ?? "",
                                message?.updated ?? "",
                                message?.rating ?? ""
                              );
                            })}
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Pagination count={total} page={page} onChange={handlePageChange} />
    </div>
  );
};

export default Conversations;
