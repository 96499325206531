import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import { IChatbot } from "../../interfaces/Chatbot";
import { useChatbotLazyQuery } from "../../generated/graphql";
import { useLocation } from "react-router-dom";

const ChatbotList = () => {
  const [getChatbots, { loading }] = useChatbotLazyQuery({
    fetchPolicy: "no-cache",
  });
  const [filter, setFilter] = useState({
    dateFrom: new Date(),
    dateTo: new Date(),
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const organizationId = queryParams.get("organization_id");
  const [chatbots, setChatbots] = useState<Array<IChatbot>>([]);

  const fetchChatbots = async (isReset: boolean) => {
    let variables = {};
    if (!isReset) {
      variables = {
        dateFrom: filter.dateFrom.toISOString().slice(0, 10),
        dateTo: filter.dateTo.toISOString().slice(0, 10),
      };
    }
    if (organizationId) {
      variables = {
        ...variables,
        organizationId,
      };
    }
    const chatbots = await getChatbots({
      variables,
    });
    if (chatbots.data?.chatbots?.length && chatbots.data?.chatbots !== null) {
      setChatbots(chatbots.data.chatbots as unknown as IChatbot[]);
    }
  };

  useEffect(() => {
    fetchChatbots(true);
  }, []);

  return (
    <div>
      <div className="mb-4">
        <Box>
          <DatePicker
            selected={filter.dateFrom}
            onChange={(date: Date) => setFilter({ ...filter, dateFrom: date })}
          />
          <DatePicker
            selected={filter.dateTo}
            onChange={(date: Date) => setFilter({ ...filter, dateTo: date })}
          />
        </Box>

        <Button
          onClick={() => {
            fetchChatbots(false);
          }}
        >
          Filter
        </Button>

        <Button
          onClick={() => {
            fetchChatbots(true);
          }}
        >
          Reset
        </Button>
      </div>
      <h2 className="mb-4">
        Total Chatbots: {loading ? "Calculating..." : chatbots.length}
      </h2>
      {loading ? (
        <>
          <CircularProgress /> <p>Loading...</p>
        </>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Bot</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Created By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chatbots.map((chatbot) => (
                <TableRow key={chatbot.id}>
                  <TableCell>{chatbot.chatboxTitle}</TableCell>
                  <TableCell>
                    {new Intl.DateTimeFormat("default", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      timeZoneName: "short",
                    }).format(new Date(chatbot.created))}
                  </TableCell>
                  <TableCell>{chatbot?.user?.email ?? "OWNER"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default ChatbotList;
