import { FC, useState } from "react";
import {
  Avatar,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
// import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { IUser } from "../interfaces/Users";
import AlertDialog from "./Dialog";
import { RoleType, useUpdateCustomerMutation } from "../generated/graphql";
import { useApp } from "../states/AppContext";
import { Notification } from "./Notification";
import { INotification } from "../interfaces/General";
// import AssignRole from "../organisms/Role/AssignRole";

enum ACTION_TYPE {
  DELETE,
  ASSIGN_ROLE,
}
interface UserListProps {
  userList: IUser[];
  roles: RoleType[];
  onDelete: (user: IUser) => void;
  setUserList: React.Dispatch<React.SetStateAction<IUser[]>>;
}

const UserList: FC<UserListProps> = ({ userList, setUserList, onDelete }) => {
  const [state] = useApp();
  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "error",
  });
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<{ user: IUser | null; type: ACTION_TYPE }>();
  const [updateCustomer, { loading }] = useUpdateCustomerMutation({
    onCompleted(data) {
      if (data.updateCustomer?.customer?.id) {
        setUserList(
          userList.map((item) => ({
            ...item,
            isOwner:
              item.id === data.updateCustomer?.customer?.id
                ? data.updateCustomer.customer.isOwner
                : item.isOwner,
          }))
        );
        setNotification({
          type: "success",
          open: true,
          message: "User's role has been successfully changed",
        });
      }
    },
    onError(err) {
      setNotification({
        type: "error",
        open: true,
        message: err.message,
      });
    },
  });
  const handleOwner = (user: IUser, checked: boolean) => {
    updateCustomer({
      variables: {
        id: user.id,
        isOwner: checked,
      },
    });
  };
  return (
    <Paper className="p-5">
      <Notification {...notification} setOpen={setNotification} />
      <AlertDialog
        open={open}
        description={
          user?.type === ACTION_TYPE.DELETE ? (
            "Are you sure you want to delete this user?"
          ) : (
            <FormControlLabel
              control={<Switch defaultChecked={user?.user?.isOwner} />}
              label="Is Admin"
            />
            // <AssignRole
            //   roles={roles}
            //   userId={user?.user?.id}
            //   roleId={user?.user?.role?.id}
            //   getRole={(role, id) => {
            //     setUserList(
            //       userList.map((item) => ({
            //         ...item,
            //         role: {
            //           id: item.id === user?.user?.id ? id : item.role?.id ?? "",
            //           role:
            //             item.id === user?.user?.id
            //               ? role
            //               : item.role?.role ?? "",
            //         },
            //       }))
            //     );
            //     setOpen(false);
            //   }}
            // />
          )
        }
        yesLabel={user?.type === ACTION_TYPE.DELETE ? "Yes" : "Close"}
        isRemoveNo={user?.type === ACTION_TYPE.ASSIGN_ROLE}
        title={user?.type === ACTION_TYPE.DELETE ? "Delete" : "Assign Role"}
        handleClose={() => setOpen(false)}
        handleConfirm={() => {
          if (user?.type === ACTION_TYPE.DELETE) {
            user?.user && onDelete(user.user);
          }
        }}
      />
      {!userList.length && (
        <Typography variant="h5">There is no users yet</Typography>
      )}
      <List dense>
        {userList.map((user) => (
          <ListItem
            key={user.id}
            secondaryAction={
              <div className="flex ">
                {state.UserReducer.id !== user.id && (
                  <>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>User</Typography>
                      <Switch
                        disabled={loading}
                        defaultChecked={user.isOwner}
                        onChange={(e) => handleOwner(user, e.target.checked)}
                      />
                      <Typography>Admin</Typography>
                    </Stack>

                    <IconButton
                      edge="end"
                      title="delete user"
                      aria-label="delete"
                      onClick={() => {
                        setOpen(true);
                        setUser({ user, type: ACTION_TYPE.DELETE });
                      }}
                    >
                      <PersonRemoveIcon />
                    </IconButton>
                  </>
                )}

                {/* <IconButton
                    edge="end"
                    title="assign role"
                    aria-label="assign role"
                    onClick={() => {
                      setOpen(true);
                      setUser({ user, type: ACTION_TYPE.ASSIGN_ROLE });
                    }}
                  >
                    <ManageAccountsIcon />
                  </IconButton> */}
              </div>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <PermIdentityIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  {user.name ? user.name : "No name"}{" "}
                  {!user.isActive && (
                    <>
                      <CloseIcon color="error" />
                    </>
                  )}
                </>
              }
              secondary={user.email}
            />
            {/* <ListItemText primary={user.isOwner ? "Admin" : "User"} /> */}
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default UserList;
