import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ChatbotType,
  useChatbotLazyQuery,
  useChatbotListLazyQuery,
  useGetOneChatbotLazyQuery,
} from "../generated/graphql";
import { ChatbotAction } from "../interfaces/Chatbot";
import { useApp } from "../states/AppContext";
import { useUser } from "./auth";

export const useChatbot = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useApp();
  const { logout } = useUser();
  const { id } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const organizationId = queryParams.get("organization_id");

  // const [getChatbotList, { loading }] = useChatbotLazyQuery({});

  const [getOneChatbotServer, { loading }] = useGetOneChatbotLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [getOnlyChatbotList, { loading: loadingOnlyChatbots }] =
    useChatbotListLazyQuery({
      fetchPolicy: "no-cache",
    });

  // const getChatbots = async (chatbotId?: string, organizationId?: string) => {
  //   const chatbots = await getChatbotList({
  //     variables: { organizationId },
  //     onError() {
  //       logout();
  //       navigate("/login");
  //     },
  //   });
  //   const data = chatbots.data;
  //   if (data?.chatbots) {
  //     dispatch({
  //       type: ChatbotAction.SET_CHATBOT_DATA,
  //       payload: {
  //         ...state.ChatbotReducer,
  //         chatbotSelected: chatbotId || id || data.chatbots[0]?.id,
  //         chatbots: data.chatbots as Array<ChatbotType>,
  //       },
  //     });
  //   }
  //   return chatbots;
  // };

  const getOnlyChatbots = async (
    organizationId?: string,
    chatbotId?: string
  ) => {
    const chatbots = await getOnlyChatbotList({
      variables: { organizationId },
      onError() {
        logout();
        navigate("/login");
      },
    });
    const data = chatbots.data;
    if (data?.chatbots) {
      dispatch({
        type: ChatbotAction.SET_ONLY_CHATBOT_DATA,
        payload: {
          ...state.ChatbotReducer,
          onlyChatbots: data.chatbots as Array<ChatbotType>,
          chatbotSelected: chatbotId || id || data.chatbots[0]?.id,
        },
      });
    }
    return chatbots;
  };

  const getOneChatbot = async (chatbotId: string, organizationId?: string) => {
    const chatbots = await getOneChatbotServer({
      variables: { organizationId, id: chatbotId },
      onError() {
        logout();
        navigate("/login");
      },
    });
    const data = chatbots.data;
    if (data?.chatbot?.id) {
      dispatch({
        type: ChatbotAction.SET_CHATBOT_DATA,
        payload: {
          ...state.ChatbotReducer,
          chatbots: [data.chatbot] as Array<ChatbotType>,
          chatbotSelected: chatbotId || id || data.chatbot?.id,
        },
      });
    }
    return chatbots;
  };

  const deleteChatbot = async (id: string) => {
    const chatbots = state.ChatbotReducer.onlyChatbots.filter(
      (item) => item.id !== id
    );
    dispatch({
      type: ChatbotAction.SET_ONLY_CHATBOT_DATA,
      payload: {
        ...state.ChatbotReducer,
        chatbotSelected: chatbots[0]?.id || "",
        onlyChatbots: chatbots ?? [],
      },
    });
    await getOneChatbot(chatbots[0]?.id);
    if (chatbots.length) {
      navigate(
        `/dashboard/chatbot/${chatbots[0].id}/overview?organization_id=${organizationId}`
      );
    } else {
      navigate("/dashboard/chatbot");
    }
  };

  return {
    // getChatbots,
    getOneChatbot,
    getOnlyChatbots,
    deleteChatbot,
    loadingOnlyChatbots,
    loading,
  };
};
