import {
  useContext,
  FunctionComponent,
  useReducer,
  createContext,
} from "react";

import { combineReducers } from "../utils/utils";
import { UserReducer, userInitialState } from "./reducers/UserReducer";
import { IUser, UserAction } from "../interfaces/Users";

import { ChatbotAction, IChatbotState } from "../interfaces/Chatbot";
import { chatbotInitialState, ChatbotReducer } from "./reducers/ChatbotReducer";
const ContextState = createContext([]);

interface IAppStateContext {
  UserReducer: IUser;
  ChatbotReducer: IChatbotState;
}

interface IDispatchParams {
  type: UserAction | ChatbotAction;
  payload: IUser | IChatbotState;
}

const useApp = () => {
  const context = useContext(ContextState);
  if (!context) {
    throw new Error(`useApp must be used within a AppProvider`);
  }
  return context as unknown as [
    IAppStateContext,
    ({ ...args }: IDispatchParams) => void
  ];
};

const AppProvider: FunctionComponent<any> = (props) => {
  const [state, dispatch] = useReducer(
    combineReducers({
      UserReducer,
      ChatbotReducer,
    }),
    {
      UserReducer: { ...userInitialState },
      ChatbotReducer: chatbotInitialState,
    }
  );
  return (
    <ContextState.Provider
      value={
        [state, dispatch] as unknown as [
          IAppStateContext,
          ({ ...args }: IDispatchParams) => void
        ]
      }
      {...props}
    />
  );
};

export { useApp, AppProvider };
