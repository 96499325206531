import { IChatbotTabs } from "../../../interfaces/Chatbot";
import Configuration from "./Configuration";
import HelperQuestions from "../HelperQuestions";
import ChatbotTabs from "../../UI/ChatbotTabs";
import { ChatbotFormType } from "..";
import { FC } from "react";

export interface ConfigurationContainer {
  setFormValues: React.Dispatch<React.SetStateAction<ChatbotFormType>>;
  formValues: ChatbotFormType;
  open: boolean;
  loading: boolean;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  handleChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
}

export enum ConfigurationContainerEnum {
  CONFIG = 0,
  HELPER_QUESTIONS = 1,
}

const getTabList = (props: ConfigurationContainer): IChatbotTabs => [
  {
    label: "Config",
    component: () => <Configuration {...props} />,
  },
  {
    label: "Suggested Questions",
    component: () => <HelperQuestions {...props} />,
  },
];

const ConfigurationContainer: FC<ConfigurationContainer> = (props) => {
  const tabs = getTabList(props);

  return (
    <div className="flex item-center w-full flex-col">
      <ChatbotTabs tabs={tabs} />
    </div>
  );
};

export default ConfigurationContainer;
