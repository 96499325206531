import FormItemContainer from "../../../atoms/FormItemContainer";
import { FC } from "react";
import InputTags from "../../../molecules/TagInput";
import { useApp } from "../../../states/AppContext";
import { useParams } from "react-router-dom";
import { cleanUrl, urlRegex } from "../../../utils/utils";
import { ChatbotModalProps } from ".";
import HowDeploy from "../../HowDeploy";
import { TextField } from "@mui/material";

const ChatbotWindow: FC<ChatbotModalProps> = ({
  open,
  formValues,
  setFormValues,
  handleChange,
  onSubmit,
}) => {
  const { id } = useParams();
  const [state] = useApp();
  const chatbot = state.ChatbotReducer.chatbots.find((item) => item.id === id);
  const channel = chatbot?.chatbotChannels.find(
    (item) => item.id === state.ChatbotReducer.channelId
  );
  const verifyUrl = (value: string) => {
    if (urlRegex.test(value)) {
      return true;
    }
    return false;
  };

  const getInitialValues = () => {
    if (id) {
      return state.ChatbotReducer.channelId
        ? channel?.domains?.split(",").filter(Boolean) ?? []
        : [];
    }
    return formValues.domains.split(",").filter(Boolean);
  };

  return (
    <>
      <FormItemContainer>
        <TextField
          label="Channel Name"
          name="name"
          value={formValues.name}
          onChange={handleChange}
          size="small"
          fullWidth
          required
        />
      </FormItemContainer>
      <FormItemContainer>
        <InputTags
          label="Domains (press enter for each url)"
          helperText="Enter valid url!"
          initialTags={getInitialValues()}
          onSubmitTag={verifyUrl}
          getTags={(domains) => {
            setFormValues((prefState) => ({
              ...prefState,
              domains: domains.map((domain) => cleanUrl(domain)).join(","),
            }));
          }}
        />
      </FormItemContainer>
      <FormItemContainer>
        {id && state.ChatbotReducer.channelId ? (
          <HowDeploy />
        ) : (
          <div className="text-sm text-gray-500">
            You'll be able to see script tag here after saving the channel.
          </div>
        )}
      </FormItemContainer>
    </>
  );
};

export default ChatbotWindow;
