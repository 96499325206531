import { FC, useEffect } from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";

import { AppProvider } from "../states/AppContext";
import { checkToken, getToken, useUser } from "../utils/auth";

import { useChatbot } from "../utils/chatbot";

const httpLink = createUploadLink({ uri: process.env.REACT_APP_API_URL });

const authLink = setContext((_, { headers = {} }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const WrappedPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const organizationId = queryParams.get("organization_id");
  const { id } = useParams();
  const { getOnlyChatbots, getOneChatbot } = useChatbot();
  const { user, loginUser } = useUser();
  const redirect = (path: string) => {
    navigate(path);
  };

  const isAuthenticated = checkToken();

  const handleRoute = async () => {
    if (isAuthenticated) {
      let orgId =
        organizationId !== null && organizationId !== "null"
          ? organizationId
          : "";
      const chatbots = await getOnlyChatbots(orgId || "");

      let chatbotId = id ?? "";
      if (!id && chatbots?.data?.chatbots) {
        if (!orgId) {
          orgId = chatbots.data?.chatbots[0]?.organizationId;
        }
        chatbotId = chatbots.data?.chatbots[0]?.id;
      }
      if (!user.id) {
        // await loginUser();
      }
      if (chatbotId) {
        await getOneChatbot(chatbotId, orgId || "");
      }
      if (
        !location.pathname.includes("dashboard") &&
        !location.pathname.includes("master-admin") &&
        chatbots?.data?.chatbots
      ) {
        redirect(
          `/dashboard/chatbot/${
            id ?? chatbots.data?.chatbots[0]?.id
          }/overview?organization_id=${orgId}`
        );
      }
    } else {
      if (
        !location.pathname.includes("login") ||
        location.pathname.includes("select-organization")
      ) {
        redirect("/login");
      }
    }
  };
  useEffect(() => {
    handleRoute();
  }, []);
  return <Outlet />;
};

export const App: FC = () => {
  return (
    <ApolloProvider client={client}>
      <AppProvider>
        <WrappedPage />
      </AppProvider>
    </ApolloProvider>
  );
};
