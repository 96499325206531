import { useParams } from "react-router-dom";
import ChatbotCreation from "../../organisms/ChatbotCreation";
import { TABENUM } from "../../organisms/ChatbotCreation/steps";

const ConfigurationPage = () => {
  const { id } = useParams();
  return (
    <ChatbotCreation
      id={id ?? ""}
      tab={TABENUM.CONFIGURATION_QUESTION_HELPER}
    />
  );
};

export default ConfigurationPage;
