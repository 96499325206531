import React from "react";
import { FileDownload as FD } from "@mui/icons-material";
import { generateCSVFile } from "../organisms/Usage/utils";

interface Props {
  fileData: Array<{ [key: string]: Array<any> }>;
  label: string;
}

const FileDownload: React.FC<Props> = ({ fileData, label }) => {
  const handleDownload = () => {
    try {
      const csv = generateCSVFile(fileData);
      const fileName = `${fileData[0]?.name}(${fileData[0]?.email}).csv`;
      const fileType = "text/csv";

      const blob = new Blob([csv], { type: fileType });
      const a = document.createElement("a");
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvt);
      a.remove();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="flex justify-center px-6 text-sky-400">
      <button onClick={handleDownload}>
        {label}
        <FD />
      </button>
    </div>
  );
};

export default FileDownload;
