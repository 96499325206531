import { Box, Button, ButtonProps } from "@mui/material";
import { FC } from "react";

const ActionButton: FC<ButtonProps> = ({
  variant = "contained",
  color = "primary",
  disabled = false,
  children,
  onClick,
}) => (
  <Box mt={2}>
    <Button
      variant={variant}
      color={color}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  </Box>
);

export default ActionButton;
