import { useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";

import { UserOrganizationSelect } from "../../atoms/UserOrganizationSelect";

export default function SelectOrganization() {
  const [value, setValue] = useState("");

  return (
    <div className="flex flex-col h-full">
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <>
            <UserOrganizationSelect
              value={value}
              setValue={setValue}
              componentyType="table"
            />
          </>
        </FormControl>
      </Box>
    </div>
  );
}
