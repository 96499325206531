import { CircularProgress, TextField } from "@mui/material";
import { CreateBtn } from "../atoms/CreateBtn";
import { useState } from "react";
import { useLoginMutation } from "../generated/graphql";
import { useUser } from "../utils/auth";
import { Link, useNavigate } from "react-router-dom";
import FormItemContainer from "../atoms/FormItemContainer";
import { useChatbot } from "../utils/chatbot";

const Login = () => {
  const navigate = useNavigate();
  const { loginUser } = useUser();
  const { getOnlyChatbots } = useChatbot();
  const [loading, setLoading] = useState(false);
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const [login, { error }] = useLoginMutation({
    variables: {
      email: loginForm.email,
      password: loginForm.password,
    },
    fetchPolicy: "no-cache",
    async onCompleted(data) {
      if (data.login && data.login.token) {
        const me = await loginUser("", data.login.token);
        setLoading(false);
        if (me.data?.me?.isMaster) {
          navigate("/login/master-admin-select-organization");
        } else if (me.data?.me?.isOwner) {
          const chatbots = await getOnlyChatbots();

          if (!chatbots.data?.chatbots?.length) {
            navigate(
              "/dashboard/admin/settings?organization_id=" +
                me.data?.me?.organization?.id
            );
          } else {
            navigate(
              `/dashboard/chatbot/${chatbots.data?.chatbots[0]?.id}/overview?organization_id=${chatbots.data?.chatbots[0]?.organizationId}`
            );
          }
        } else {
          const chatbots = await getOnlyChatbots();
          if (!chatbots.data?.chatbots?.length) {
            navigate(
              "/dashboard/chatbot?organization_id=" +
                me.data?.me?.organization?.id
            );
          } else {
            navigate(
              `/dashboard/chatbot/${chatbots.data?.chatbots[0]?.id}/overview?organization_id=${chatbots.data?.chatbots[0]?.organizationId}`
            );
          }
        }
      } else {
        setLoading(false);
      }
    },
    onError() {
      setLoading(false);
    },
  });

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setLoginForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    login();
  };
  return (
    <>
      {error && (
        <FormItemContainer>
          <p className="text-red-500">{error.message}</p>
        </FormItemContainer>
      )}
      <form noValidate autoComplete="off" onSubmit={handleLogin}>
        <FormItemContainer>
          <TextField
            id="email"
            name="email"
            label="Email"
            onChange={handleChange}
            size="medium"
            fullWidth
          />
        </FormItemContainer>
        <FormItemContainer>
          <TextField
            id="password"
            name="password"
            label="Password"
            type="password"
            onChange={handleChange}
            size="medium"
            fullWidth
          />
        </FormItemContainer>
        <FormItemContainer>
          <CreateBtn
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            style={{
              marginTop: "25px",
              paddingLeft: "25px",
              paddingRight: "25px",
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Login"}
          </CreateBtn>
        </FormItemContainer>
      </form>
      <div className="text-center mt-4">
        <div>
          <Link to="/login/forgot-password" className="text-blue-500 underline">
            Forgot Password?
          </Link>
        </div>
        <div>
          <Link to="/login/signup" className="text-blue-500 underline">
            Register new account
          </Link>
        </div>
      </div>
    </>
  );
};

export default Login;
