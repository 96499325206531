import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { FC } from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

interface LoadingFadeProps {
  count: number;
}
const LoadingFade: FC<LoadingFadeProps> = ({ count }) => {
  return (
    <>
      {new Array(count).fill(0).map((_, index) => (
        <Stack spacing={1} key={index}>
          <div className="flex flex-row items-center mb-5 w-full">
            <ArrowForwardIosSharpIcon className="mr-2" />
            <Skeleton
              variant="rounded"
              animation="wave"
              width="100%"
              height={60}
            />
          </div>
        </Stack>
      ))}
    </>
  );
};

export default LoadingFade;
