import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { FC } from "react";

interface ArcProgressBarProps {
  value: number
  max: number
}

const ArcProgressBar: FC<ArcProgressBarProps> = ({ value, max }) => {
  const progress = (value / max) * 50;
  const limit = max > 9999999 ? "∞" : max
  return (
    <Box className="flex flex-col">
      <Box className="relative inline-flex w-[60px] h-[30px] truncate">
        <Box className="absolute inline-flex top-0 left-0 rotate-[-90deg]">
          <CircularProgress
            variant="determinate"
            size="60px"
            thickness={7}
            sx={{
              borderRadius: "100%",
              boxShadow: "inset 0 0 0px 11px rgba(128, 128, 128, 0.5)",
            }}
            value={progress} />
        </Box>
      </Box>
      <div className="text-center text-xs">
        {value} / {limit}
      </div>
    </Box>
  )
}

export default ArcProgressBar