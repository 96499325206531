import { FC } from "react";
import { useApp } from "../../states/AppContext";
import { Outlet } from "react-router-dom";

const AdminPage: FC = () => {
  const [state] = useApp();
  return (
    <>
      {state.UserReducer.isOwner ? (
        <div className="p-5">
          <Outlet />
        </div>
      ) : (
        <p>You don't have permission to access this page</p>
      )}
    </>
  );
};

export default AdminPage;
