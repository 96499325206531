import { createBrowserRouter } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import SettingPage from "./pages/SettingPage";
import MemberPage from "./pages/MemberPage";
import ChatbotPage from "./pages/ChatbotPage";
import Usage from "./pages/ChatbotPage/Usage";
import { LoginPage } from "./pages/Login";
import { App } from "./pages";
import ActivatePage from "./pages/ActivatePage";
import AdminPage from "./pages/AdminPage";
import ChatbotFormPage from "./pages/ChatbotForm";
import OverviewPage from "./pages/ChatbotPage/Overview";
import Channels from "./pages/ChatbotPage/Channels";
import ForgotPasswordPage from "./pages/ForgotPassword/ForgotPassword";
import ResetPasswordPage from "./pages/ForgotPassword/ResetPassword";
import PreLoginPage from "./pages/PreLoging";
import KnowledgeBase from "./pages/ChatbotPage/KnowledgeBase";
import Personalisation from "./pages/ChatbotPage/Personalisation";
import Automation from "./pages/ChatbotPage/Automation";
import ConfigurationPage from "./pages/ChatbotPage/Configuration";
import RolePage from "./pages/MemberPage/Role";
import RoleList from "./organisms/Role/RoleList";
import SignupPage from "./pages/SignupPage";
import FinalizeRegistrationPage from "./pages/SignupPage/FinazlieRegister";
import ChatbotList from "./pages/ChatbotPage/List";
import SelectOrganization from "./pages/MasterAdmin/SelectOrganization";
import CreateOrganization from "./pages/MasterAdmin/CreateOrganization";
import AdminDashboardPage from "./pages/AdminDashboard";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/login",
        element: <PreLoginPage />,
        children: [
          {
            path: "activate/:token",
            element: <ActivatePage />,
          },
          {
            path: "forgot-password",
            element: <ForgotPasswordPage />,
          },
          {
            path: "signup",
            element: <SignupPage />,
          },
          {
            path: "finalize-register/:token",
            element: <FinalizeRegistrationPage />,
          },
          {
            path: "reset-password/:token/:uid",
            element: <ResetPasswordPage />,
          },
          {
            path: "master-admin-select-organization",
            element: <SelectOrganization />,
          },
          {
            path: "master-admin-create-organization",
            element: <CreateOrganization />,
          },
          {
            path: "master-admin-create-organization/:orgId",
            element: <CreateOrganization />,
          },
          {
            path: "",
            element: <LoginPage />,
          },
        ],
      },

      {
        path: "/dashboard",
        element: <Dashboard />,
        children: [
          {
            path: "admin",
            element: <AdminPage />,
            children: [
              {
                path: "",
                element: <AdminDashboardPage />,
              },
              {
                path: "settings",
                element: <SettingPage />,
              },
              {
                path: "members",
                element: <MemberPage />,
              },
              {
                path: "roles-list",
                element: <RoleList />,
              },
              {
                path: "roles/:roleId?",
                element: <RolePage />,
              },
              {
                path: "billing",
                element: <>biling goes here</>,
              },
              {
                path: "chatbots",
                element: <ChatbotList />,
              },
            ],
          },
          {
            path: "chatbot",
            element: <ChatbotFormPage />,
          },
          {
            path: "chatbot/:id",
            element: <ChatbotPage />,
            children: [
              {
                path: "overview",
                element: <OverviewPage />,
              },
              {
                path: "usage",
                element: <Usage />,
              },
              {
                path: "configuration",
                element: <ConfigurationPage />,
              },
              {
                path: "automation",
                element: <Automation />,
              },
              {
                path: "knowledge-base",
                element: <KnowledgeBase />,
              },
              {
                path: "personalisation",
                element: <Personalisation />,
              },
              {
                path: "channels",
                element: <Channels />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default router;
