import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  GenericScalar: any;
  JSONString: any;
  UUID: any;
  Upload: any;
};

export type ActivateUser = {
  __typename?: 'ActivateUser';
  success: Scalars['Boolean'];
};

export type AssignRole = {
  __typename?: 'AssignRole';
  user?: Maybe<CustomerType>;
};

export type ChabotBrandingType = {
  __typename?: 'ChabotBrandingType';
  colors?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['Boolean']>;
};

export enum ChanelModeEnum {
  Api = 'API',
  Chatbox = 'CHATBOX',
  FullScreen = 'FULL_SCREEN'
}

export type ChannelInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  chatbotId: Scalars['UUID'];
  domains: Scalars['String'];
  mode?: InputMaybe<ChanelModeEnum>;
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
};

export type ChannelProfileInput = {
  channelId: Scalars['UUID'];
  chatbotFont?: InputMaybe<Scalars['String']>;
  chatbotTitleColor?: InputMaybe<Scalars['String']>;
  chatbotTitleSize?: InputMaybe<Scalars['Int']>;
  color?: InputMaybe<Scalars['String']>;
  feedbackView?: InputMaybe<FeedbackViewEnum>;
  hideSources?: InputMaybe<Scalars['Boolean']>;
  hideSystemMessage?: InputMaybe<Scalars['Boolean']>;
  hideTopBar?: InputMaybe<Scalars['Boolean']>;
  hoverColor?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['Upload']>;
  logoSize?: InputMaybe<Scalars['Int']>;
  onLoad?: InputMaybe<Scalars['Boolean']>;
};

export type ChannelProfileType = {
  __typename?: 'ChannelProfileType';
  channel: ChannelType;
  chatbotFont: Scalars['String'];
  chatbotTitleColor: Scalars['String'];
  chatbotTitleSize: Scalars['Int'];
  color: Scalars['String'];
  created?: Maybe<Scalars['DateTime']>;
  feedbackView?: Maybe<FeedbackViewEnum>;
  fileName?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  hideSources?: Maybe<Scalars['Boolean']>;
  hideSystemMessage?: Maybe<Scalars['Boolean']>;
  hideTopBar?: Maybe<Scalars['Boolean']>;
  hoverColor: Scalars['String'];
  id: Scalars['UUID'];
  logo?: Maybe<Scalars['String']>;
  logoSize: Scalars['Int'];
  onLoad?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelType = {
  __typename?: 'ChannelType';
  active: Scalars['Boolean'];
  channelProfile?: Maybe<ChannelProfileType>;
  chatbot: ChatbotType;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  domains: Scalars['String'];
  id: Scalars['UUID'];
  key: Scalars['String'];
  mode?: Maybe<ChanelModeEnum>;
  name: Scalars['String'];
  scriptTag: Scalars['String'];
  updated?: Maybe<Scalars['DateTime']>;
  user: CustomerType;
};

export type ChatbotInput = {
  chatboxTitle: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  extractionAgent?: InputMaybe<Scalars['Boolean']>;
  personalised?: InputMaybe<Scalars['Boolean']>;
  welcomeMessage: Scalars['String'];
};

export type ChatbotType = {
  __typename?: 'ChatbotType';
  chatbotChannels: Array<ChannelType>;
  chatbotHelperQuestions: Array<HelperQuestionsType>;
  chatbotOpensearchConfig: Array<OpenSearchConfigType>;
  chatbotSqlConfig: Array<SqlConfigType>;
  chatbotUserChatbots: Array<ChatbotUserConversationType>;
  chatboxTitle: Scalars['String'];
  created?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  documents: Array<DocumentType>;
  extractionAgent: Scalars['Boolean'];
  id: Scalars['UUID'];
  intents: Scalars['JSONString'];
  llms: Scalars['JSONString'];
  organization: OrganizationType;
  organizationId?: Maybe<Scalars['UUID']>;
  personalised: Scalars['Boolean'];
  testable: Scalars['Boolean'];
  triggers: Scalars['JSONString'];
  updated?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerType>;
  welcomeMessage: Scalars['String'];
};

export type ChatbotUserConversationListType = {
  __typename?: 'ChatbotUserConversationListType';
  data?: Maybe<Array<Maybe<ChatbotUserConversationType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ChatbotUserConversationType = {
  __typename?: 'ChatbotUserConversationType';
  chatbot: ChatbotType;
  conversationChatbotUsers: Array<UserConversationType>;
  conversations?: Maybe<Array<Maybe<UserConversationType>>>;
  created?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  extractedData: Scalars['JSONString'];
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  personalizedData?: Maybe<Scalars['String']>;
  uniqueIdentification?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type ChatbotUserType = {
  __typename?: 'ChatbotUserType';
  chatbot: ChatbotType;
  conversationChatbotUsers: Array<UserConversationType>;
  created?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  extractedData: Scalars['JSONString'];
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  personalizedData?: Maybe<Scalars['String']>;
  uniqueIdentification?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type ConversationType = {
  __typename?: 'ConversationType';
  chatbotUser: ChatbotUserConversationType;
  created?: Maybe<Scalars['DateTime']>;
  extractedData?: Maybe<Scalars['JSONString']>;
  id: Scalars['UUID'];
  messageConversations: Array<MessageConversationType>;
  state?: Maybe<LokichatsPlatformConversationStateChoices>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type CreateChannel = {
  __typename?: 'CreateChannel';
  channel?: Maybe<ChannelType>;
};

export type CreateChannelProfile = {
  __typename?: 'CreateChannelProfile';
  channelProfile?: Maybe<ChannelProfileType>;
};

export type CreateChatbot = {
  __typename?: 'CreateChatbot';
  chatbot?: Maybe<ChatbotType>;
};

export type CreateChatbotUser = {
  __typename?: 'CreateChatbotUser';
  chatbotUser?: Maybe<ChatbotUserType>;
};

export type CreateConversation = {
  __typename?: 'CreateConversation';
  chatbotUser?: Maybe<ChatbotUserType>;
  conversation?: Maybe<ConversationType>;
};

export type CreateHelperQuestions = {
  __typename?: 'CreateHelperQuestions';
  helperQuestions?: Maybe<HelperQuestionsType>;
};

export type CreateMessage = {
  __typename?: 'CreateMessage';
  message?: Maybe<MessageType>;
};

export type CreateOpenSearchConfig = {
  __typename?: 'CreateOpenSearchConfig';
  opensearchConfig?: Maybe<OpenSearchConfigType>;
};

export type CreateOrUpdateChannel = {
  __typename?: 'CreateOrUpdateChannel';
  channel?: Maybe<ChannelType>;
};

export type CreateOrganization = {
  __typename?: 'CreateOrganization';
  organization?: Maybe<OrganizationType>;
};

export type CreateQuestionPairs = {
  __typename?: 'CreateQuestionPairs';
  document?: Maybe<DocumentType>;
};

export type CreateRole = {
  __typename?: 'CreateRole';
  role?: Maybe<RoleType>;
};

export type CreateSqlConfig = {
  __typename?: 'CreateSQLConfig';
  sqlConfig?: Maybe<SqlConfigType>;
};

export type CustomObtainJsonWebToken = {
  __typename?: 'CustomObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type CustomerType = {
  __typename?: 'CustomerType';
  dateJoined: Scalars['DateTime'];
  designation?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['UUID'];
  isActive: Scalars['Boolean'];
  isMaster: Scalars['Boolean'];
  isOwner: Scalars['Boolean'];
  name: Scalars['String'];
  organization?: Maybe<OrganizationType>;
  role?: Maybe<RoleType>;
  usecaseFunction?: Maybe<Scalars['String']>;
};

export type DeleteChannel = {
  __typename?: 'DeleteChannel';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteChannelProfileLogo = {
  __typename?: 'DeleteChannelProfileLogo';
  id: Scalars['UUID'];
};

export type DeleteChatbot = {
  __typename?: 'DeleteChatbot';
  id?: Maybe<Scalars['UUID']>;
};

export type DeleteCustomer = {
  __typename?: 'DeleteCustomer';
  id?: Maybe<Scalars['UUID']>;
};

export type DeleteDocument = {
  __typename?: 'DeleteDocument';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteHelperQuestions = {
  __typename?: 'DeleteHelperQuestions';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteRoleMutation = {
  __typename?: 'DeleteRoleMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  chatbot: ChatbotType;
  created?: Maybe<Scalars['DateTime']>;
  file: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  questionPairs?: Maybe<Scalars['JSONString']>;
  summary?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export enum FeedbackViewEnum {
  Smile = 'SMILE',
  Star = 'STAR',
  Thumb = 'THUMB'
}

export type ForgotPasswordMutation = {
  __typename?: 'ForgotPasswordMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type FrequentlyQuestionType = {
  __typename?: 'FrequentlyQuestionType';
  count: Scalars['Int'];
  question: Scalars['String'];
};

export type HelperQuestionsType = {
  __typename?: 'HelperQuestionsType';
  chatbot: ChatbotType;
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  questions: Scalars['JSONString'];
  updated?: Maybe<Scalars['DateTime']>;
};

/** An enumeration. */
export enum LokichatsPlatformConversationStateChoices {
  /** COMPLETED */
  Completed = 'COMPLETED',
  /** INITIALIZED */
  Initialized = 'INITIALIZED',
  /** LIVE_CHAT */
  LiveChat = 'LIVE_CHAT',
  /** ONGOING */
  Ongoing = 'ONGOING'
}

/** An enumeration. */
export enum LokichatsPlatformMessageAuthorChoices {
  /** AGENT */
  MessageAuthorsAgent = 'MESSAGE_AUTHORS_AGENT',
  /** BOT */
  MessageAuthorsBot = 'MESSAGE_AUTHORS_BOT',
  /** ERROR */
  MessageAuthorsError = 'MESSAGE_AUTHORS_ERROR',
  /** SYSTEM */
  MessageAuthorsSystem = 'MESSAGE_AUTHORS_SYSTEM',
  /** USER */
  MessageAuthorsUser = 'MESSAGE_AUTHORS_USER',
  /** WELCOME */
  MessageAuthorsWelcome = 'MESSAGE_AUTHORS_WELCOME'
}

/** An enumeration. */
export enum LokichatsPlatformMessageOriginChoices {
  /** BUTTON */
  MessageOriginButton = 'MESSAGE_ORIGIN_BUTTON',
  /** FEEDBACK */
  MessageOriginFeedback = 'MESSAGE_ORIGIN_FEEDBACK',
  /** SYSTEM */
  MessageOriginSystem = 'MESSAGE_ORIGIN_SYSTEM',
  /** USER */
  MessageOriginUser = 'MESSAGE_ORIGIN_USER'
}

export type MessageConversationType = {
  __typename?: 'MessageConversationType';
  author?: Maybe<Scalars['String']>;
  conversation: UserConversationType;
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  origin?: Maybe<LokichatsPlatformMessageOriginChoices>;
  rating?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
  userFeedback?: Maybe<Scalars['JSONString']>;
};

export type MessageType = {
  __typename?: 'MessageType';
  author?: Maybe<LokichatsPlatformMessageAuthorChoices>;
  conversation: UserConversationType;
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  origin?: Maybe<LokichatsPlatformMessageOriginChoices>;
  rating?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
  userFeedback?: Maybe<Scalars['JSONString']>;
};

export type MostFrequentlyQuestionsType = {
  __typename?: 'MostFrequentlyQuestionsType';
  questions?: Maybe<Array<Maybe<FrequentlyQuestionType>>>;
  total: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateUser?: Maybe<ActivateUser>;
  assignRole?: Maybe<AssignRole>;
  createChannel?: Maybe<CreateChannel>;
  createChatbot?: Maybe<CreateChatbot>;
  createChatbotUser?: Maybe<CreateChatbotUser>;
  createConversation?: Maybe<CreateConversation>;
  createDocument?: Maybe<UploadDocument>;
  createHelperQuestions?: Maybe<CreateHelperQuestions>;
  createMessage?: Maybe<CreateMessage>;
  createOpensearchConfig?: Maybe<CreateOpenSearchConfig>;
  createOrUpdateChannel?: Maybe<CreateOrUpdateChannel>;
  createOrganization?: Maybe<CreateOrganization>;
  createQuestionPairs?: Maybe<CreateQuestionPairs>;
  createRole?: Maybe<CreateRole>;
  createSqlConfig?: Maybe<CreateSqlConfig>;
  deleteChannel?: Maybe<DeleteChannel>;
  deleteChatbot?: Maybe<DeleteChatbot>;
  deleteCustomer?: Maybe<DeleteCustomer>;
  deleteDocument?: Maybe<DeleteDocument>;
  deleteHelperQuestions?: Maybe<DeleteHelperQuestions>;
  deleteRole?: Maybe<DeleteRoleMutation>;
  forgotPassword?: Maybe<ForgotPasswordMutation>;
  login?: Maybe<CustomObtainJsonWebToken>;
  refreshToken?: Maybe<Refresh>;
  register?: Maybe<RegisterMutation>;
  removeChannelProfileLogo?: Maybe<DeleteChannelProfileLogo>;
  resetPassword?: Maybe<ResetPasswordMutation>;
  signinChatbotUser?: Maybe<SignInChatbotUser>;
  signup?: Maybe<SignupMutation>;
  signupOrganization?: Maybe<SignupOrganizationMutation>;
  updateChannel?: Maybe<UpdateChannel>;
  updateChannelProfile?: Maybe<CreateChannelProfile>;
  updateChatbot?: Maybe<UpdateChatbot>;
  updateCustomer?: Maybe<UpdateCustomer>;
  updateHelperQuestions?: Maybe<UpdateHelperQuestions>;
  updateOpensearchConfig?: Maybe<UpdateOpenSearchConfig>;
  updateOrganization?: Maybe<UpdateOrganization>;
  updateRole?: Maybe<UpdateRole>;
  updateSqlConfig?: Maybe<UpdateSqlConfig>;
  verifyToken?: Maybe<Verify>;
};


export type MutationActivateUserArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationAssignRoleArgs = {
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
};


export type MutationCreateChannelArgs = {
  input: ChannelInput;
};


export type MutationCreateChatbotArgs = {
  chatbotData: ChatbotInput;
  organizationId?: InputMaybe<Scalars['UUID']>;
};


export type MutationCreateChatbotUserArgs = {
  chatbotId: Scalars['UUID'];
  email: Scalars['String'];
  extractedData?: InputMaybe<Scalars['JSONString']>;
  name: Scalars['String'];
  personalizedData?: InputMaybe<Scalars['String']>;
};


export type MutationCreateConversationArgs = {
  chatbotUserId: Scalars['ID'];
};


export type MutationCreateDocumentArgs = {
  chatbotId: Scalars['UUID'];
  file: Scalars['Upload'];
};


export type MutationCreateHelperQuestionsArgs = {
  chatbotId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  organizationId?: InputMaybe<Scalars['String']>;
  questions: Scalars['JSONString'];
};


export type MutationCreateMessageArgs = {
  author: Scalars['String'];
  conversationId: Scalars['UUID'];
  text: Scalars['String'];
};


export type MutationCreateOpensearchConfigArgs = {
  chatbotId: Scalars['UUID'];
  dbAdditionalResponse: Scalars['String'];
  dbHost: Scalars['String'];
  dbName: Scalars['String'];
  dbPass: Scalars['String'];
  dbPort: Scalars['String'];
  dbResponse: Scalars['String'];
  dbUser: Scalars['String'];
};


export type MutationCreateOrUpdateChannelArgs = {
  id?: InputMaybe<Scalars['String']>;
  input: ChannelInput;
};


export type MutationCreateOrganizationArgs = {
  name: Scalars['String'];
  priceTierType: Scalars['String'];
  timezone: Scalars['String'];
};


export type MutationCreateQuestionPairsArgs = {
  chatbotId: Scalars['UUID'];
  id?: InputMaybe<Scalars['String']>;
  questionPairs: Scalars['JSONString'];
};


export type MutationCreateRoleArgs = {
  role: Scalars['String'];
};


export type MutationCreateSqlConfigArgs = {
  chatbotId: Scalars['UUID'];
  dbDescription: Scalars['String'];
  dbHost: Scalars['String'];
  dbListTables: Scalars['String'];
  dbName: Scalars['String'];
  dbPass: Scalars['String'];
  dbPort: Scalars['String'];
  dbUser: Scalars['String'];
  sqlType: Scalars['String'];
};


export type MutationDeleteChannelArgs = {
  id: Scalars['UUID'];
  organizationId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteChatbotArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteHelperQuestionsArgs = {
  chatbotId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['UUID'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRefreshTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type MutationRegisterArgs = {
  email: Scalars['String'];
  isOwner?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['UUID']>;
};


export type MutationRemoveChannelProfileLogoArgs = {
  id: Scalars['UUID'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationSigninChatbotUserArgs = {
  email: Scalars['String'];
  extractedData?: InputMaybe<Scalars['JSONString']>;
  name: Scalars['String'];
};


export type MutationSignupArgs = {
  designation?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
  organization: Scalars['String'];
  usecaseFunction?: InputMaybe<Scalars['String']>;
};


export type MutationSignupOrganizationArgs = {
  adminEmail: Scalars['String'];
  adminName: Scalars['String'];
  name: Scalars['String'];
  priceTierType: Scalars['String'];
  timezone: Scalars['String'];
};


export type MutationUpdateChannelArgs = {
  id: Scalars['UUID'];
  input: ChannelInput;
};


export type MutationUpdateChannelProfileArgs = {
  channelProfileData: ChannelProfileInput;
};


export type MutationUpdateChatbotArgs = {
  chatbotData: ChatbotInput;
  id: Scalars['UUID'];
};


export type MutationUpdateCustomerArgs = {
  id?: InputMaybe<Scalars['UUID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isOwner?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateHelperQuestionsArgs = {
  chatbotId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  questions?: InputMaybe<Scalars['JSONString']>;
};


export type MutationUpdateOpensearchConfigArgs = {
  chatbotId?: InputMaybe<Scalars['UUID']>;
  dbAdditionalResponse?: InputMaybe<Scalars['String']>;
  dbHost?: InputMaybe<Scalars['String']>;
  dbName?: InputMaybe<Scalars['String']>;
  dbPass?: InputMaybe<Scalars['String']>;
  dbPort?: InputMaybe<Scalars['String']>;
  dbResponse?: InputMaybe<Scalars['String']>;
  dbUser?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};


export type MutationUpdateOrganizationArgs = {
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  priceTierType?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateRoleArgs = {
  id: Scalars['UUID'];
  role: Scalars['String'];
};


export type MutationUpdateSqlConfigArgs = {
  chatbotId?: InputMaybe<Scalars['UUID']>;
  dbDescription?: InputMaybe<Scalars['String']>;
  dbHost?: InputMaybe<Scalars['String']>;
  dbListTables?: InputMaybe<Scalars['String']>;
  dbName?: InputMaybe<Scalars['String']>;
  dbPass?: InputMaybe<Scalars['String']>;
  dbPort?: InputMaybe<Scalars['String']>;
  dbUser?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  sqlType?: InputMaybe<Scalars['String']>;
};


export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};

export type OpenSearchConfigType = {
  __typename?: 'OpenSearchConfigType';
  chatbot: ChatbotType;
  created?: Maybe<Scalars['DateTime']>;
  dbAdditionalResponse?: Maybe<Scalars['String']>;
  dbHost: Scalars['String'];
  dbName: Scalars['String'];
  dbPass: Scalars['String'];
  dbPort: Scalars['String'];
  dbResponse: Scalars['String'];
  dbUser: Scalars['String'];
  id: Scalars['UUID'];
  updated?: Maybe<Scalars['DateTime']>;
};

export type OrganizationType = {
  __typename?: 'OrganizationType';
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  monthlyQuestionsLimit?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  numberOfChatbots?: Maybe<Scalars['Float']>;
  priceTierConfig?: Maybe<PriceTierConfigType>;
  priceTierType?: Maybe<Scalars['String']>;
  storageLimit?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type PriceTierConfigType = {
  __typename?: 'PriceTierConfigType';
  acl?: Maybe<Scalars['Boolean']>;
  chabotBranding?: Maybe<ChabotBrandingType>;
  fileSizeLimit?: Maybe<Scalars['Float']>;
  monthlyQuestionsLimit?: Maybe<Scalars['Float']>;
  numberOfChatbots?: Maybe<Scalars['Float']>;
  numberOfFilePerBot?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  storageLimit?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  channel?: Maybe<ChannelType>;
  channels?: Maybe<Array<Maybe<ChannelType>>>;
  chatbot?: Maybe<ChatbotType>;
  chatbotUserConversation?: Maybe<ChatbotUserConversationListType>;
  chatbots?: Maybe<Array<Maybe<ChatbotType>>>;
  conversations?: Maybe<Array<Maybe<ConversationType>>>;
  documents?: Maybe<Array<Maybe<DocumentType>>>;
  generateReportConversation?: Maybe<Scalars['Boolean']>;
  helperQuestions?: Maybe<Array<Maybe<HelperQuestionsType>>>;
  me?: Maybe<CustomerType>;
  messages?: Maybe<Array<Maybe<MessageType>>>;
  mostFrequentlyQuestions?: Maybe<MostFrequentlyQuestionsType>;
  opensearchConfigs?: Maybe<Array<Maybe<OpenSearchConfigType>>>;
  organization?: Maybe<OrganizationType>;
  organizationCustomers?: Maybe<Array<Maybe<CustomerType>>>;
  organizations?: Maybe<Array<Maybe<OrganizationType>>>;
  registerTokenOrganization?: Maybe<CustomerType>;
  responseTime?: Maybe<ResponseTimeType>;
  role?: Maybe<RoleType>;
  roles?: Maybe<Array<Maybe<RoleType>>>;
  sqlConfigs?: Maybe<Array<Maybe<SqlConfigType>>>;
  userAndConversation?: Maybe<UserAndConversationType>;
};


export type QueryChannelArgs = {
  chatbotId: Scalars['UUID'];
  id: Scalars['UUID'];
  organizationId?: InputMaybe<Scalars['String']>;
};


export type QueryChannelsArgs = {
  chatbotId: Scalars['UUID'];
  organizationId?: InputMaybe<Scalars['String']>;
};


export type QueryChatbotArgs = {
  id: Scalars['UUID'];
  organizationId?: InputMaybe<Scalars['String']>;
};


export type QueryChatbotUserConversationArgs = {
  chatbotId: Scalars['UUID'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryChatbotsArgs = {
  dateFrom?: InputMaybe<Scalars['Date']>;
  dateTo?: InputMaybe<Scalars['Date']>;
  organizationId?: InputMaybe<Scalars['String']>;
};


export type QueryDocumentsArgs = {
  chatbotId: Scalars['UUID'];
};


export type QueryGenerateReportConversationArgs = {
  chatbotId: Scalars['UUID'];
};


export type QueryHelperQuestionsArgs = {
  chatbotId: Scalars['UUID'];
};


export type QueryMeArgs = {
  organizationId?: InputMaybe<Scalars['String']>;
};


export type QueryMostFrequentlyQuestionsArgs = {
  chatbotId: Scalars['UUID'];
};


export type QueryOpensearchConfigsArgs = {
  chatbotId: Scalars['UUID'];
};


export type QueryOrganizationArgs = {
  id: Scalars['UUID'];
};


export type QueryOrganizationCustomersArgs = {
  organizationId: Scalars['UUID'];
};


export type QueryRegisterTokenOrganizationArgs = {
  token: Scalars['String'];
};


export type QueryResponseTimeArgs = {
  chatbotId: Scalars['UUID'];
};


export type QueryRoleArgs = {
  id: Scalars['UUID'];
};


export type QuerySqlConfigsArgs = {
  chatbotId: Scalars['UUID'];
};


export type QueryUserAndConversationArgs = {
  chatbotId: Scalars['UUID'];
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type RegisterMutation = {
  __typename?: 'RegisterMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type ResetPasswordMutation = {
  __typename?: 'ResetPasswordMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type ResponseTimeType = {
  __typename?: 'ResponseTimeType';
  avg: Scalars['Float'];
  fastest: Scalars['Float'];
  longest: Scalars['Float'];
};

export type RoleType = {
  __typename?: 'RoleType';
  created?: Maybe<Scalars['DateTime']>;
  customerRoles: Array<CustomerType>;
  id: Scalars['UUID'];
  organization?: Maybe<OrganizationType>;
  role?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type SqlConfigType = {
  __typename?: 'SQLConfigType';
  chatbot: ChatbotType;
  created?: Maybe<Scalars['DateTime']>;
  dbDescription: Scalars['String'];
  dbHost: Scalars['String'];
  dbListTables?: Maybe<Scalars['String']>;
  dbName: Scalars['String'];
  dbPass: Scalars['String'];
  dbPort: Scalars['String'];
  dbUser: Scalars['String'];
  id: Scalars['UUID'];
  sqlType: Scalars['String'];
  updated?: Maybe<Scalars['DateTime']>;
};

export type SignInChatbotUser = {
  __typename?: 'SignInChatbotUser';
  token: Scalars['String'];
};

export type SignupMutation = {
  __typename?: 'SignupMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type SignupOrganizationMutation = {
  __typename?: 'SignupOrganizationMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateChannel = {
  __typename?: 'UpdateChannel';
  channel?: Maybe<ChannelType>;
};

export type UpdateChatbot = {
  __typename?: 'UpdateChatbot';
  chatbot?: Maybe<ChatbotType>;
};

export type UpdateCustomer = {
  __typename?: 'UpdateCustomer';
  customer?: Maybe<CustomerType>;
};

export type UpdateHelperQuestions = {
  __typename?: 'UpdateHelperQuestions';
  helperQuestions?: Maybe<HelperQuestionsType>;
};

export type UpdateOpenSearchConfig = {
  __typename?: 'UpdateOpenSearchConfig';
  opensearchConfig?: Maybe<OpenSearchConfigType>;
};

export type UpdateOrganization = {
  __typename?: 'UpdateOrganization';
  organization?: Maybe<OrganizationType>;
};

export type UpdateRole = {
  __typename?: 'UpdateRole';
  role?: Maybe<RoleType>;
};

export type UpdateSqlConfig = {
  __typename?: 'UpdateSQLConfig';
  sqlConfig?: Maybe<SqlConfigType>;
};

export type UploadDocument = {
  __typename?: 'UploadDocument';
  document?: Maybe<DocumentType>;
};

export type UserAndConversationType = {
  __typename?: 'UserAndConversationType';
  avgConversationsPerUser: Scalars['Float'];
  avgMessagesPerConversation: Scalars['Float'];
  conversationsCount: Scalars['Float'];
  uniqueUsersCount: Scalars['Float'];
};

export type UserConversationType = {
  __typename?: 'UserConversationType';
  chatbotUser: ChatbotUserConversationType;
  created?: Maybe<Scalars['DateTime']>;
  extractedData?: Maybe<Scalars['JSONString']>;
  id: Scalars['UUID'];
  messageConversations: Array<MessageConversationType>;
  messages?: Maybe<Array<Maybe<MessageConversationType>>>;
  state?: Maybe<LokichatsPlatformConversationStateChoices>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type ChannelFieldsFragment = { __typename?: 'ChannelType', id: any, name: string, domains: string, scriptTag: string, mode?: ChanelModeEnum | null, active: boolean };

export type ChannelProfileFieldsFragment = { __typename?: 'ChannelProfileType', id: any, color: string, hoverColor: string, logo?: string | null, logoSize: number, chatbotTitleSize: number, chatbotTitleColor: string, chatbotFont: string, hideTopBar?: boolean | null, hideSystemMessage?: boolean | null, hideSources?: boolean | null, onLoad?: boolean | null, fileType?: string | null, fileName?: string | null, url?: string | null, feedbackView?: FeedbackViewEnum | null, channel: { __typename?: 'ChannelType', id: any, name: string, domains: string, scriptTag: string, mode?: ChanelModeEnum | null, active: boolean } };

export type UpdateOrganizationMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  priceTierType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
}>;


export type UpdateOrganizationMutation = { __typename?: 'Mutation', updateOrganization?: { __typename?: 'UpdateOrganization', organization?: { __typename?: 'OrganizationType', id: any } | null } | null };

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'Query', roles?: Array<{ __typename?: 'RoleType', id: any, role?: string | null, customerRoles: Array<{ __typename?: 'CustomerType', id: any, email: string }> } | null> | null };

export type RoleQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type RoleQuery = { __typename?: 'Query', role?: { __typename?: 'RoleType', id: any, role?: string | null } | null };

export type DocumentsQueryVariables = Exact<{
  chatbotId: Scalars['UUID'];
}>;


export type DocumentsQuery = { __typename?: 'Query', documents?: Array<{ __typename?: 'DocumentType', id: any, created?: any | null, updated?: any | null, fileType?: string | null, fileName?: string | null, summary?: string | null, file: string, questionPairs?: any | null, chatbot: { __typename?: 'ChatbotType', id: any } } | null> | null };

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteDocumentMutation = { __typename?: 'Mutation', deleteDocument?: { __typename?: 'DeleteDocument', success?: boolean | null } | null };

export type CreateDocumentMutationVariables = Exact<{
  chatbotId: Scalars['UUID'];
  file: Scalars['Upload'];
}>;


export type CreateDocumentMutation = { __typename?: 'Mutation', createDocument?: { __typename?: 'UploadDocument', document?: { __typename?: 'DocumentType', id: any, file: string, chatbot: { __typename?: 'ChatbotType', id: any } } | null } | null };

export type UpdateChatbotMutationVariables = Exact<{
  id: Scalars['UUID'];
  chatbotData: ChatbotInput;
}>;


export type UpdateChatbotMutation = { __typename?: 'Mutation', updateChatbot?: { __typename?: 'UpdateChatbot', chatbot?: { __typename?: 'ChatbotType', id: any, chatboxTitle: string, welcomeMessage: string, description?: string | null, personalised: boolean, extractionAgent: boolean, chatbotChannels: Array<{ __typename?: 'ChannelType', id: any, name: string, domains: string, scriptTag: string, mode?: ChanelModeEnum | null, active: boolean }> } | null } | null };

export type DeleteChatbotMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteChatbotMutation = { __typename?: 'Mutation', deleteChatbot?: { __typename?: 'DeleteChatbot', id?: any | null } | null };

export type UpdateCustomerMutationVariables = Exact<{
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isOwner?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateCustomerMutation = { __typename?: 'Mutation', updateCustomer?: { __typename?: 'UpdateCustomer', customer?: { __typename?: 'CustomerType', id: any, email: string, name: string, isOwner: boolean, dateJoined: any, organization?: { __typename?: 'OrganizationType', id: any } | null } | null } | null };

export type DeleteCustomerMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteCustomerMutation = { __typename?: 'Mutation', deleteCustomer?: { __typename?: 'DeleteCustomer', id?: any | null } | null };

export type ChannelsQueryVariables = Exact<{
  chatbotId: Scalars['UUID'];
  organizationId?: InputMaybe<Scalars['String']>;
}>;


export type ChannelsQuery = { __typename?: 'Query', channels?: Array<{ __typename?: 'ChannelType', id: any, name: string, domains: string, scriptTag: string, mode?: ChanelModeEnum | null, active: boolean } | null> | null };

export type ChannelQueryVariables = Exact<{
  id: Scalars['UUID'];
  chatbotId: Scalars['UUID'];
  organizationId?: InputMaybe<Scalars['String']>;
}>;


export type ChannelQuery = { __typename?: 'Query', channel?: { __typename?: 'ChannelType', id: any, name: string, domains: string, scriptTag: string, mode?: ChanelModeEnum | null, active: boolean } | null };

export type OrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationsQuery = { __typename?: 'Query', organizations?: Array<{ __typename?: 'OrganizationType', id: any, name: string, timezone?: string | null, priceTierType?: string | null, numberOfChatbots?: number | null, monthlyQuestionsLimit?: number | null, storageLimit?: number | null, priceTierConfig?: { __typename?: 'PriceTierConfigType', numberOfChatbots?: number | null, numberOfFilePerBot?: number | null, fileSizeLimit?: number | null, storageLimit?: number | null, monthlyQuestionsLimit?: number | null, acl?: boolean | null, price?: number | null, chabotBranding?: { __typename?: 'ChabotBrandingType', logo?: boolean | null, colors?: boolean | null } | null } | null } | null> | null };

export type OrganizationCustomersQueryVariables = Exact<{
  organizationId: Scalars['UUID'];
}>;


export type OrganizationCustomersQuery = { __typename?: 'Query', organizationCustomers?: Array<{ __typename?: 'CustomerType', email: string, name: string, id: any, dateJoined: any, isActive: boolean, isOwner: boolean, organization?: { __typename?: 'OrganizationType', name: string, timezone?: string | null, id: any } | null, role?: { __typename?: 'RoleType', id: any, role?: string | null } | null } | null> | null };

export type OrganizationStatsQueryVariables = Exact<{
  organizationId: Scalars['UUID'];
}>;


export type OrganizationStatsQuery = { __typename?: 'Query', organization?: { __typename?: 'OrganizationType', id: any, numberOfChatbots?: number | null, monthlyQuestionsLimit?: number | null, storageLimit?: number | null, priceTierConfig?: { __typename?: 'PriceTierConfigType', numberOfChatbots?: number | null, numberOfFilePerBot?: number | null, fileSizeLimit?: number | null, storageLimit?: number | null, monthlyQuestionsLimit?: number | null, acl?: boolean | null, price?: number | null, chabotBranding?: { __typename?: 'ChabotBrandingType', logo?: boolean | null, colors?: boolean | null } | null } | null } | null };

export type OrganizationStatsNumberOfChatbotQueryVariables = Exact<{
  organizationId: Scalars['UUID'];
}>;


export type OrganizationStatsNumberOfChatbotQuery = { __typename?: 'Query', organization?: { __typename?: 'OrganizationType', id: any, numberOfChatbots?: number | null } | null };

export type OrganizationStatsMonthlyQuestionsLimitQueryVariables = Exact<{
  organizationId: Scalars['UUID'];
}>;


export type OrganizationStatsMonthlyQuestionsLimitQuery = { __typename?: 'Query', organization?: { __typename?: 'OrganizationType', id: any, monthlyQuestionsLimit?: number | null } | null };

export type OrganizationStatsStorageLimitQueryVariables = Exact<{
  organizationId: Scalars['UUID'];
}>;


export type OrganizationStatsStorageLimitQuery = { __typename?: 'Query', organization?: { __typename?: 'OrganizationType', id: any, storageLimit?: number | null } | null };

export type MeQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars['String']>;
}>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'CustomerType', email: string, name: string, id: any, dateJoined: any, isActive: boolean, isOwner: boolean, isMaster: boolean, organization?: { __typename?: 'OrganizationType', id: any, name: string, numberOfChatbots?: number | null, monthlyQuestionsLimit?: number | null, storageLimit?: number | null, timezone?: string | null, priceTierType?: string | null, priceTierConfig?: { __typename?: 'PriceTierConfigType', numberOfChatbots?: number | null, numberOfFilePerBot?: number | null, fileSizeLimit?: number | null, storageLimit?: number | null, monthlyQuestionsLimit?: number | null, acl?: boolean | null, price?: number | null, chabotBranding?: { __typename?: 'ChabotBrandingType', logo?: boolean | null, colors?: boolean | null } | null } | null } | null } | null };

export type ChatbotListQueryVariables = Exact<{
  dateFrom?: InputMaybe<Scalars['Date']>;
  dateTo?: InputMaybe<Scalars['Date']>;
  organizationId?: InputMaybe<Scalars['String']>;
}>;


export type ChatbotListQuery = { __typename?: 'Query', chatbots?: Array<{ __typename?: 'ChatbotType', id: any, chatboxTitle: string, welcomeMessage: string, description?: string | null, created?: any | null, updated?: any | null, organizationId?: any | null, chatbotChannels: Array<{ __typename?: 'ChannelType', id: any, name: string, domains: string, scriptTag: string, mode?: ChanelModeEnum | null, active: boolean }> } | null> | null };

export type GetOneChatbotQueryVariables = Exact<{
  id: Scalars['UUID'];
  organizationId?: InputMaybe<Scalars['String']>;
}>;


export type GetOneChatbotQuery = { __typename?: 'Query', chatbot?: { __typename?: 'ChatbotType', id: any, chatboxTitle: string, welcomeMessage: string, description?: string | null, created?: any | null, updated?: any | null, personalised: boolean, extractionAgent: boolean, organizationId?: any | null, chatbotChannels: Array<{ __typename?: 'ChannelType', id: any, name: string, domains: string, scriptTag: string, mode?: ChanelModeEnum | null, active: boolean, channelProfile?: { __typename?: 'ChannelProfileType', id: any, color: string, hoverColor: string, logo?: string | null, logoSize: number, chatbotTitleSize: number, chatbotTitleColor: string, chatbotFont: string, hideTopBar?: boolean | null, hideSystemMessage?: boolean | null, hideSources?: boolean | null, onLoad?: boolean | null, fileType?: string | null, fileName?: string | null, url?: string | null, feedbackView?: FeedbackViewEnum | null, channel: { __typename?: 'ChannelType', id: any, name: string, domains: string, scriptTag: string, mode?: ChanelModeEnum | null, active: boolean } } | null }>, organization: { __typename?: 'OrganizationType', id: any, name: string }, documents: Array<{ __typename?: 'DocumentType', id: any, file: string, summary?: string | null, fileType?: string | null, fileName?: string | null, questionPairs?: any | null }>, chatbotSqlConfig: Array<{ __typename?: 'SQLConfigType', id: any, dbName: string, dbUser: string, dbPass: string, dbHost: string, dbPort: string, sqlType: string, dbDescription: string, dbListTables?: string | null }>, user?: { __typename?: 'CustomerType', id: any, email: string, name: string, isOwner: boolean } | null, chatbotOpensearchConfig: Array<{ __typename?: 'OpenSearchConfigType', id: any, dbName: string, dbUser: string, dbPass: string, dbHost: string, dbPort: string, dbResponse: string, dbAdditionalResponse?: string | null }>, chatbotHelperQuestions: Array<{ __typename?: 'HelperQuestionsType', id: any, questions: any }> } | null };

export type ChatbotQueryVariables = Exact<{
  dateFrom?: InputMaybe<Scalars['Date']>;
  dateTo?: InputMaybe<Scalars['Date']>;
  organizationId?: InputMaybe<Scalars['String']>;
}>;


export type ChatbotQuery = { __typename?: 'Query', chatbots?: Array<{ __typename?: 'ChatbotType', id: any, chatboxTitle: string, welcomeMessage: string, description?: string | null, created?: any | null, updated?: any | null, personalised: boolean, extractionAgent: boolean, chatbotChannels: Array<{ __typename?: 'ChannelType', id: any, name: string, domains: string, scriptTag: string, mode?: ChanelModeEnum | null, active: boolean, channelProfile?: { __typename?: 'ChannelProfileType', id: any, color: string, hoverColor: string, logo?: string | null, logoSize: number, chatbotTitleSize: number, chatbotTitleColor: string, chatbotFont: string, hideTopBar?: boolean | null, hideSystemMessage?: boolean | null, hideSources?: boolean | null, onLoad?: boolean | null, fileType?: string | null, fileName?: string | null, url?: string | null, feedbackView?: FeedbackViewEnum | null, channel: { __typename?: 'ChannelType', id: any, name: string, domains: string, scriptTag: string, mode?: ChanelModeEnum | null, active: boolean } } | null }>, organization: { __typename?: 'OrganizationType', id: any, name: string }, documents: Array<{ __typename?: 'DocumentType', id: any, file: string, summary?: string | null, fileType?: string | null, fileName?: string | null, questionPairs?: any | null }>, chatbotSqlConfig: Array<{ __typename?: 'SQLConfigType', id: any, dbName: string, dbUser: string, dbPass: string, dbHost: string, dbPort: string, sqlType: string, dbDescription: string, dbListTables?: string | null }>, user?: { __typename?: 'CustomerType', id: any, email: string, name: string, isOwner: boolean } | null, chatbotOpensearchConfig: Array<{ __typename?: 'OpenSearchConfigType', id: any, dbName: string, dbUser: string, dbPass: string, dbHost: string, dbPort: string, dbResponse: string, dbAdditionalResponse?: string | null }>, chatbotHelperQuestions: Array<{ __typename?: 'HelperQuestionsType', id: any, questions: any }> } | null> | null };

export type MessagesQueryVariables = Exact<{ [key: string]: never; }>;


export type MessagesQuery = { __typename?: 'Query', messages?: Array<{ __typename?: 'MessageType', id: any, created?: any | null, updated?: any | null, text?: string | null, author?: LokichatsPlatformMessageAuthorChoices | null, conversation: { __typename?: 'UserConversationType', id: any } } | null> | null };

export type ConversationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ConversationsQuery = { __typename?: 'Query', conversations?: Array<{ __typename?: 'ConversationType', id: any, created?: any | null, updated?: any | null, extractedData?: any | null, state?: LokichatsPlatformConversationStateChoices | null, messageConversations: Array<{ __typename?: 'MessageConversationType', id: any, created?: any | null, text?: string | null, author?: string | null }> } | null> | null };

export type OrganizationQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type OrganizationQuery = { __typename?: 'Query', organization?: { __typename?: 'OrganizationType', id: any, name: string, timezone?: string | null, priceTierType?: string | null } | null };

export type MostFrequentlyQuestionsQueryVariables = Exact<{
  chatbotId: Scalars['UUID'];
}>;


export type MostFrequentlyQuestionsQuery = { __typename?: 'Query', mostFrequentlyQuestions?: { __typename?: 'MostFrequentlyQuestionsType', total: number, questions?: Array<{ __typename?: 'FrequentlyQuestionType', question: string, count: number } | null> | null } | null };

export type ResponseTimeQueryVariables = Exact<{
  chatbotId: Scalars['UUID'];
}>;


export type ResponseTimeQuery = { __typename?: 'Query', responseTime?: { __typename?: 'ResponseTimeType', avg: number, fastest: number, longest: number } | null };

export type UserAndConversationQueryVariables = Exact<{
  chatbotId: Scalars['UUID'];
}>;


export type UserAndConversationQuery = { __typename?: 'Query', userAndConversation?: { __typename?: 'UserAndConversationType', uniqueUsersCount: number, conversationsCount: number, avgMessagesPerConversation: number, avgConversationsPerUser: number } | null };

export type GenerateReportConversationQueryVariables = Exact<{
  chatbotId: Scalars['UUID'];
}>;


export type GenerateReportConversationQuery = { __typename?: 'Query', generateReportConversation?: boolean | null };

export type ChatbotUserConversationQueryVariables = Exact<{
  chatbotId: Scalars['UUID'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;


export type ChatbotUserConversationQuery = { __typename?: 'Query', chatbotUserConversation?: { __typename?: 'ChatbotUserConversationListType', totalCount?: number | null, data?: Array<{ __typename?: 'ChatbotUserConversationType', id: any, name?: string | null, email?: string | null, conversationChatbotUsers: Array<{ __typename?: 'UserConversationType', id: any, created?: any | null, updated?: any | null, messages?: Array<{ __typename?: 'MessageConversationType', id: any, text?: string | null, author?: string | null, updated?: any | null, rating?: number | null, userFeedback?: any | null } | null> | null }> } | null> | null } | null };

export type SqlConfigFieldsFragment = { __typename?: 'SQLConfigType', id: any, dbName: string, dbUser: string, dbPass: string, dbHost: string, dbPort: string, sqlType: string, dbDescription: string, dbListTables?: string | null };

export type HelperQuestionsFieldsFragment = { __typename?: 'HelperQuestionsType', id: any, questions: any };

export type OpenSearchConfigFieldsFragment = { __typename?: 'OpenSearchConfigType', id: any, dbName: string, dbUser: string, dbPass: string, dbHost: string, dbPort: string, dbResponse: string, dbAdditionalResponse?: string | null };

export type SqlConfigsQueryVariables = Exact<{
  chatbotId: Scalars['UUID'];
}>;


export type SqlConfigsQuery = { __typename?: 'Query', sqlConfigs?: Array<{ __typename?: 'SQLConfigType', id: any, dbName: string, dbUser: string, dbPass: string, dbHost: string, dbPort: string, sqlType: string, dbDescription: string, dbListTables?: string | null } | null> | null };

export type HelperQuestionsQueryVariables = Exact<{
  chatbotId: Scalars['UUID'];
}>;


export type HelperQuestionsQuery = { __typename?: 'Query', helperQuestions?: Array<{ __typename?: 'HelperQuestionsType', id: any, questions: any } | null> | null };

export type OpensearchConfigsQueryVariables = Exact<{
  chatbotId: Scalars['UUID'];
}>;


export type OpensearchConfigsQuery = { __typename?: 'Query', opensearchConfigs?: Array<{ __typename?: 'OpenSearchConfigType', id: any, dbName: string, dbUser: string, dbPass: string, dbHost: string, dbPort: string, dbResponse: string, dbAdditionalResponse?: string | null } | null> | null };

export type CreateSqlConfigMutationVariables = Exact<{
  chatbotId: Scalars['UUID'];
  dbDescription: Scalars['String'];
  dbHost: Scalars['String'];
  dbName: Scalars['String'];
  dbPort: Scalars['String'];
  dbUser: Scalars['String'];
  dbPass: Scalars['String'];
  sqlType: Scalars['String'];
  dbListTables: Scalars['String'];
}>;


export type CreateSqlConfigMutation = { __typename?: 'Mutation', createSqlConfig?: { __typename?: 'CreateSQLConfig', sqlConfig?: { __typename?: 'SQLConfigType', id: any, dbName: string, dbUser: string, dbPass: string, dbHost: string, dbPort: string, sqlType: string, dbDescription: string, dbListTables?: string | null } | null } | null };

export type CreateHelperQuestionsMutationVariables = Exact<{
  id?: InputMaybe<Scalars['UUID']>;
  chatbotId: Scalars['UUID'];
  questions: Scalars['JSONString'];
  organizationId?: InputMaybe<Scalars['String']>;
}>;


export type CreateHelperQuestionsMutation = { __typename?: 'Mutation', createHelperQuestions?: { __typename?: 'CreateHelperQuestions', helperQuestions?: { __typename?: 'HelperQuestionsType', id: any, questions: any } | null } | null };

export type CreateOpensearchConfigMutationVariables = Exact<{
  chatbotId: Scalars['UUID'];
  dbResponse: Scalars['String'];
  dbHost: Scalars['String'];
  dbName: Scalars['String'];
  dbPort: Scalars['String'];
  dbUser: Scalars['String'];
  dbPass: Scalars['String'];
  dbAdditionalResponse: Scalars['String'];
}>;


export type CreateOpensearchConfigMutation = { __typename?: 'Mutation', createOpensearchConfig?: { __typename?: 'CreateOpenSearchConfig', opensearchConfig?: { __typename?: 'OpenSearchConfigType', id: any, dbName: string, dbUser: string, dbPass: string, dbHost: string, dbPort: string, dbResponse: string, dbAdditionalResponse?: string | null } | null } | null };

export type UpdateSqlConfigMutationVariables = Exact<{
  id: Scalars['UUID'];
  chatbotId: Scalars['UUID'];
  dbDescription: Scalars['String'];
  dbHost: Scalars['String'];
  dbName: Scalars['String'];
  dbPort: Scalars['String'];
  dbUser: Scalars['String'];
  dbPass: Scalars['String'];
  sqlType: Scalars['String'];
  dbListTables: Scalars['String'];
}>;


export type UpdateSqlConfigMutation = { __typename?: 'Mutation', updateSqlConfig?: { __typename?: 'UpdateSQLConfig', sqlConfig?: { __typename?: 'SQLConfigType', id: any, dbName: string, dbUser: string, dbPass: string, dbHost: string, dbPort: string, sqlType: string, dbDescription: string, dbListTables?: string | null } | null } | null };

export type UpdateHelperQuestionsMutationVariables = Exact<{
  id: Scalars['UUID'];
  chatbotId: Scalars['UUID'];
  questions: Scalars['JSONString'];
}>;


export type UpdateHelperQuestionsMutation = { __typename?: 'Mutation', updateHelperQuestions?: { __typename?: 'UpdateHelperQuestions', helperQuestions?: { __typename?: 'HelperQuestionsType', id: any, questions: any } | null } | null };

export type DeleteHelperQuestionsMutationVariables = Exact<{
  id: Scalars['UUID'];
  chatbotId: Scalars['UUID'];
}>;


export type DeleteHelperQuestionsMutation = { __typename?: 'Mutation', deleteHelperQuestions?: { __typename?: 'DeleteHelperQuestions', success?: boolean | null } | null };

export type UpdateOpensearchConfigMutationVariables = Exact<{
  id: Scalars['UUID'];
  chatbotId: Scalars['UUID'];
  dbResponse: Scalars['String'];
  dbHost: Scalars['String'];
  dbName: Scalars['String'];
  dbPort: Scalars['String'];
  dbUser: Scalars['String'];
  dbPass: Scalars['String'];
  dbAdditionalResponse: Scalars['String'];
}>;


export type UpdateOpensearchConfigMutation = { __typename?: 'Mutation', updateOpensearchConfig?: { __typename?: 'UpdateOpenSearchConfig', opensearchConfig?: { __typename?: 'OpenSearchConfigType', id: any, dbName: string, dbUser: string, dbPass: string, dbHost: string, dbPort: string, dbResponse: string, dbAdditionalResponse?: string | null } | null } | null };

export type RemoveChannelProfileLogoMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type RemoveChannelProfileLogoMutation = { __typename?: 'Mutation', removeChannelProfileLogo?: { __typename?: 'DeleteChannelProfileLogo', id: any } | null };

export type UpdateChannelProfileMutationVariables = Exact<{
  channelProfileData: ChannelProfileInput;
}>;


export type UpdateChannelProfileMutation = { __typename?: 'Mutation', updateChannelProfile?: { __typename?: 'CreateChannelProfile', channelProfile?: { __typename?: 'ChannelProfileType', id: any, color: string, hoverColor: string, logo?: string | null, logoSize: number, chatbotTitleSize: number, chatbotTitleColor: string, chatbotFont: string, hideTopBar?: boolean | null, hideSystemMessage?: boolean | null, hideSources?: boolean | null, onLoad?: boolean | null, fileType?: string | null, fileName?: string | null, url?: string | null, feedbackView?: FeedbackViewEnum | null, channel: { __typename?: 'ChannelType', id: any, name: string, domains: string, scriptTag: string, mode?: ChanelModeEnum | null, active: boolean } } | null } | null };

export type CreateOrUpdateChannelMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
  input: ChannelInput;
}>;


export type CreateOrUpdateChannelMutation = { __typename?: 'Mutation', createOrUpdateChannel?: { __typename?: 'CreateOrUpdateChannel', channel?: { __typename?: 'ChannelType', id: any, name: string, domains: string, scriptTag: string, mode?: ChanelModeEnum | null, active: boolean } | null } | null };

export type CreateChannelMutationVariables = Exact<{
  input: ChannelInput;
}>;


export type CreateChannelMutation = { __typename?: 'Mutation', createChannel?: { __typename?: 'CreateChannel', channel?: { __typename?: 'ChannelType', id: any, name: string, domains: string, scriptTag: string, mode?: ChanelModeEnum | null, active: boolean } | null } | null };

export type UpdateChannelMutationVariables = Exact<{
  id: Scalars['UUID'];
  input: ChannelInput;
}>;


export type UpdateChannelMutation = { __typename?: 'Mutation', updateChannel?: { __typename?: 'UpdateChannel', channel?: { __typename?: 'ChannelType', id: any, name: string, domains: string, scriptTag: string, mode?: ChanelModeEnum | null, active: boolean } | null } | null };

export type DeleteChannelMutationVariables = Exact<{
  id: Scalars['UUID'];
  organizationId: Scalars['String'];
}>;


export type DeleteChannelMutation = { __typename?: 'Mutation', deleteChannel?: { __typename?: 'DeleteChannel', success?: boolean | null } | null };

export type CreateChatbotMutationVariables = Exact<{
  chatbotData: ChatbotInput;
  organizationId?: InputMaybe<Scalars['UUID']>;
}>;


export type CreateChatbotMutation = { __typename?: 'Mutation', createChatbot?: { __typename?: 'CreateChatbot', chatbot?: { __typename?: 'ChatbotType', id: any, chatboxTitle: string, welcomeMessage: string, description?: string | null, personalised: boolean, extractionAgent: boolean, chatbotChannels: Array<{ __typename?: 'ChannelType', id: any, name: string, domains: string, scriptTag: string, mode?: ChanelModeEnum | null, active: boolean }> } | null } | null };

export type CreateOrganizationMutationVariables = Exact<{
  name: Scalars['String'];
  timezone: Scalars['String'];
  priceTierType: Scalars['String'];
}>;


export type CreateOrganizationMutation = { __typename?: 'Mutation', createOrganization?: { __typename?: 'CreateOrganization', organization?: { __typename?: 'OrganizationType', id: any, created?: any | null, updated?: any | null, name: string, timezone?: string | null } | null } | null };

export type VerifyTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyTokenMutation = { __typename?: 'Mutation', verifyToken?: { __typename?: 'Verify', payload: any } | null };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'CustomObtainJSONWebToken', token: string } | null };

export type SignupOrganizationWithAdminMutationVariables = Exact<{
  name: Scalars['String'];
  priceTierType: Scalars['String'];
  timezone: Scalars['String'];
  adminName: Scalars['String'];
  adminEmail: Scalars['String'];
}>;


export type SignupOrganizationWithAdminMutation = { __typename?: 'Mutation', signupOrganization?: { __typename?: 'SignupOrganizationMutation', success?: boolean | null } | null };

export type SignupUserMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
  organization: Scalars['String'];
  designation?: InputMaybe<Scalars['String']>;
  usecaseFunction?: InputMaybe<Scalars['String']>;
}>;


export type SignupUserMutation = { __typename?: 'Mutation', signup?: { __typename?: 'SignupMutation', success?: boolean | null } | null };

export type InviteUserMutationVariables = Exact<{
  email: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['UUID']>;
  isOwner?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['String']>;
}>;


export type InviteUserMutation = { __typename?: 'Mutation', register?: { __typename?: 'RegisterMutation', success?: boolean | null } | null };

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type ActivateUserMutation = { __typename?: 'Mutation', activateUser?: { __typename?: 'ActivateUser', success: boolean } | null };

export type RegisterTokenOrganizationQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type RegisterTokenOrganizationQuery = { __typename?: 'Query', registerTokenOrganization?: { __typename?: 'CustomerType', id: any, email: string, name: string, organization?: { __typename?: 'OrganizationType', id: any, name: string, timezone?: string | null } | null } | null };

export type ForgotUserPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotUserPasswordMutation = { __typename?: 'Mutation', forgotPassword?: { __typename?: 'ForgotPasswordMutation', success?: boolean | null } | null };

export type ResetUserPasswordMutationVariables = Exact<{
  uid: Scalars['String'];
  token: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ResetUserPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'ResetPasswordMutation', success?: boolean | null } | null };

export type CreateQuestionPairsMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
  chatbotId: Scalars['UUID'];
  questionPairs: Scalars['JSONString'];
}>;


export type CreateQuestionPairsMutation = { __typename?: 'Mutation', createQuestionPairs?: { __typename?: 'CreateQuestionPairs', document?: { __typename?: 'DocumentType', id: any, file: string, summary?: string | null } | null } | null };

export type DeleteOrganizationRoleMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteOrganizationRoleMutation = { __typename?: 'Mutation', deleteRole?: { __typename?: 'DeleteRoleMutation', success?: boolean | null } | null };

export type CreateRoleMutationVariables = Exact<{
  role: Scalars['String'];
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole?: { __typename?: 'CreateRole', role?: { __typename?: 'RoleType', id: any, role?: string | null } | null } | null };

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars['UUID'];
  role: Scalars['String'];
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole?: { __typename?: 'UpdateRole', role?: { __typename?: 'RoleType', id: any, role?: string | null, organization?: { __typename?: 'OrganizationType', id: any } | null } | null } | null };

export type AssingRoleMutationVariables = Exact<{
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
}>;


export type AssingRoleMutation = { __typename?: 'Mutation', assignRole?: { __typename?: 'AssignRole', user?: { __typename?: 'CustomerType', id: any } | null } | null };

export const ChannelFieldsFragmentDoc = gql`
    fragment ChannelFields on ChannelType {
  id
  name
  domains
  scriptTag
  mode
  active
}
    `;
export const ChannelProfileFieldsFragmentDoc = gql`
    fragment ChannelProfileFields on ChannelProfileType {
  id
  channel {
    ...ChannelFields
  }
  color
  hoverColor
  logo
  logoSize
  chatbotTitleSize
  chatbotTitleColor
  chatbotFont
  hideTopBar
  hideSystemMessage
  hideSources
  onLoad
  fileType
  fileName
  url
  feedbackView
}
    ${ChannelFieldsFragmentDoc}`;
export const SqlConfigFieldsFragmentDoc = gql`
    fragment SQLConfigFields on SQLConfigType {
  id
  dbName
  dbUser
  dbPass
  dbHost
  dbPort
  sqlType
  dbDescription
  dbListTables
}
    `;
export const HelperQuestionsFieldsFragmentDoc = gql`
    fragment HelperQuestionsFields on HelperQuestionsType {
  id
  questions
}
    `;
export const OpenSearchConfigFieldsFragmentDoc = gql`
    fragment OpenSearchConfigFields on OpenSearchConfigType {
  id
  dbName
  dbUser
  dbPass
  dbHost
  dbPort
  dbResponse
  dbAdditionalResponse
}
    `;
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($name: String, $timezone: String, $priceTierType: String, $id: UUID) {
  updateOrganization(
    name: $name
    timezone: $timezone
    priceTierType: $priceTierType
    id: $id
  ) {
    organization {
      id
    }
  }
}
    `;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      timezone: // value for 'timezone'
 *      priceTierType: // value for 'priceTierType'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const RolesDocument = gql`
    query roles {
  roles {
    id
    role
    customerRoles {
      id
      email
    }
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const RoleDocument = gql`
    query role($id: UUID!) {
  role(id: $id) {
    id
    role
  }
}
    `;

/**
 * __useRoleQuery__
 *
 * To run a query within a React component, call `useRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleQuery(baseOptions: Apollo.QueryHookOptions<RoleQuery, RoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleQuery, RoleQueryVariables>(RoleDocument, options);
      }
export function useRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleQuery, RoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleQuery, RoleQueryVariables>(RoleDocument, options);
        }
export type RoleQueryHookResult = ReturnType<typeof useRoleQuery>;
export type RoleLazyQueryHookResult = ReturnType<typeof useRoleLazyQuery>;
export type RoleQueryResult = Apollo.QueryResult<RoleQuery, RoleQueryVariables>;
export const DocumentsDocument = gql`
    query documents($chatbotId: UUID!) {
  documents(chatbotId: $chatbotId) {
    id
    created
    updated
    chatbot {
      id
    }
    fileType
    fileName
    summary
    file
    questionPairs
  }
}
    `;

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *      chatbotId: // value for 'chatbotId'
 *   },
 * });
 */
export function useDocumentsQuery(baseOptions: Apollo.QueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, options);
      }
export function useDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, options);
        }
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsLazyQueryHookResult = ReturnType<typeof useDocumentsLazyQuery>;
export type DocumentsQueryResult = Apollo.QueryResult<DocumentsQuery, DocumentsQueryVariables>;
export const DeleteDocumentDocument = gql`
    mutation deleteDocument($id: UUID!) {
  deleteDocument(id: $id) {
    success
  }
}
    `;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const CreateDocumentDocument = gql`
    mutation createDocument($chatbotId: UUID!, $file: Upload!) {
  createDocument(chatbotId: $chatbotId, file: $file) {
    document {
      id
      chatbot {
        id
      }
      file
    }
  }
}
    `;
export type CreateDocumentMutationFn = Apollo.MutationFunction<CreateDocumentMutation, CreateDocumentMutationVariables>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      chatbotId: // value for 'chatbotId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentMutation, CreateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(CreateDocumentDocument, options);
      }
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = Apollo.MutationResult<CreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const UpdateChatbotDocument = gql`
    mutation updateChatbot($id: UUID!, $chatbotData: ChatbotInput!) {
  updateChatbot(id: $id, chatbotData: $chatbotData) {
    chatbot {
      id
      chatboxTitle
      welcomeMessage
      description
      chatbotChannels {
        ...ChannelFields
      }
      personalised
      extractionAgent
    }
  }
}
    ${ChannelFieldsFragmentDoc}`;
export type UpdateChatbotMutationFn = Apollo.MutationFunction<UpdateChatbotMutation, UpdateChatbotMutationVariables>;

/**
 * __useUpdateChatbotMutation__
 *
 * To run a mutation, you first call `useUpdateChatbotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatbotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatbotMutation, { data, loading, error }] = useUpdateChatbotMutation({
 *   variables: {
 *      id: // value for 'id'
 *      chatbotData: // value for 'chatbotData'
 *   },
 * });
 */
export function useUpdateChatbotMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChatbotMutation, UpdateChatbotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChatbotMutation, UpdateChatbotMutationVariables>(UpdateChatbotDocument, options);
      }
export type UpdateChatbotMutationHookResult = ReturnType<typeof useUpdateChatbotMutation>;
export type UpdateChatbotMutationResult = Apollo.MutationResult<UpdateChatbotMutation>;
export type UpdateChatbotMutationOptions = Apollo.BaseMutationOptions<UpdateChatbotMutation, UpdateChatbotMutationVariables>;
export const DeleteChatbotDocument = gql`
    mutation deleteChatbot($id: UUID!) {
  deleteChatbot(id: $id) {
    id
  }
}
    `;
export type DeleteChatbotMutationFn = Apollo.MutationFunction<DeleteChatbotMutation, DeleteChatbotMutationVariables>;

/**
 * __useDeleteChatbotMutation__
 *
 * To run a mutation, you first call `useDeleteChatbotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChatbotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChatbotMutation, { data, loading, error }] = useDeleteChatbotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChatbotMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChatbotMutation, DeleteChatbotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChatbotMutation, DeleteChatbotMutationVariables>(DeleteChatbotDocument, options);
      }
export type DeleteChatbotMutationHookResult = ReturnType<typeof useDeleteChatbotMutation>;
export type DeleteChatbotMutationResult = Apollo.MutationResult<DeleteChatbotMutation>;
export type DeleteChatbotMutationOptions = Apollo.BaseMutationOptions<DeleteChatbotMutation, DeleteChatbotMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($id: UUID, $name: String, $isActive: Boolean, $isOwner: Boolean) {
  updateCustomer(id: $id, name: $name, isActive: $isActive, isOwner: $isOwner) {
    customer {
      id
      email
      name
      isOwner
      organization {
        id
      }
      dateJoined
    }
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      isActive: // value for 'isActive'
 *      isOwner: // value for 'isOwner'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const DeleteCustomerDocument = gql`
    mutation deleteCustomer($id: UUID!) {
  deleteCustomer(id: $id) {
    id
  }
}
    `;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<DeleteCustomerMutation, DeleteCustomerMutationVariables>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, options);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const ChannelsDocument = gql`
    query channels($chatbotId: UUID!, $organizationId: String) {
  channels(chatbotId: $chatbotId, organizationId: $organizationId) {
    ...ChannelFields
  }
}
    ${ChannelFieldsFragmentDoc}`;

/**
 * __useChannelsQuery__
 *
 * To run a query within a React component, call `useChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsQuery({
 *   variables: {
 *      chatbotId: // value for 'chatbotId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useChannelsQuery(baseOptions: Apollo.QueryHookOptions<ChannelsQuery, ChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelsQuery, ChannelsQueryVariables>(ChannelsDocument, options);
      }
export function useChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelsQuery, ChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelsQuery, ChannelsQueryVariables>(ChannelsDocument, options);
        }
export type ChannelsQueryHookResult = ReturnType<typeof useChannelsQuery>;
export type ChannelsLazyQueryHookResult = ReturnType<typeof useChannelsLazyQuery>;
export type ChannelsQueryResult = Apollo.QueryResult<ChannelsQuery, ChannelsQueryVariables>;
export const ChannelDocument = gql`
    query channel($id: UUID!, $chatbotId: UUID!, $organizationId: String) {
  channel(id: $id, chatbotId: $chatbotId, organizationId: $organizationId) {
    ...ChannelFields
  }
}
    ${ChannelFieldsFragmentDoc}`;

/**
 * __useChannelQuery__
 *
 * To run a query within a React component, call `useChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelQuery({
 *   variables: {
 *      id: // value for 'id'
 *      chatbotId: // value for 'chatbotId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useChannelQuery(baseOptions: Apollo.QueryHookOptions<ChannelQuery, ChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelQuery, ChannelQueryVariables>(ChannelDocument, options);
      }
export function useChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelQuery, ChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelQuery, ChannelQueryVariables>(ChannelDocument, options);
        }
export type ChannelQueryHookResult = ReturnType<typeof useChannelQuery>;
export type ChannelLazyQueryHookResult = ReturnType<typeof useChannelLazyQuery>;
export type ChannelQueryResult = Apollo.QueryResult<ChannelQuery, ChannelQueryVariables>;
export const OrganizationsDocument = gql`
    query organizations {
  organizations {
    id
    name
    timezone
    priceTierType
    numberOfChatbots
    monthlyQuestionsLimit
    storageLimit
    priceTierConfig {
      numberOfChatbots
      numberOfFilePerBot
      fileSizeLimit
      storageLimit
      monthlyQuestionsLimit
      chabotBranding {
        logo
        colors
      }
      acl
      price
    }
  }
}
    `;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
      }
export function useOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
        }
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;
export type OrganizationsQueryResult = Apollo.QueryResult<OrganizationsQuery, OrganizationsQueryVariables>;
export const OrganizationCustomersDocument = gql`
    query organizationCustomers($organizationId: UUID!) {
  organizationCustomers(organizationId: $organizationId) {
    email
    name
    id
    dateJoined
    isActive
    isOwner
    organization {
      name
      timezone
      id
    }
    role {
      id
      role
    }
  }
}
    `;

/**
 * __useOrganizationCustomersQuery__
 *
 * To run a query within a React component, call `useOrganizationCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationCustomersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationCustomersQuery(baseOptions: Apollo.QueryHookOptions<OrganizationCustomersQuery, OrganizationCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationCustomersQuery, OrganizationCustomersQueryVariables>(OrganizationCustomersDocument, options);
      }
export function useOrganizationCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationCustomersQuery, OrganizationCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationCustomersQuery, OrganizationCustomersQueryVariables>(OrganizationCustomersDocument, options);
        }
export type OrganizationCustomersQueryHookResult = ReturnType<typeof useOrganizationCustomersQuery>;
export type OrganizationCustomersLazyQueryHookResult = ReturnType<typeof useOrganizationCustomersLazyQuery>;
export type OrganizationCustomersQueryResult = Apollo.QueryResult<OrganizationCustomersQuery, OrganizationCustomersQueryVariables>;
export const OrganizationStatsDocument = gql`
    query organizationStats($organizationId: UUID!) {
  organization(id: $organizationId) {
    id
    numberOfChatbots
    monthlyQuestionsLimit
    storageLimit
    priceTierConfig {
      numberOfChatbots
      numberOfFilePerBot
      fileSizeLimit
      storageLimit
      monthlyQuestionsLimit
      chabotBranding {
        logo
        colors
      }
      acl
      price
    }
  }
}
    `;

/**
 * __useOrganizationStatsQuery__
 *
 * To run a query within a React component, call `useOrganizationStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationStatsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationStatsQuery(baseOptions: Apollo.QueryHookOptions<OrganizationStatsQuery, OrganizationStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationStatsQuery, OrganizationStatsQueryVariables>(OrganizationStatsDocument, options);
      }
export function useOrganizationStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationStatsQuery, OrganizationStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationStatsQuery, OrganizationStatsQueryVariables>(OrganizationStatsDocument, options);
        }
export type OrganizationStatsQueryHookResult = ReturnType<typeof useOrganizationStatsQuery>;
export type OrganizationStatsLazyQueryHookResult = ReturnType<typeof useOrganizationStatsLazyQuery>;
export type OrganizationStatsQueryResult = Apollo.QueryResult<OrganizationStatsQuery, OrganizationStatsQueryVariables>;
export const OrganizationStatsNumberOfChatbotDocument = gql`
    query organizationStatsNumberOfChatbot($organizationId: UUID!) {
  organization(id: $organizationId) {
    id
    numberOfChatbots
  }
}
    `;

/**
 * __useOrganizationStatsNumberOfChatbotQuery__
 *
 * To run a query within a React component, call `useOrganizationStatsNumberOfChatbotQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationStatsNumberOfChatbotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationStatsNumberOfChatbotQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationStatsNumberOfChatbotQuery(baseOptions: Apollo.QueryHookOptions<OrganizationStatsNumberOfChatbotQuery, OrganizationStatsNumberOfChatbotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationStatsNumberOfChatbotQuery, OrganizationStatsNumberOfChatbotQueryVariables>(OrganizationStatsNumberOfChatbotDocument, options);
      }
export function useOrganizationStatsNumberOfChatbotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationStatsNumberOfChatbotQuery, OrganizationStatsNumberOfChatbotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationStatsNumberOfChatbotQuery, OrganizationStatsNumberOfChatbotQueryVariables>(OrganizationStatsNumberOfChatbotDocument, options);
        }
export type OrganizationStatsNumberOfChatbotQueryHookResult = ReturnType<typeof useOrganizationStatsNumberOfChatbotQuery>;
export type OrganizationStatsNumberOfChatbotLazyQueryHookResult = ReturnType<typeof useOrganizationStatsNumberOfChatbotLazyQuery>;
export type OrganizationStatsNumberOfChatbotQueryResult = Apollo.QueryResult<OrganizationStatsNumberOfChatbotQuery, OrganizationStatsNumberOfChatbotQueryVariables>;
export const OrganizationStatsMonthlyQuestionsLimitDocument = gql`
    query organizationStatsMonthlyQuestionsLimit($organizationId: UUID!) {
  organization(id: $organizationId) {
    id
    monthlyQuestionsLimit
  }
}
    `;

/**
 * __useOrganizationStatsMonthlyQuestionsLimitQuery__
 *
 * To run a query within a React component, call `useOrganizationStatsMonthlyQuestionsLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationStatsMonthlyQuestionsLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationStatsMonthlyQuestionsLimitQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationStatsMonthlyQuestionsLimitQuery(baseOptions: Apollo.QueryHookOptions<OrganizationStatsMonthlyQuestionsLimitQuery, OrganizationStatsMonthlyQuestionsLimitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationStatsMonthlyQuestionsLimitQuery, OrganizationStatsMonthlyQuestionsLimitQueryVariables>(OrganizationStatsMonthlyQuestionsLimitDocument, options);
      }
export function useOrganizationStatsMonthlyQuestionsLimitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationStatsMonthlyQuestionsLimitQuery, OrganizationStatsMonthlyQuestionsLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationStatsMonthlyQuestionsLimitQuery, OrganizationStatsMonthlyQuestionsLimitQueryVariables>(OrganizationStatsMonthlyQuestionsLimitDocument, options);
        }
export type OrganizationStatsMonthlyQuestionsLimitQueryHookResult = ReturnType<typeof useOrganizationStatsMonthlyQuestionsLimitQuery>;
export type OrganizationStatsMonthlyQuestionsLimitLazyQueryHookResult = ReturnType<typeof useOrganizationStatsMonthlyQuestionsLimitLazyQuery>;
export type OrganizationStatsMonthlyQuestionsLimitQueryResult = Apollo.QueryResult<OrganizationStatsMonthlyQuestionsLimitQuery, OrganizationStatsMonthlyQuestionsLimitQueryVariables>;
export const OrganizationStatsStorageLimitDocument = gql`
    query organizationStatsStorageLimit($organizationId: UUID!) {
  organization(id: $organizationId) {
    id
    storageLimit
  }
}
    `;

/**
 * __useOrganizationStatsStorageLimitQuery__
 *
 * To run a query within a React component, call `useOrganizationStatsStorageLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationStatsStorageLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationStatsStorageLimitQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationStatsStorageLimitQuery(baseOptions: Apollo.QueryHookOptions<OrganizationStatsStorageLimitQuery, OrganizationStatsStorageLimitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationStatsStorageLimitQuery, OrganizationStatsStorageLimitQueryVariables>(OrganizationStatsStorageLimitDocument, options);
      }
export function useOrganizationStatsStorageLimitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationStatsStorageLimitQuery, OrganizationStatsStorageLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationStatsStorageLimitQuery, OrganizationStatsStorageLimitQueryVariables>(OrganizationStatsStorageLimitDocument, options);
        }
export type OrganizationStatsStorageLimitQueryHookResult = ReturnType<typeof useOrganizationStatsStorageLimitQuery>;
export type OrganizationStatsStorageLimitLazyQueryHookResult = ReturnType<typeof useOrganizationStatsStorageLimitLazyQuery>;
export type OrganizationStatsStorageLimitQueryResult = Apollo.QueryResult<OrganizationStatsStorageLimitQuery, OrganizationStatsStorageLimitQueryVariables>;
export const MeDocument = gql`
    query me($organizationId: String) {
  me(organizationId: $organizationId) {
    email
    name
    id
    dateJoined
    isActive
    isOwner
    isMaster
    organization {
      id
      name
      numberOfChatbots
      monthlyQuestionsLimit
      storageLimit
      timezone
      priceTierConfig {
        numberOfChatbots
        numberOfFilePerBot
        fileSizeLimit
        storageLimit
        monthlyQuestionsLimit
        chabotBranding {
          logo
          colors
        }
        acl
        price
      }
      priceTierType
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ChatbotListDocument = gql`
    query chatbotList($dateFrom: Date, $dateTo: Date, $organizationId: String) {
  chatbots(dateFrom: $dateFrom, dateTo: $dateTo, organizationId: $organizationId) {
    id
    chatboxTitle
    welcomeMessage
    description
    created
    updated
    chatbotChannels {
      ...ChannelFields
    }
    organizationId
  }
}
    ${ChannelFieldsFragmentDoc}`;

/**
 * __useChatbotListQuery__
 *
 * To run a query within a React component, call `useChatbotListQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatbotListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatbotListQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useChatbotListQuery(baseOptions?: Apollo.QueryHookOptions<ChatbotListQuery, ChatbotListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatbotListQuery, ChatbotListQueryVariables>(ChatbotListDocument, options);
      }
export function useChatbotListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatbotListQuery, ChatbotListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatbotListQuery, ChatbotListQueryVariables>(ChatbotListDocument, options);
        }
export type ChatbotListQueryHookResult = ReturnType<typeof useChatbotListQuery>;
export type ChatbotListLazyQueryHookResult = ReturnType<typeof useChatbotListLazyQuery>;
export type ChatbotListQueryResult = Apollo.QueryResult<ChatbotListQuery, ChatbotListQueryVariables>;
export const GetOneChatbotDocument = gql`
    query getOneChatbot($id: UUID!, $organizationId: String) {
  chatbot(id: $id, organizationId: $organizationId) {
    id
    chatboxTitle
    welcomeMessage
    description
    created
    updated
    chatbotChannels {
      ...ChannelFields
    }
    personalised
    extractionAgent
    organizationId
    organization {
      id
      name
    }
    chatbotChannels {
      ...ChannelFields
      channelProfile {
        ...ChannelProfileFields
      }
    }
    documents {
      id
      file
      summary
      fileType
      fileName
      questionPairs
    }
    chatbotSqlConfig {
      ...SQLConfigFields
    }
    user {
      id
      email
      name
      isOwner
    }
    chatbotOpensearchConfig {
      ...OpenSearchConfigFields
    }
    chatbotHelperQuestions {
      ...HelperQuestionsFields
    }
  }
}
    ${ChannelFieldsFragmentDoc}
${ChannelProfileFieldsFragmentDoc}
${SqlConfigFieldsFragmentDoc}
${OpenSearchConfigFieldsFragmentDoc}
${HelperQuestionsFieldsFragmentDoc}`;

/**
 * __useGetOneChatbotQuery__
 *
 * To run a query within a React component, call `useGetOneChatbotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneChatbotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneChatbotQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOneChatbotQuery(baseOptions: Apollo.QueryHookOptions<GetOneChatbotQuery, GetOneChatbotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneChatbotQuery, GetOneChatbotQueryVariables>(GetOneChatbotDocument, options);
      }
export function useGetOneChatbotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneChatbotQuery, GetOneChatbotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneChatbotQuery, GetOneChatbotQueryVariables>(GetOneChatbotDocument, options);
        }
export type GetOneChatbotQueryHookResult = ReturnType<typeof useGetOneChatbotQuery>;
export type GetOneChatbotLazyQueryHookResult = ReturnType<typeof useGetOneChatbotLazyQuery>;
export type GetOneChatbotQueryResult = Apollo.QueryResult<GetOneChatbotQuery, GetOneChatbotQueryVariables>;
export const ChatbotDocument = gql`
    query chatbot($dateFrom: Date, $dateTo: Date, $organizationId: String) {
  chatbots(dateFrom: $dateFrom, dateTo: $dateTo, organizationId: $organizationId) {
    id
    chatboxTitle
    welcomeMessage
    description
    created
    updated
    chatbotChannels {
      ...ChannelFields
      channelProfile {
        ...ChannelProfileFields
      }
    }
    personalised
    extractionAgent
    organization {
      id
      name
    }
    documents {
      id
      file
      summary
      fileType
      fileName
      questionPairs
    }
    chatbotSqlConfig {
      ...SQLConfigFields
    }
    user {
      id
      email
      name
      isOwner
    }
    chatbotOpensearchConfig {
      ...OpenSearchConfigFields
    }
    chatbotHelperQuestions {
      ...HelperQuestionsFields
    }
  }
}
    ${ChannelFieldsFragmentDoc}
${ChannelProfileFieldsFragmentDoc}
${SqlConfigFieldsFragmentDoc}
${OpenSearchConfigFieldsFragmentDoc}
${HelperQuestionsFieldsFragmentDoc}`;

/**
 * __useChatbotQuery__
 *
 * To run a query within a React component, call `useChatbotQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatbotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatbotQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useChatbotQuery(baseOptions?: Apollo.QueryHookOptions<ChatbotQuery, ChatbotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatbotQuery, ChatbotQueryVariables>(ChatbotDocument, options);
      }
export function useChatbotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatbotQuery, ChatbotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatbotQuery, ChatbotQueryVariables>(ChatbotDocument, options);
        }
export type ChatbotQueryHookResult = ReturnType<typeof useChatbotQuery>;
export type ChatbotLazyQueryHookResult = ReturnType<typeof useChatbotLazyQuery>;
export type ChatbotQueryResult = Apollo.QueryResult<ChatbotQuery, ChatbotQueryVariables>;
export const MessagesDocument = gql`
    query messages {
  messages {
    id
    created
    updated
    text
    author
    conversation {
      id
    }
  }
}
    `;

/**
 * __useMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessagesQuery(baseOptions?: Apollo.QueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
      }
export function useMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
        }
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<typeof useMessagesLazyQuery>;
export type MessagesQueryResult = Apollo.QueryResult<MessagesQuery, MessagesQueryVariables>;
export const ConversationsDocument = gql`
    query conversations {
  conversations {
    id
    created
    updated
    extractedData
    state
    messageConversations {
      id
      created
      text
      author
    }
  }
}
    `;

/**
 * __useConversationsQuery__
 *
 * To run a query within a React component, call `useConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConversationsQuery(baseOptions?: Apollo.QueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
      }
export function useConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
        }
export type ConversationsQueryHookResult = ReturnType<typeof useConversationsQuery>;
export type ConversationsLazyQueryHookResult = ReturnType<typeof useConversationsLazyQuery>;
export type ConversationsQueryResult = Apollo.QueryResult<ConversationsQuery, ConversationsQueryVariables>;
export const OrganizationDocument = gql`
    query organization($id: UUID!) {
  organization(id: $id) {
    id
    name
    timezone
    priceTierType
  }
}
    `;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(baseOptions: Apollo.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
      }
export function useOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
        }
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationQueryResult = Apollo.QueryResult<OrganizationQuery, OrganizationQueryVariables>;
export const MostFrequentlyQuestionsDocument = gql`
    query mostFrequentlyQuestions($chatbotId: UUID!) {
  mostFrequentlyQuestions(chatbotId: $chatbotId) {
    questions {
      question
      count
    }
    total
  }
}
    `;

/**
 * __useMostFrequentlyQuestionsQuery__
 *
 * To run a query within a React component, call `useMostFrequentlyQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMostFrequentlyQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMostFrequentlyQuestionsQuery({
 *   variables: {
 *      chatbotId: // value for 'chatbotId'
 *   },
 * });
 */
export function useMostFrequentlyQuestionsQuery(baseOptions: Apollo.QueryHookOptions<MostFrequentlyQuestionsQuery, MostFrequentlyQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MostFrequentlyQuestionsQuery, MostFrequentlyQuestionsQueryVariables>(MostFrequentlyQuestionsDocument, options);
      }
export function useMostFrequentlyQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MostFrequentlyQuestionsQuery, MostFrequentlyQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MostFrequentlyQuestionsQuery, MostFrequentlyQuestionsQueryVariables>(MostFrequentlyQuestionsDocument, options);
        }
export type MostFrequentlyQuestionsQueryHookResult = ReturnType<typeof useMostFrequentlyQuestionsQuery>;
export type MostFrequentlyQuestionsLazyQueryHookResult = ReturnType<typeof useMostFrequentlyQuestionsLazyQuery>;
export type MostFrequentlyQuestionsQueryResult = Apollo.QueryResult<MostFrequentlyQuestionsQuery, MostFrequentlyQuestionsQueryVariables>;
export const ResponseTimeDocument = gql`
    query responseTime($chatbotId: UUID!) {
  responseTime(chatbotId: $chatbotId) {
    avg
    fastest
    longest
  }
}
    `;

/**
 * __useResponseTimeQuery__
 *
 * To run a query within a React component, call `useResponseTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useResponseTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResponseTimeQuery({
 *   variables: {
 *      chatbotId: // value for 'chatbotId'
 *   },
 * });
 */
export function useResponseTimeQuery(baseOptions: Apollo.QueryHookOptions<ResponseTimeQuery, ResponseTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResponseTimeQuery, ResponseTimeQueryVariables>(ResponseTimeDocument, options);
      }
export function useResponseTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResponseTimeQuery, ResponseTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResponseTimeQuery, ResponseTimeQueryVariables>(ResponseTimeDocument, options);
        }
export type ResponseTimeQueryHookResult = ReturnType<typeof useResponseTimeQuery>;
export type ResponseTimeLazyQueryHookResult = ReturnType<typeof useResponseTimeLazyQuery>;
export type ResponseTimeQueryResult = Apollo.QueryResult<ResponseTimeQuery, ResponseTimeQueryVariables>;
export const UserAndConversationDocument = gql`
    query userAndConversation($chatbotId: UUID!) {
  userAndConversation(chatbotId: $chatbotId) {
    uniqueUsersCount
    conversationsCount
    avgMessagesPerConversation
    avgConversationsPerUser
  }
}
    `;

/**
 * __useUserAndConversationQuery__
 *
 * To run a query within a React component, call `useUserAndConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAndConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAndConversationQuery({
 *   variables: {
 *      chatbotId: // value for 'chatbotId'
 *   },
 * });
 */
export function useUserAndConversationQuery(baseOptions: Apollo.QueryHookOptions<UserAndConversationQuery, UserAndConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAndConversationQuery, UserAndConversationQueryVariables>(UserAndConversationDocument, options);
      }
export function useUserAndConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAndConversationQuery, UserAndConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAndConversationQuery, UserAndConversationQueryVariables>(UserAndConversationDocument, options);
        }
export type UserAndConversationQueryHookResult = ReturnType<typeof useUserAndConversationQuery>;
export type UserAndConversationLazyQueryHookResult = ReturnType<typeof useUserAndConversationLazyQuery>;
export type UserAndConversationQueryResult = Apollo.QueryResult<UserAndConversationQuery, UserAndConversationQueryVariables>;
export const GenerateReportConversationDocument = gql`
    query generateReportConversation($chatbotId: UUID!) {
  generateReportConversation(chatbotId: $chatbotId)
}
    `;

/**
 * __useGenerateReportConversationQuery__
 *
 * To run a query within a React component, call `useGenerateReportConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateReportConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateReportConversationQuery({
 *   variables: {
 *      chatbotId: // value for 'chatbotId'
 *   },
 * });
 */
export function useGenerateReportConversationQuery(baseOptions: Apollo.QueryHookOptions<GenerateReportConversationQuery, GenerateReportConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateReportConversationQuery, GenerateReportConversationQueryVariables>(GenerateReportConversationDocument, options);
      }
export function useGenerateReportConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateReportConversationQuery, GenerateReportConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateReportConversationQuery, GenerateReportConversationQueryVariables>(GenerateReportConversationDocument, options);
        }
export type GenerateReportConversationQueryHookResult = ReturnType<typeof useGenerateReportConversationQuery>;
export type GenerateReportConversationLazyQueryHookResult = ReturnType<typeof useGenerateReportConversationLazyQuery>;
export type GenerateReportConversationQueryResult = Apollo.QueryResult<GenerateReportConversationQuery, GenerateReportConversationQueryVariables>;
export const ChatbotUserConversationDocument = gql`
    query chatbotUserConversation($chatbotId: UUID!, $page: Int, $pageSize: Int) {
  chatbotUserConversation(chatbotId: $chatbotId, page: $page, pageSize: $pageSize) {
    totalCount
    data {
      id
      name
      email
      conversationChatbotUsers {
        id
        created
        updated
        messages {
          id
          text
          author
          updated
          rating
          userFeedback
        }
      }
    }
  }
}
    `;

/**
 * __useChatbotUserConversationQuery__
 *
 * To run a query within a React component, call `useChatbotUserConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatbotUserConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatbotUserConversationQuery({
 *   variables: {
 *      chatbotId: // value for 'chatbotId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useChatbotUserConversationQuery(baseOptions: Apollo.QueryHookOptions<ChatbotUserConversationQuery, ChatbotUserConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatbotUserConversationQuery, ChatbotUserConversationQueryVariables>(ChatbotUserConversationDocument, options);
      }
export function useChatbotUserConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatbotUserConversationQuery, ChatbotUserConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatbotUserConversationQuery, ChatbotUserConversationQueryVariables>(ChatbotUserConversationDocument, options);
        }
export type ChatbotUserConversationQueryHookResult = ReturnType<typeof useChatbotUserConversationQuery>;
export type ChatbotUserConversationLazyQueryHookResult = ReturnType<typeof useChatbotUserConversationLazyQuery>;
export type ChatbotUserConversationQueryResult = Apollo.QueryResult<ChatbotUserConversationQuery, ChatbotUserConversationQueryVariables>;
export const SqlConfigsDocument = gql`
    query sqlConfigs($chatbotId: UUID!) {
  sqlConfigs(chatbotId: $chatbotId) {
    ...SQLConfigFields
  }
}
    ${SqlConfigFieldsFragmentDoc}`;

/**
 * __useSqlConfigsQuery__
 *
 * To run a query within a React component, call `useSqlConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSqlConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSqlConfigsQuery({
 *   variables: {
 *      chatbotId: // value for 'chatbotId'
 *   },
 * });
 */
export function useSqlConfigsQuery(baseOptions: Apollo.QueryHookOptions<SqlConfigsQuery, SqlConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SqlConfigsQuery, SqlConfigsQueryVariables>(SqlConfigsDocument, options);
      }
export function useSqlConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SqlConfigsQuery, SqlConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SqlConfigsQuery, SqlConfigsQueryVariables>(SqlConfigsDocument, options);
        }
export type SqlConfigsQueryHookResult = ReturnType<typeof useSqlConfigsQuery>;
export type SqlConfigsLazyQueryHookResult = ReturnType<typeof useSqlConfigsLazyQuery>;
export type SqlConfigsQueryResult = Apollo.QueryResult<SqlConfigsQuery, SqlConfigsQueryVariables>;
export const HelperQuestionsDocument = gql`
    query helperQuestions($chatbotId: UUID!) {
  helperQuestions(chatbotId: $chatbotId) {
    ...HelperQuestionsFields
  }
}
    ${HelperQuestionsFieldsFragmentDoc}`;

/**
 * __useHelperQuestionsQuery__
 *
 * To run a query within a React component, call `useHelperQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelperQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelperQuestionsQuery({
 *   variables: {
 *      chatbotId: // value for 'chatbotId'
 *   },
 * });
 */
export function useHelperQuestionsQuery(baseOptions: Apollo.QueryHookOptions<HelperQuestionsQuery, HelperQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HelperQuestionsQuery, HelperQuestionsQueryVariables>(HelperQuestionsDocument, options);
      }
export function useHelperQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HelperQuestionsQuery, HelperQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HelperQuestionsQuery, HelperQuestionsQueryVariables>(HelperQuestionsDocument, options);
        }
export type HelperQuestionsQueryHookResult = ReturnType<typeof useHelperQuestionsQuery>;
export type HelperQuestionsLazyQueryHookResult = ReturnType<typeof useHelperQuestionsLazyQuery>;
export type HelperQuestionsQueryResult = Apollo.QueryResult<HelperQuestionsQuery, HelperQuestionsQueryVariables>;
export const OpensearchConfigsDocument = gql`
    query opensearchConfigs($chatbotId: UUID!) {
  opensearchConfigs(chatbotId: $chatbotId) {
    ...OpenSearchConfigFields
  }
}
    ${OpenSearchConfigFieldsFragmentDoc}`;

/**
 * __useOpensearchConfigsQuery__
 *
 * To run a query within a React component, call `useOpensearchConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpensearchConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpensearchConfigsQuery({
 *   variables: {
 *      chatbotId: // value for 'chatbotId'
 *   },
 * });
 */
export function useOpensearchConfigsQuery(baseOptions: Apollo.QueryHookOptions<OpensearchConfigsQuery, OpensearchConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OpensearchConfigsQuery, OpensearchConfigsQueryVariables>(OpensearchConfigsDocument, options);
      }
export function useOpensearchConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpensearchConfigsQuery, OpensearchConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OpensearchConfigsQuery, OpensearchConfigsQueryVariables>(OpensearchConfigsDocument, options);
        }
export type OpensearchConfigsQueryHookResult = ReturnType<typeof useOpensearchConfigsQuery>;
export type OpensearchConfigsLazyQueryHookResult = ReturnType<typeof useOpensearchConfigsLazyQuery>;
export type OpensearchConfigsQueryResult = Apollo.QueryResult<OpensearchConfigsQuery, OpensearchConfigsQueryVariables>;
export const CreateSqlConfigDocument = gql`
    mutation createSqlConfig($chatbotId: UUID!, $dbDescription: String!, $dbHost: String!, $dbName: String!, $dbPort: String!, $dbUser: String!, $dbPass: String!, $sqlType: String!, $dbListTables: String!) {
  createSqlConfig(
    chatbotId: $chatbotId
    dbDescription: $dbDescription
    dbHost: $dbHost
    dbName: $dbName
    dbPort: $dbPort
    dbUser: $dbUser
    dbPass: $dbPass
    sqlType: $sqlType
    dbListTables: $dbListTables
  ) {
    sqlConfig {
      ...SQLConfigFields
    }
  }
}
    ${SqlConfigFieldsFragmentDoc}`;
export type CreateSqlConfigMutationFn = Apollo.MutationFunction<CreateSqlConfigMutation, CreateSqlConfigMutationVariables>;

/**
 * __useCreateSqlConfigMutation__
 *
 * To run a mutation, you first call `useCreateSqlConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSqlConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSqlConfigMutation, { data, loading, error }] = useCreateSqlConfigMutation({
 *   variables: {
 *      chatbotId: // value for 'chatbotId'
 *      dbDescription: // value for 'dbDescription'
 *      dbHost: // value for 'dbHost'
 *      dbName: // value for 'dbName'
 *      dbPort: // value for 'dbPort'
 *      dbUser: // value for 'dbUser'
 *      dbPass: // value for 'dbPass'
 *      sqlType: // value for 'sqlType'
 *      dbListTables: // value for 'dbListTables'
 *   },
 * });
 */
export function useCreateSqlConfigMutation(baseOptions?: Apollo.MutationHookOptions<CreateSqlConfigMutation, CreateSqlConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSqlConfigMutation, CreateSqlConfigMutationVariables>(CreateSqlConfigDocument, options);
      }
export type CreateSqlConfigMutationHookResult = ReturnType<typeof useCreateSqlConfigMutation>;
export type CreateSqlConfigMutationResult = Apollo.MutationResult<CreateSqlConfigMutation>;
export type CreateSqlConfigMutationOptions = Apollo.BaseMutationOptions<CreateSqlConfigMutation, CreateSqlConfigMutationVariables>;
export const CreateHelperQuestionsDocument = gql`
    mutation createHelperQuestions($id: UUID, $chatbotId: UUID!, $questions: JSONString!, $organizationId: String) {
  createHelperQuestions(
    id: $id
    chatbotId: $chatbotId
    questions: $questions
    organizationId: $organizationId
  ) {
    helperQuestions {
      ...HelperQuestionsFields
    }
  }
}
    ${HelperQuestionsFieldsFragmentDoc}`;
export type CreateHelperQuestionsMutationFn = Apollo.MutationFunction<CreateHelperQuestionsMutation, CreateHelperQuestionsMutationVariables>;

/**
 * __useCreateHelperQuestionsMutation__
 *
 * To run a mutation, you first call `useCreateHelperQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHelperQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHelperQuestionsMutation, { data, loading, error }] = useCreateHelperQuestionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      chatbotId: // value for 'chatbotId'
 *      questions: // value for 'questions'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateHelperQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateHelperQuestionsMutation, CreateHelperQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHelperQuestionsMutation, CreateHelperQuestionsMutationVariables>(CreateHelperQuestionsDocument, options);
      }
export type CreateHelperQuestionsMutationHookResult = ReturnType<typeof useCreateHelperQuestionsMutation>;
export type CreateHelperQuestionsMutationResult = Apollo.MutationResult<CreateHelperQuestionsMutation>;
export type CreateHelperQuestionsMutationOptions = Apollo.BaseMutationOptions<CreateHelperQuestionsMutation, CreateHelperQuestionsMutationVariables>;
export const CreateOpensearchConfigDocument = gql`
    mutation createOpensearchConfig($chatbotId: UUID!, $dbResponse: String!, $dbHost: String!, $dbName: String!, $dbPort: String!, $dbUser: String!, $dbPass: String!, $dbAdditionalResponse: String!) {
  createOpensearchConfig(
    chatbotId: $chatbotId
    dbResponse: $dbResponse
    dbHost: $dbHost
    dbName: $dbName
    dbPort: $dbPort
    dbUser: $dbUser
    dbPass: $dbPass
    dbAdditionalResponse: $dbAdditionalResponse
  ) {
    opensearchConfig {
      ...OpenSearchConfigFields
    }
  }
}
    ${OpenSearchConfigFieldsFragmentDoc}`;
export type CreateOpensearchConfigMutationFn = Apollo.MutationFunction<CreateOpensearchConfigMutation, CreateOpensearchConfigMutationVariables>;

/**
 * __useCreateOpensearchConfigMutation__
 *
 * To run a mutation, you first call `useCreateOpensearchConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpensearchConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpensearchConfigMutation, { data, loading, error }] = useCreateOpensearchConfigMutation({
 *   variables: {
 *      chatbotId: // value for 'chatbotId'
 *      dbResponse: // value for 'dbResponse'
 *      dbHost: // value for 'dbHost'
 *      dbName: // value for 'dbName'
 *      dbPort: // value for 'dbPort'
 *      dbUser: // value for 'dbUser'
 *      dbPass: // value for 'dbPass'
 *      dbAdditionalResponse: // value for 'dbAdditionalResponse'
 *   },
 * });
 */
export function useCreateOpensearchConfigMutation(baseOptions?: Apollo.MutationHookOptions<CreateOpensearchConfigMutation, CreateOpensearchConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOpensearchConfigMutation, CreateOpensearchConfigMutationVariables>(CreateOpensearchConfigDocument, options);
      }
export type CreateOpensearchConfigMutationHookResult = ReturnType<typeof useCreateOpensearchConfigMutation>;
export type CreateOpensearchConfigMutationResult = Apollo.MutationResult<CreateOpensearchConfigMutation>;
export type CreateOpensearchConfigMutationOptions = Apollo.BaseMutationOptions<CreateOpensearchConfigMutation, CreateOpensearchConfigMutationVariables>;
export const UpdateSqlConfigDocument = gql`
    mutation updateSqlConfig($id: UUID!, $chatbotId: UUID!, $dbDescription: String!, $dbHost: String!, $dbName: String!, $dbPort: String!, $dbUser: String!, $dbPass: String!, $sqlType: String!, $dbListTables: String!) {
  updateSqlConfig(
    id: $id
    chatbotId: $chatbotId
    dbDescription: $dbDescription
    dbHost: $dbHost
    dbName: $dbName
    dbPort: $dbPort
    dbUser: $dbUser
    dbPass: $dbPass
    sqlType: $sqlType
    dbListTables: $dbListTables
  ) {
    sqlConfig {
      ...SQLConfigFields
    }
  }
}
    ${SqlConfigFieldsFragmentDoc}`;
export type UpdateSqlConfigMutationFn = Apollo.MutationFunction<UpdateSqlConfigMutation, UpdateSqlConfigMutationVariables>;

/**
 * __useUpdateSqlConfigMutation__
 *
 * To run a mutation, you first call `useUpdateSqlConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSqlConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSqlConfigMutation, { data, loading, error }] = useUpdateSqlConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      chatbotId: // value for 'chatbotId'
 *      dbDescription: // value for 'dbDescription'
 *      dbHost: // value for 'dbHost'
 *      dbName: // value for 'dbName'
 *      dbPort: // value for 'dbPort'
 *      dbUser: // value for 'dbUser'
 *      dbPass: // value for 'dbPass'
 *      sqlType: // value for 'sqlType'
 *      dbListTables: // value for 'dbListTables'
 *   },
 * });
 */
export function useUpdateSqlConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSqlConfigMutation, UpdateSqlConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSqlConfigMutation, UpdateSqlConfigMutationVariables>(UpdateSqlConfigDocument, options);
      }
export type UpdateSqlConfigMutationHookResult = ReturnType<typeof useUpdateSqlConfigMutation>;
export type UpdateSqlConfigMutationResult = Apollo.MutationResult<UpdateSqlConfigMutation>;
export type UpdateSqlConfigMutationOptions = Apollo.BaseMutationOptions<UpdateSqlConfigMutation, UpdateSqlConfigMutationVariables>;
export const UpdateHelperQuestionsDocument = gql`
    mutation updateHelperQuestions($id: UUID!, $chatbotId: UUID!, $questions: JSONString!) {
  updateHelperQuestions(id: $id, chatbotId: $chatbotId, questions: $questions) {
    helperQuestions {
      ...HelperQuestionsFields
    }
  }
}
    ${HelperQuestionsFieldsFragmentDoc}`;
export type UpdateHelperQuestionsMutationFn = Apollo.MutationFunction<UpdateHelperQuestionsMutation, UpdateHelperQuestionsMutationVariables>;

/**
 * __useUpdateHelperQuestionsMutation__
 *
 * To run a mutation, you first call `useUpdateHelperQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHelperQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHelperQuestionsMutation, { data, loading, error }] = useUpdateHelperQuestionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      chatbotId: // value for 'chatbotId'
 *      questions: // value for 'questions'
 *   },
 * });
 */
export function useUpdateHelperQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHelperQuestionsMutation, UpdateHelperQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHelperQuestionsMutation, UpdateHelperQuestionsMutationVariables>(UpdateHelperQuestionsDocument, options);
      }
export type UpdateHelperQuestionsMutationHookResult = ReturnType<typeof useUpdateHelperQuestionsMutation>;
export type UpdateHelperQuestionsMutationResult = Apollo.MutationResult<UpdateHelperQuestionsMutation>;
export type UpdateHelperQuestionsMutationOptions = Apollo.BaseMutationOptions<UpdateHelperQuestionsMutation, UpdateHelperQuestionsMutationVariables>;
export const DeleteHelperQuestionsDocument = gql`
    mutation deleteHelperQuestions($id: UUID!, $chatbotId: UUID!) {
  deleteHelperQuestions(id: $id, chatbotId: $chatbotId) {
    success
  }
}
    `;
export type DeleteHelperQuestionsMutationFn = Apollo.MutationFunction<DeleteHelperQuestionsMutation, DeleteHelperQuestionsMutationVariables>;

/**
 * __useDeleteHelperQuestionsMutation__
 *
 * To run a mutation, you first call `useDeleteHelperQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHelperQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHelperQuestionsMutation, { data, loading, error }] = useDeleteHelperQuestionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      chatbotId: // value for 'chatbotId'
 *   },
 * });
 */
export function useDeleteHelperQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHelperQuestionsMutation, DeleteHelperQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHelperQuestionsMutation, DeleteHelperQuestionsMutationVariables>(DeleteHelperQuestionsDocument, options);
      }
export type DeleteHelperQuestionsMutationHookResult = ReturnType<typeof useDeleteHelperQuestionsMutation>;
export type DeleteHelperQuestionsMutationResult = Apollo.MutationResult<DeleteHelperQuestionsMutation>;
export type DeleteHelperQuestionsMutationOptions = Apollo.BaseMutationOptions<DeleteHelperQuestionsMutation, DeleteHelperQuestionsMutationVariables>;
export const UpdateOpensearchConfigDocument = gql`
    mutation updateOpensearchConfig($id: UUID!, $chatbotId: UUID!, $dbResponse: String!, $dbHost: String!, $dbName: String!, $dbPort: String!, $dbUser: String!, $dbPass: String!, $dbAdditionalResponse: String!) {
  updateOpensearchConfig(
    id: $id
    chatbotId: $chatbotId
    dbResponse: $dbResponse
    dbHost: $dbHost
    dbName: $dbName
    dbPort: $dbPort
    dbUser: $dbUser
    dbPass: $dbPass
    dbAdditionalResponse: $dbAdditionalResponse
  ) {
    opensearchConfig {
      ...OpenSearchConfigFields
    }
  }
}
    ${OpenSearchConfigFieldsFragmentDoc}`;
export type UpdateOpensearchConfigMutationFn = Apollo.MutationFunction<UpdateOpensearchConfigMutation, UpdateOpensearchConfigMutationVariables>;

/**
 * __useUpdateOpensearchConfigMutation__
 *
 * To run a mutation, you first call `useUpdateOpensearchConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpensearchConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpensearchConfigMutation, { data, loading, error }] = useUpdateOpensearchConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      chatbotId: // value for 'chatbotId'
 *      dbResponse: // value for 'dbResponse'
 *      dbHost: // value for 'dbHost'
 *      dbName: // value for 'dbName'
 *      dbPort: // value for 'dbPort'
 *      dbUser: // value for 'dbUser'
 *      dbPass: // value for 'dbPass'
 *      dbAdditionalResponse: // value for 'dbAdditionalResponse'
 *   },
 * });
 */
export function useUpdateOpensearchConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOpensearchConfigMutation, UpdateOpensearchConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOpensearchConfigMutation, UpdateOpensearchConfigMutationVariables>(UpdateOpensearchConfigDocument, options);
      }
export type UpdateOpensearchConfigMutationHookResult = ReturnType<typeof useUpdateOpensearchConfigMutation>;
export type UpdateOpensearchConfigMutationResult = Apollo.MutationResult<UpdateOpensearchConfigMutation>;
export type UpdateOpensearchConfigMutationOptions = Apollo.BaseMutationOptions<UpdateOpensearchConfigMutation, UpdateOpensearchConfigMutationVariables>;
export const RemoveChannelProfileLogoDocument = gql`
    mutation removeChannelProfileLogo($id: UUID!) {
  removeChannelProfileLogo(id: $id) {
    id
  }
}
    `;
export type RemoveChannelProfileLogoMutationFn = Apollo.MutationFunction<RemoveChannelProfileLogoMutation, RemoveChannelProfileLogoMutationVariables>;

/**
 * __useRemoveChannelProfileLogoMutation__
 *
 * To run a mutation, you first call `useRemoveChannelProfileLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChannelProfileLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChannelProfileLogoMutation, { data, loading, error }] = useRemoveChannelProfileLogoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveChannelProfileLogoMutation(baseOptions?: Apollo.MutationHookOptions<RemoveChannelProfileLogoMutation, RemoveChannelProfileLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveChannelProfileLogoMutation, RemoveChannelProfileLogoMutationVariables>(RemoveChannelProfileLogoDocument, options);
      }
export type RemoveChannelProfileLogoMutationHookResult = ReturnType<typeof useRemoveChannelProfileLogoMutation>;
export type RemoveChannelProfileLogoMutationResult = Apollo.MutationResult<RemoveChannelProfileLogoMutation>;
export type RemoveChannelProfileLogoMutationOptions = Apollo.BaseMutationOptions<RemoveChannelProfileLogoMutation, RemoveChannelProfileLogoMutationVariables>;
export const UpdateChannelProfileDocument = gql`
    mutation updateChannelProfile($channelProfileData: ChannelProfileInput!) {
  updateChannelProfile(channelProfileData: $channelProfileData) {
    channelProfile {
      ...ChannelProfileFields
    }
  }
}
    ${ChannelProfileFieldsFragmentDoc}`;
export type UpdateChannelProfileMutationFn = Apollo.MutationFunction<UpdateChannelProfileMutation, UpdateChannelProfileMutationVariables>;

/**
 * __useUpdateChannelProfileMutation__
 *
 * To run a mutation, you first call `useUpdateChannelProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelProfileMutation, { data, loading, error }] = useUpdateChannelProfileMutation({
 *   variables: {
 *      channelProfileData: // value for 'channelProfileData'
 *   },
 * });
 */
export function useUpdateChannelProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelProfileMutation, UpdateChannelProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelProfileMutation, UpdateChannelProfileMutationVariables>(UpdateChannelProfileDocument, options);
      }
export type UpdateChannelProfileMutationHookResult = ReturnType<typeof useUpdateChannelProfileMutation>;
export type UpdateChannelProfileMutationResult = Apollo.MutationResult<UpdateChannelProfileMutation>;
export type UpdateChannelProfileMutationOptions = Apollo.BaseMutationOptions<UpdateChannelProfileMutation, UpdateChannelProfileMutationVariables>;
export const CreateOrUpdateChannelDocument = gql`
    mutation createOrUpdateChannel($id: String, $input: ChannelInput!) {
  createOrUpdateChannel(id: $id, input: $input) {
    channel {
      ...ChannelFields
    }
  }
}
    ${ChannelFieldsFragmentDoc}`;
export type CreateOrUpdateChannelMutationFn = Apollo.MutationFunction<CreateOrUpdateChannelMutation, CreateOrUpdateChannelMutationVariables>;

/**
 * __useCreateOrUpdateChannelMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateChannelMutation, { data, loading, error }] = useCreateOrUpdateChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateChannelMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateChannelMutation, CreateOrUpdateChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateChannelMutation, CreateOrUpdateChannelMutationVariables>(CreateOrUpdateChannelDocument, options);
      }
export type CreateOrUpdateChannelMutationHookResult = ReturnType<typeof useCreateOrUpdateChannelMutation>;
export type CreateOrUpdateChannelMutationResult = Apollo.MutationResult<CreateOrUpdateChannelMutation>;
export type CreateOrUpdateChannelMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateChannelMutation, CreateOrUpdateChannelMutationVariables>;
export const CreateChannelDocument = gql`
    mutation createChannel($input: ChannelInput!) {
  createChannel(input: $input) {
    channel {
      ...ChannelFields
    }
  }
}
    ${ChannelFieldsFragmentDoc}`;
export type CreateChannelMutationFn = Apollo.MutationFunction<CreateChannelMutation, CreateChannelMutationVariables>;

/**
 * __useCreateChannelMutation__
 *
 * To run a mutation, you first call `useCreateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelMutation, { data, loading, error }] = useCreateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChannelMutation(baseOptions?: Apollo.MutationHookOptions<CreateChannelMutation, CreateChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChannelMutation, CreateChannelMutationVariables>(CreateChannelDocument, options);
      }
export type CreateChannelMutationHookResult = ReturnType<typeof useCreateChannelMutation>;
export type CreateChannelMutationResult = Apollo.MutationResult<CreateChannelMutation>;
export type CreateChannelMutationOptions = Apollo.BaseMutationOptions<CreateChannelMutation, CreateChannelMutationVariables>;
export const UpdateChannelDocument = gql`
    mutation updateChannel($id: UUID!, $input: ChannelInput!) {
  updateChannel(id: $id, input: $input) {
    channel {
      ...ChannelFields
    }
  }
}
    ${ChannelFieldsFragmentDoc}`;
export type UpdateChannelMutationFn = Apollo.MutationFunction<UpdateChannelMutation, UpdateChannelMutationVariables>;

/**
 * __useUpdateChannelMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMutation, { data, loading, error }] = useUpdateChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelMutation, UpdateChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelMutation, UpdateChannelMutationVariables>(UpdateChannelDocument, options);
      }
export type UpdateChannelMutationHookResult = ReturnType<typeof useUpdateChannelMutation>;
export type UpdateChannelMutationResult = Apollo.MutationResult<UpdateChannelMutation>;
export type UpdateChannelMutationOptions = Apollo.BaseMutationOptions<UpdateChannelMutation, UpdateChannelMutationVariables>;
export const DeleteChannelDocument = gql`
    mutation deleteChannel($id: UUID!, $organizationId: String!) {
  deleteChannel(id: $id, organizationId: $organizationId) {
    success
  }
}
    `;
export type DeleteChannelMutationFn = Apollo.MutationFunction<DeleteChannelMutation, DeleteChannelMutationVariables>;

/**
 * __useDeleteChannelMutation__
 *
 * To run a mutation, you first call `useDeleteChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChannelMutation, { data, loading, error }] = useDeleteChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteChannelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChannelMutation, DeleteChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChannelMutation, DeleteChannelMutationVariables>(DeleteChannelDocument, options);
      }
export type DeleteChannelMutationHookResult = ReturnType<typeof useDeleteChannelMutation>;
export type DeleteChannelMutationResult = Apollo.MutationResult<DeleteChannelMutation>;
export type DeleteChannelMutationOptions = Apollo.BaseMutationOptions<DeleteChannelMutation, DeleteChannelMutationVariables>;
export const CreateChatbotDocument = gql`
    mutation createChatbot($chatbotData: ChatbotInput!, $organizationId: UUID) {
  createChatbot(chatbotData: $chatbotData, organizationId: $organizationId) {
    chatbot {
      id
      chatboxTitle
      welcomeMessage
      description
      chatbotChannels {
        ...ChannelFields
      }
      personalised
      extractionAgent
    }
  }
}
    ${ChannelFieldsFragmentDoc}`;
export type CreateChatbotMutationFn = Apollo.MutationFunction<CreateChatbotMutation, CreateChatbotMutationVariables>;

/**
 * __useCreateChatbotMutation__
 *
 * To run a mutation, you first call `useCreateChatbotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatbotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatbotMutation, { data, loading, error }] = useCreateChatbotMutation({
 *   variables: {
 *      chatbotData: // value for 'chatbotData'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateChatbotMutation(baseOptions?: Apollo.MutationHookOptions<CreateChatbotMutation, CreateChatbotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChatbotMutation, CreateChatbotMutationVariables>(CreateChatbotDocument, options);
      }
export type CreateChatbotMutationHookResult = ReturnType<typeof useCreateChatbotMutation>;
export type CreateChatbotMutationResult = Apollo.MutationResult<CreateChatbotMutation>;
export type CreateChatbotMutationOptions = Apollo.BaseMutationOptions<CreateChatbotMutation, CreateChatbotMutationVariables>;
export const CreateOrganizationDocument = gql`
    mutation createOrganization($name: String!, $timezone: String!, $priceTierType: String!) {
  createOrganization(
    name: $name
    timezone: $timezone
    priceTierType: $priceTierType
  ) {
    organization {
      id
      created
      updated
      name
      timezone
    }
  }
}
    `;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      timezone: // value for 'timezone'
 *      priceTierType: // value for 'priceTierType'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const VerifyTokenDocument = gql`
    mutation verifyToken($token: String!) {
  verifyToken(token: $token) {
    payload
  }
}
    `;
export type VerifyTokenMutationFn = Apollo.MutationFunction<VerifyTokenMutation, VerifyTokenMutationVariables>;

/**
 * __useVerifyTokenMutation__
 *
 * To run a mutation, you first call `useVerifyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTokenMutation, { data, loading, error }] = useVerifyTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTokenMutation, VerifyTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(VerifyTokenDocument, options);
      }
export type VerifyTokenMutationHookResult = ReturnType<typeof useVerifyTokenMutation>;
export type VerifyTokenMutationResult = Apollo.MutationResult<VerifyTokenMutation>;
export type VerifyTokenMutationOptions = Apollo.BaseMutationOptions<VerifyTokenMutation, VerifyTokenMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const SignupOrganizationWithAdminDocument = gql`
    mutation signupOrganizationWithAdmin($name: String!, $priceTierType: String!, $timezone: String!, $adminName: String!, $adminEmail: String!) {
  signupOrganization(
    name: $name
    priceTierType: $priceTierType
    timezone: $timezone
    adminName: $adminName
    adminEmail: $adminEmail
  ) {
    success
  }
}
    `;
export type SignupOrganizationWithAdminMutationFn = Apollo.MutationFunction<SignupOrganizationWithAdminMutation, SignupOrganizationWithAdminMutationVariables>;

/**
 * __useSignupOrganizationWithAdminMutation__
 *
 * To run a mutation, you first call `useSignupOrganizationWithAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupOrganizationWithAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupOrganizationWithAdminMutation, { data, loading, error }] = useSignupOrganizationWithAdminMutation({
 *   variables: {
 *      name: // value for 'name'
 *      priceTierType: // value for 'priceTierType'
 *      timezone: // value for 'timezone'
 *      adminName: // value for 'adminName'
 *      adminEmail: // value for 'adminEmail'
 *   },
 * });
 */
export function useSignupOrganizationWithAdminMutation(baseOptions?: Apollo.MutationHookOptions<SignupOrganizationWithAdminMutation, SignupOrganizationWithAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupOrganizationWithAdminMutation, SignupOrganizationWithAdminMutationVariables>(SignupOrganizationWithAdminDocument, options);
      }
export type SignupOrganizationWithAdminMutationHookResult = ReturnType<typeof useSignupOrganizationWithAdminMutation>;
export type SignupOrganizationWithAdminMutationResult = Apollo.MutationResult<SignupOrganizationWithAdminMutation>;
export type SignupOrganizationWithAdminMutationOptions = Apollo.BaseMutationOptions<SignupOrganizationWithAdminMutation, SignupOrganizationWithAdminMutationVariables>;
export const SignupUserDocument = gql`
    mutation signupUser($email: String!, $name: String!, $organization: String!, $designation: String, $usecaseFunction: String) {
  signup(
    email: $email
    name: $name
    organization: $organization
    designation: $designation
    usecaseFunction: $usecaseFunction
  ) {
    success
  }
}
    `;
export type SignupUserMutationFn = Apollo.MutationFunction<SignupUserMutation, SignupUserMutationVariables>;

/**
 * __useSignupUserMutation__
 *
 * To run a mutation, you first call `useSignupUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupUserMutation, { data, loading, error }] = useSignupUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      organization: // value for 'organization'
 *      designation: // value for 'designation'
 *      usecaseFunction: // value for 'usecaseFunction'
 *   },
 * });
 */
export function useSignupUserMutation(baseOptions?: Apollo.MutationHookOptions<SignupUserMutation, SignupUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupUserMutation, SignupUserMutationVariables>(SignupUserDocument, options);
      }
export type SignupUserMutationHookResult = ReturnType<typeof useSignupUserMutation>;
export type SignupUserMutationResult = Apollo.MutationResult<SignupUserMutation>;
export type SignupUserMutationOptions = Apollo.BaseMutationOptions<SignupUserMutation, SignupUserMutationVariables>;
export const InviteUserDocument = gql`
    mutation inviteUser($email: String!, $name: String, $role: UUID, $isOwner: Boolean, $organizationId: String) {
  register(
    email: $email
    name: $name
    role: $role
    isOwner: $isOwner
    organizationId: $organizationId
  ) {
    success
  }
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      role: // value for 'role'
 *      isOwner: // value for 'isOwner'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!, $password: String!) {
  activateUser(password: $password, token: $token) {
    success
  }
}
    `;
export type ActivateUserMutationFn = Apollo.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: Apollo.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, options);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = Apollo.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = Apollo.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const RegisterTokenOrganizationDocument = gql`
    query registerTokenOrganization($token: String!) {
  registerTokenOrganization(token: $token) {
    id
    email
    name
    organization {
      id
      name
      timezone
    }
  }
}
    `;

/**
 * __useRegisterTokenOrganizationQuery__
 *
 * To run a query within a React component, call `useRegisterTokenOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterTokenOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterTokenOrganizationQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRegisterTokenOrganizationQuery(baseOptions: Apollo.QueryHookOptions<RegisterTokenOrganizationQuery, RegisterTokenOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegisterTokenOrganizationQuery, RegisterTokenOrganizationQueryVariables>(RegisterTokenOrganizationDocument, options);
      }
export function useRegisterTokenOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegisterTokenOrganizationQuery, RegisterTokenOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegisterTokenOrganizationQuery, RegisterTokenOrganizationQueryVariables>(RegisterTokenOrganizationDocument, options);
        }
export type RegisterTokenOrganizationQueryHookResult = ReturnType<typeof useRegisterTokenOrganizationQuery>;
export type RegisterTokenOrganizationLazyQueryHookResult = ReturnType<typeof useRegisterTokenOrganizationLazyQuery>;
export type RegisterTokenOrganizationQueryResult = Apollo.QueryResult<RegisterTokenOrganizationQuery, RegisterTokenOrganizationQueryVariables>;
export const ForgotUserPasswordDocument = gql`
    mutation forgotUserPassword($email: String!) {
  forgotPassword(email: $email) {
    success
  }
}
    `;
export type ForgotUserPasswordMutationFn = Apollo.MutationFunction<ForgotUserPasswordMutation, ForgotUserPasswordMutationVariables>;

/**
 * __useForgotUserPasswordMutation__
 *
 * To run a mutation, you first call `useForgotUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotUserPasswordMutation, { data, loading, error }] = useForgotUserPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotUserPasswordMutation, ForgotUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotUserPasswordMutation, ForgotUserPasswordMutationVariables>(ForgotUserPasswordDocument, options);
      }
export type ForgotUserPasswordMutationHookResult = ReturnType<typeof useForgotUserPasswordMutation>;
export type ForgotUserPasswordMutationResult = Apollo.MutationResult<ForgotUserPasswordMutation>;
export type ForgotUserPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotUserPasswordMutation, ForgotUserPasswordMutationVariables>;
export const ResetUserPasswordDocument = gql`
    mutation resetUserPassword($uid: String!, $token: String!, $newPassword: String!) {
  resetPassword(newPassword: $newPassword, token: $token, uid: $uid) {
    success
  }
}
    `;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(ResetUserPasswordDocument, options);
      }
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>;
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;
export const CreateQuestionPairsDocument = gql`
    mutation createQuestionPairs($id: String, $chatbotId: UUID!, $questionPairs: JSONString!) {
  createQuestionPairs(
    id: $id
    chatbotId: $chatbotId
    questionPairs: $questionPairs
  ) {
    document {
      id
      file
      summary
    }
  }
}
    `;
export type CreateQuestionPairsMutationFn = Apollo.MutationFunction<CreateQuestionPairsMutation, CreateQuestionPairsMutationVariables>;

/**
 * __useCreateQuestionPairsMutation__
 *
 * To run a mutation, you first call `useCreateQuestionPairsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionPairsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionPairsMutation, { data, loading, error }] = useCreateQuestionPairsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      chatbotId: // value for 'chatbotId'
 *      questionPairs: // value for 'questionPairs'
 *   },
 * });
 */
export function useCreateQuestionPairsMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionPairsMutation, CreateQuestionPairsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionPairsMutation, CreateQuestionPairsMutationVariables>(CreateQuestionPairsDocument, options);
      }
export type CreateQuestionPairsMutationHookResult = ReturnType<typeof useCreateQuestionPairsMutation>;
export type CreateQuestionPairsMutationResult = Apollo.MutationResult<CreateQuestionPairsMutation>;
export type CreateQuestionPairsMutationOptions = Apollo.BaseMutationOptions<CreateQuestionPairsMutation, CreateQuestionPairsMutationVariables>;
export const DeleteOrganizationRoleDocument = gql`
    mutation deleteOrganizationRole($id: UUID!) {
  deleteRole(id: $id) {
    success
  }
}
    `;
export type DeleteOrganizationRoleMutationFn = Apollo.MutationFunction<DeleteOrganizationRoleMutation, DeleteOrganizationRoleMutationVariables>;

/**
 * __useDeleteOrganizationRoleMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationRoleMutation, { data, loading, error }] = useDeleteOrganizationRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationRoleMutation, DeleteOrganizationRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationRoleMutation, DeleteOrganizationRoleMutationVariables>(DeleteOrganizationRoleDocument, options);
      }
export type DeleteOrganizationRoleMutationHookResult = ReturnType<typeof useDeleteOrganizationRoleMutation>;
export type DeleteOrganizationRoleMutationResult = Apollo.MutationResult<DeleteOrganizationRoleMutation>;
export type DeleteOrganizationRoleMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationRoleMutation, DeleteOrganizationRoleMutationVariables>;
export const CreateRoleDocument = gql`
    mutation CreateRole($role: String!) {
  createRole(role: $role) {
    role {
      id
      role
    }
  }
}
    `;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($id: UUID!, $role: String!) {
  updateRole(id: $id, role: $role) {
    role {
      id
      organization {
        id
      }
      role
    }
  }
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const AssingRoleDocument = gql`
    mutation AssingRole($id: UUID!, $userId: UUID!) {
  assignRole(id: $id, userId: $userId) {
    user {
      id
    }
  }
}
    `;
export type AssingRoleMutationFn = Apollo.MutationFunction<AssingRoleMutation, AssingRoleMutationVariables>;

/**
 * __useAssingRoleMutation__
 *
 * To run a mutation, you first call `useAssingRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssingRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assingRoleMutation, { data, loading, error }] = useAssingRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAssingRoleMutation(baseOptions?: Apollo.MutationHookOptions<AssingRoleMutation, AssingRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssingRoleMutation, AssingRoleMutationVariables>(AssingRoleDocument, options);
      }
export type AssingRoleMutationHookResult = ReturnType<typeof useAssingRoleMutation>;
export type AssingRoleMutationResult = Apollo.MutationResult<AssingRoleMutation>;
export type AssingRoleMutationOptions = Apollo.BaseMutationOptions<AssingRoleMutation, AssingRoleMutationVariables>;