import { Button, CircularProgress, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  useActivateUserMutation,
  useRegisterTokenOrganizationLazyQuery,
} from "../generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import FormItemContainer from "../atoms/FormItemContainer";
import { Notification } from "../molecules/Notification";
import { validatePassword } from "../utils/utils";
import { INotification } from "../interfaces/General";
import { CreateBtn } from "../atoms/CreateBtn";

const Activate: FC<{ isFinalize?: boolean }> = ({ isFinalize }) => {
  const navigate = useNavigate();
  const { token } = useParams();

  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [confirmError, setConfirmError] = useState(false);
  const [formValues, setFormValues] = useState({
    password: "",
    confirmPassword: "",
  });

  const [getUser, { data: user, loading: userLoading }] =
    useRegisterTokenOrganizationLazyQuery({
      variables: {
        token: token ?? "",
      },
    });
  const [activate, { loading, error }] = useActivateUserMutation({
    variables: {
      token: token ?? "",
      password: formValues.password,
    },
    fetchPolicy: "no-cache",
    async onCompleted(data) {
      if (data.activateUser && data.activateUser.success) {
        navigate("/login");
      } else {
        setNotification({
          message: "Invtation link has been expired",
          open: true,
          type: "error",
        });
      }
    },
    onError(error) {
      setNotification({
        message: error?.message,
        open: true,
        type: "error",
      });
    },
  });

  const handleChange: React.ChangeEventHandler<HTMLInputElement> | undefined = (
    event
  ) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async () => {
    const passwordErrors = validatePassword(formValues.password);
    if (formValues.password !== formValues.confirmPassword || passwordErrors) {
      if (passwordErrors) {
        setNotification({ message: passwordErrors, open: true, type: "error" });
      } else {
        setConfirmError(true);
      }
    } else {
      activate();
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      <Notification {...notification} setOpen={setNotification} />

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {!isFinalize ? (
            <>
              You have been invited by &nbsp;
              <span>
                {userLoading ? (
                  <CircularProgress />
                ) : (
                  user?.registerTokenOrganization?.organization?.name
                )}
              </span>
            </>
          ) : (
            <>
              Hi{" "}
              <span className="text-green-500">
                {user?.registerTokenOrganization?.name}
              </span>
              &nbsp; Finalize your registration
            </>
          )}
        </h2>
      </div>

      {error && (
        <FormItemContainer>
          <p className="text-red-500">{error.message}</p>
        </FormItemContainer>
      )}

      <form noValidate autoComplete="off">
        <FormItemContainer>
          <TextField
            id="password"
            name="password"
            label="Password"
            type="password"
            onChange={handleChange}
            size="medium"
            fullWidth
          />
        </FormItemContainer>
        <FormItemContainer>
          <TextField
            id="password"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            onChange={handleChange}
            size="medium"
            error={confirmError}
            helperText={confirmError ? "Password does not match" : ""}
            fullWidth
          />
        </FormItemContainer>
        <FormItemContainer>
          <CreateBtn
            variant="contained"
            color="primary"
            onClick={handleLogin}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Activate Account"
            )}
          </CreateBtn>
        </FormItemContainer>
      </form>
    </>
  );
};

export default Activate;
