import { MenuItem, Select, TextField } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { KnowledgeBaseProps } from ".";


const OpenSearchConfigForm: FC<KnowledgeBaseProps> = ({
  formValues,
  loading,
  setFormValues,
}) => {
  const { id } = useParams();

  const handleChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined = (e) => {
      const { name, value } = e.target;

      updateFormValues(name, value);
    };

  const updateFormValues = (name: string, value: string) => {
    if (formValues) {
      setFormValues((prevValues) => {
        if (prevValues.opensearchConfig) {
          return {
            ...prevValues,
            opensearchConfig: { ...prevValues.opensearchConfig, [name]: value },
          };
        }
        return prevValues;
      });
    }
  };

  return (
    <form className="w-full">
      <div className="flex place-content-around">
        <TextField
          style={{ marginRight: 10 }}
          name="dbName"
          label="Database Name"
          required
          value={formValues.opensearchConfig?.dbName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          size="small"
        />

        <TextField
          style={{ marginRight: 10 }}
          name="dbUser"
          label="Database User"
          required
          value={formValues.opensearchConfig?.dbUser}
          onChange={handleChange}
          fullWidth
          margin="normal"
          size="small"
        />
        <TextField
          name="dbPass"
          type="password"
          label="Database Password"
          required
          value={formValues.opensearchConfig?.dbPass}
          onChange={handleChange}
          fullWidth
          margin="normal"
          size="small"
        />
      </div>
      <div className="flex place-content-around">
        <TextField
          style={{ marginRight: 10 }}
          name="dbHost"
          label="Database Host"
          required
          value={formValues.opensearchConfig?.dbHost}
          onChange={handleChange}
          fullWidth
          margin="normal"
          size="small"
        />
        <TextField
          name="dbPort"
          label="Database Port"
          required
          value={formValues.opensearchConfig?.dbPort}
          onChange={handleChange}
          fullWidth
          margin="normal"
          size="small"
        />
      </div>
      <div className="flex place-content-around">
        <TextField
          name="dbResponse"
          label="Response"
          required
          value={formValues.opensearchConfig?.dbResponse}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
        />
      </div>
      <div className="flex place-content-around">
        <TextField
          name="dbAdditionalResponse"
          label="Additional Response"
          required
          value={formValues.opensearchConfig?.dbAdditionalResponse}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
        />
      </div>
    </form>
  );
};

export default OpenSearchConfigForm;
