import { FC, FormEvent, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CreateBtn } from "../../atoms/CreateBtn";
import { ISTEPPER } from "../../interfaces/Chatbot";
import { CircularProgress, LinearProgress, StepButton } from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import { ChatbotFormType } from "../ChatbotCreation";
import { ChatbotType } from "../../generated/graphql";
import { INotification } from "../../interfaces/General";
import { verifyChatbotForm } from "../ChatbotCreation/utils";

interface HorizontalLinearStepperProps {
  steps: ISTEPPER[];
  formValues: ChatbotFormType;
  chatbot: ChatbotType | undefined;
  files: File[];
  loading: boolean;
  progress: number;
  savingText: string;
  onFinish: React.FormEventHandler<HTMLFormElement>;
  setNotification: React.Dispatch<React.SetStateAction<INotification>>;
}

const HorizontalLinearStepper: FC<HorizontalLinearStepperProps> = ({
  steps,
  formValues,
  chatbot,
  files,
  loading,
  progress,
  savingText,
  onFinish,
  setNotification,
}) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get("step");

  const [activeStep, setActiveStep] = useState(parseInt(step ?? "1") - 1);

  const [skipped, setSkipped] = useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return steps[step].isOptional;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext: React.MouseEventHandler<HTMLButtonElement> | undefined = (
    e
  ) => {
    if (activeStep === steps.length - 1) {
      onFinish(e as unknown as FormEvent<HTMLFormElement>);
    } else {
      const formValidator = verifyChatbotForm(
        formValues,
        chatbot?.id,
        chatbot,
        files,
        activeStep
      );
      if (formValidator.status) {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      } else {
        setNotification({
          message: formValidator.reason.join("<br />"),
          open: true,
          type: "error",
        });
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getWidth = () => {
    if (location.pathname.includes("knowledge-base") || activeStep === 2) {
      return "w-[900px]";
    } else if (location.pathname.includes("channels") || activeStep === 5) {
      return "w-full";
    }
    return "w-[700px]";
  };

  const getHeight = () => {
    if (location.pathname.includes("personalisation") && steps.length === 1) {
      return null;
    }
    return "h-[500px]";
  };
  const getStepComponent = () => {
    const step = steps[activeStep];
    return (
      <div className={`flex justify-center overflow-x-auto ${getHeight()}`}>
        <div className={`${getWidth()} mt-10`}>
          {!loading && step.component()}
        </div>
      </div>
    );
  };

  const handleStep = (step: number) => () => {
    if (!loading) {
      setActiveStep(step);
    }
  };

  const setUrl = () => {
    searchParams.set("step", (activeStep + 1).toString());
    setSearchParams(searchParams);
  };

  const getButtonFinishLabel = () => {
    let buttonText = "Finish";
    if (steps.length === 1) {
      buttonText = "Update";
    }

    return loading ? (
      <CircularProgress size={24} color="inherit" />
    ) : (
      buttonText
    );
  };

  useEffect(() => {
    setUrl();
  }, [activeStep]);

  return (
    <Box sx={{ width: "100%" }}>
      {steps.length > 1 && (
        <Stepper activeStep={activeStep}>
          {steps.map(({ label }, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step
                key={label}
                {...stepProps}
                onClick={handleStep(index)}
                style={{ cursor: "pointer" }}
              >
                <StepButton {...labelProps}>{label}</StepButton>
              </Step>
            );
          })}
        </Stepper>
      )}
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </>
      ) : (
        <>
          {getStepComponent()}
          <div className="flex items-center flex-col">
            <Box
              sx={{
                width: "450px",
                mb: 2,
                textAlign: "center",
              }}
            >
              {loading && (
                <>
                  <LinearProgress
                    color="secondary"
                    variant="determinate"
                    value={progress}
                  />
                  {savingText}
                </>
              )}
            </Box>
            <div>
              {steps.length > 1 && (
                <CreateBtn
                  color="inherit"
                  disabled={activeStep === 0 || loading}
                  onClick={handleBack}
                  sx={{ mr: 1, width: "100px" }}
                >
                  Back
                </CreateBtn>
              )}
              {(steps.length === 1 && isStepOptional(activeStep)) ||
              location.pathname.includes("deployments")
                ? null
                : !location.pathname.includes("channels") && (
                    <CreateBtn
                      onClick={handleNext}
                      sx={{ mr: 1, width: "100px" }}
                      disabled={loading}
                    >
                      {activeStep === steps.length - 1
                        ? getButtonFinishLabel()
                        : "Next"}
                    </CreateBtn>
                  )}
            </div>
            {isStepOptional(activeStep) && steps.length > 1 && (
              <div
                className="underline cursor-pointer mt-4"
                onClick={handleSkip}
              >
                Skip
              </div>
            )}
          </div>
        </>
      )}
    </Box>
  );
};

export default HorizontalLinearStepper;
