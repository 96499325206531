import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  Paper,
  Grid,
} from "@mui/material";
import { CreateBtn } from "../../atoms/CreateBtn";
import ArcProgressBar from "../../atoms/ArcProgressBar";
import AddIcon from "@mui/icons-material/Add";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";

import DashboardMenu from "../../molecules/Dashboard/Menu";
import { useUser } from "../../utils/auth";
import ChatbotSelection from "../../molecules/ChatbotSelection";
import { useApp } from "../../states/AppContext";
import ChatbotWindow from "../../organisms/ChatbotWindow";
import { useChatboNavigate } from "../../hooks/navigate";
import {
  UpgradeActionEnum,
  UpgradeLokichat,
} from "../../molecules/PriceTier/UpgradeLokichat";
import { useChatbot } from "../../utils/chatbot";
// import { UserOrganizationSelect } from "../../atoms/UserOrganizationSelect";

const Dashboard = () => {
  const { logout, loginUser, loading, user } = useUser();
  const { loading: loadingChatbot, loadingOnlyChatbots } = useChatbot();
  const location = useLocation();
  const navigate = useNavigate();
  const { overviewPage } = useChatboNavigate();
  const { id } = useParams();
  const [state] = useApp();
  const queryParams = new URLSearchParams(location.search);
  const organizationId = queryParams.get("organization_id");
  const isAccountReached =
    state.ChatbotReducer?.chatbots?.length >=
    user.organization.priceTierConfig.numberOfChatbots;
  const isAdminArea = location.pathname.includes("admin");
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleAvatarClick:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined = (event) => {
      if (event.currentTarget) {
        setAnchorEl(event.currentTarget);
      }
    };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const getUser = async () => {
    await loginUser(organizationId ?? "");
  };

  useEffect(() => {
    if (!user.id) {
      getUser();
    }
  }, []);

  if (
    loadingChatbot ||
    loadingOnlyChatbots ||
    (!state.ChatbotReducer?.chatbots?.length &&
      location.pathname !== "/dashboard/chatbot")
  ) {
    return (
      <div className="h-screen flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar
          className="flex justify-between"
          style={{
            background: "#fff",
          }}
        >
          <Grid container className={`flex items-center`}>
            <Grid item xs={3}>
              <a href={`/?organization_id=${organizationId}`}>
                <img
                  className="logo"
                  src="/icons/logo.png"
                  alt="lokibots chats"
                />
              </a>
            </Grid>
            <Grid item xs={6} className="flex justify-around">
              <div className="text-black">
                {user.isMaster && (
                  <>
                    Organization:{" "}
                    <span className="text-green-500 pr-2">
                      {/* <UserOrganizationSelect value={value} setValue={setValue} /> */}

                      <Link
                        title="Click to edit"
                        to={`/login/master-admin-select-organization`}
                      >
                        {" "}
                        {user.organization?.name}
                      </Link>
                    </span>
                  </>
                )}
              </div>
            </Grid>
            <Grid item xs={3} className="flex justify-end">
              <div className="flex">
                {isAccountReached ? (
                  <div className="hidden md:block">
                    <UpgradeLokichat action={UpgradeActionEnum.CREATE_BOT} />
                  </div>
                ) : (
                  <span className="mr-4">
                    <Link
                      to={"/dashboard/chatbot?organization_id=" + organizationId}
                    >
                      <CreateBtn endIcon={<AddIcon />} variant="contained">
                        Create Chatbot
                      </CreateBtn>
                    </Link>
                  </span>
                )}
                <IconButton onClick={handleAvatarClick}>
                  <AccountCircleIcon />
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {user.isOwner && (
                    <Link
                      to={
                        isAdminArea
                          ? overviewPage
                          : "/dashboard/admin/settings?organization_id=" +
                          organizationId
                      }
                    >
                      <MenuItem>
                        {isAdminArea ? "User Area" : "Admin Area"}
                      </MenuItem>
                    </Link>
                  )}
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
                <IconButton
                  onClick={handleDrawerOpen}
                  className="mr-2"
                  sx={{ display: { xs: "inline", sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={12} className="block md:hidden justify-around">
              {isAccountReached && <UpgradeLokichat action={UpgradeActionEnum.CREATE_BOT} />}
            </Grid>
          </Grid>
        </Toolbar>
        {user.organization.priceTierType !== "unlimited" &&
          <div className="hidden sm:flex max-h-[60px] px-[24px] bg-white">
            <Grid container spacing={2}>
              <Grid item xs={3} className="align-middle">
                <span className="flex text-black/[0.6] text-xs items-center px-6" >Plan: <b>{user.organization.priceTierType.toUpperCase()}</b></span>
              </Grid>
              <Grid item xs={6} className="flex justify-around">
                <div className="flex gap-10">
                  <span className="text-black/[0.6] flex flex-row items-center">
                    <div className="p-1 text-xs">
                      Bots:
                    </div>
                    <ArcProgressBar value={user.organization.numberOfChatbots} max={user.organization.priceTierConfig.numberOfChatbots} />
                  </span>
                  <span className={`flex flex-row items-center ${user.organization.monthlyQuestionsLimit / user.organization.priceTierConfig.monthlyQuestionsLimit >= 0.85 ? 'text-rose-700' : 'text-black/[0.6]'}`}>
                    <div className="p-1 text-xs">
                      Queries:
                    </div>
                    <ArcProgressBar value={user.organization.monthlyQuestionsLimit} max={user.organization.priceTierConfig.monthlyQuestionsLimit} />
                  </span>
                  <span className="text-black/[0.6] flex flex-row items-center">
                    <div className="p-1 text-xs">
                      Storage:
                    </div>
                    <ArcProgressBar value={user.organization.storageLimit} max={user.organization.priceTierConfig.storageLimit} />
                  </span>
                </div>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </div>}
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
        <DashboardMenu
          plan={state.UserReducer.organization.priceTierType}
          isOwner={user.isOwner}
          isAdminArea={isAdminArea}
          id={state.ChatbotReducer.chatbotSelected}
        />
      </Drawer>
      <div className={`flex ${user.organization.priceTierType === "unlimited" ? "pt-[5rem]" : "pt-[8rem]"}`}>
        {(id || isAdminArea) && (
          <div className="flex-none w-64 px-4 lokichats-menu sticky top-16">
            {!!state.ChatbotReducer.onlyChatbots.length && !isAdminArea && (
              <ChatbotSelection key={id} />
            )}
            <Paper
              style={{
                boxShadow: "none",
              }}
            >
              <DashboardMenu
                plan={state.UserReducer.organization.priceTierType}
                isOwner={user.isOwner}
                isAdminArea={isAdminArea}
                id={state.ChatbotReducer.chatbotSelected}
                key={state.ChatbotReducer.chatbotSelected}
              />
            </Paper>
          </div>
        )}
        <div className="flex-auto">
          {loading ? (
            <div className="h-screen flex justify-center items-center">
              <CircularProgress />
            </div>
          ) : (
            <div className="p-5">
              <ChatbotWindow />
              <Outlet />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
