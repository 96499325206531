import { useLocation } from "react-router-dom";
import { useOrganizationStatsLazyQuery } from "../../generated/graphql";
import { Chip, CircularProgress } from "@mui/material";
import {
  Bar,
  BarChart,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  PieLabel,
} from "recharts";
import { useEffect } from "react";
import { useUser } from "../../utils/auth";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const AdminDashboardPage = () => {
  const location = useLocation();
  const { user } = useUser();
  const queryParams = new URLSearchParams(location.search);
  const organizationId = queryParams.get("organization_id");

  const [getOrganizationState, { loading, data }] =
    useOrganizationStatsLazyQuery({
      variables: {
        organizationId,
      },
    });

  useEffect(() => {
    getOrganizationState();
  }, []);
  if (loading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  const MAX_MONTHLY_QUESTIONS =
    data?.organization?.priceTierConfig?.monthlyQuestionsLimit ?? 0;
  const MAX_CHATBOTS =
    data?.organization?.priceTierConfig?.numberOfChatbots ?? 0;
  const MAX_STORAGE = data?.organization?.priceTierConfig?.storageLimit ?? 0;
  const dataForChart = [
    {
      name: "Monthly Questions Limit",
      used: data?.organization?.monthlyQuestionsLimit,
      left:
        MAX_MONTHLY_QUESTIONS -
        (data?.organization?.monthlyQuestionsLimit || 0),
    },
    {
      name: "Number of Chatbots",
      used: data?.organization?.numberOfChatbots,
      left: MAX_CHATBOTS - (data?.organization?.numberOfChatbots || 0),
    },
    {
      name: "Storage Limit (MB)",
      used: data?.organization?.storageLimit,
      left: MAX_STORAGE - (data?.organization?.storageLimit || 0),
    },
  ];

  const renderCustomizedLabel: PieLabel<any> | undefined = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    payload,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN) + 5;
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const displayValue =
      payload.name === "Left" &&
      (user?.organization.priceTierType === "unlimited" ||
        user?.organization.priceTierType === "platinum")
        ? "∞"
        : payload.value.toFixed(0);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {displayValue}
      </text>
    );
  };

  return (
    <>
      <div className="flex">
        <span className="mr-2"> Your plan: </span>
        <Chip
          label={user.organization.priceTierType}
          color="primary"
          className="mb-4"
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4">
        {dataForChart.map((entry, index) => {
          const pieData = [
            { name: "Used", value: entry.used },
            { name: "Left", value: entry.left },
          ];

          return (
            <div key={index}>
              <h2 className="text-center mb-4">{entry.name}</h2>
              <PieChart width={400} height={400} key={index}>
                <Pie
                  dataKey="value"
                  startAngle={360}
                  endAngle={0}
                  data={pieData}
                  cx={200}
                  cy={200}
                  outerRadius={80}
                  fill="#8884d8"
                  label={renderCustomizedLabel}
                  labelLine={false}
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AdminDashboardPage;
