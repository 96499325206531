import { FC, useEffect, useState } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import UserList from "../molecules/UserList";
import { IUser } from "../interfaces/Users";
import {
  // RoleType,
  useDeleteCustomerMutation,
  useOrganizationCustomersLazyQuery,
  // useRolesLazyQuery,
} from "../generated/graphql";
import AlertDialog from "../molecules/Dialog";
import InviteMember from "./InviteMember";
import { Notification } from "../molecules/Notification";
import { INotification } from "../interfaces/General";
import { useLocation } from "react-router-dom";

const Member: FC = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const organizationId = queryParams.get("organization_id");
  // const [roles, setRoles] = useState<RoleType[]>([]);
  // const [getRoles] = useRolesLazyQuery({
  //   fetchPolicy: "no-cache",
  //   onCompleted(data) {
  //     if (data.roles && data.roles.length) {
  //       setRoles(data.roles as RoleType[]);
  //     }
  //   },
  // });
  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "error",
  });
  const [getMembers, { loading }] = useOrganizationCustomersLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      organizationId: organizationId,
    },
    onCompleted(data) {
      if (data.organizationCustomers) {
        setUsers(data.organizationCustomers as IUser[]);
      }
    },
  });
  const [deleteMember] = useDeleteCustomerMutation({
    onCompleted(data) {
      if (data.deleteCustomer && data.deleteCustomer.id) {
        setUsers(users.filter((item) => item.id !== data.deleteCustomer?.id));
      }
    },
  });
  const [users, setUsers] = useState<IUser[]>([]);

  const handleUserDelete = async (user: IUser) => {
    await deleteMember({ variables: { id: user.id } });
  };

  const handleInviteMember = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    getMembers();
    setNotification({
      message: "Invitation link has been sent to user",
      open: true,
      type: "success",
    });
  };
  const handleCloseInterrupted = () => {
    setOpen(false);
    getMembers();
  };
  useEffect(() => {
    getMembers();
    // getRoles();
  }, []);
  return (
    <div>
      <Notification {...notification} setOpen={setNotification} />
      <AlertDialog
        description={<InviteMember onSuccess={handleClose} roles={[]} />}
        title="Invite Member"
        open={open}
        handleClose={handleCloseInterrupted}
        maxWidth="md"
        fullWidth
        yesLabel="Close"
        isRemoveNo
      />
      <div className="flex justify-between items-center">
        <Typography variant="h6">Members</Typography>
        <Button size="medium" onClick={handleInviteMember}>
          Invite Member
        </Button>
      </div>
      {loading ? (
        <div className="h-screen flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <UserList
          userList={users}
          onDelete={handleUserDelete}
          roles={[]}
          setUserList={setUsers}
        />
      )}
    </div>
  );
};

export default Member;
