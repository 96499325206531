import { CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { CreateBtn } from "../atoms/CreateBtn";
import { Notification } from "../molecules/Notification";
import { INotification } from "../interfaces/General";
import { useForgotUserPasswordMutation } from "../generated/graphql";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });

  const [forgotPassword, { loading }] = useForgotUserPasswordMutation({
    variables: { email },
    onCompleted(data) {
      if (data.forgotPassword?.success) {
        setNotification({
          type: "success",
          message: `Password reset instructions sent to ${email}`,
          open: true,
        });
        setTimeout(() => {
          navigate("/login");
        }, 1500);
      }
    },
    onError(error) {
      setNotification({
        type: "error",
        message: `User not found!`,
        open: true,
      });
    },
  });

  const handleEmailChange:
    | React.ChangeEventHandler<HTMLInputElement>
    | undefined = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> | undefined = (
    event
  ) => {
    event.preventDefault();
    forgotPassword();
  };

  return (
    <div className="flex justify-center">
      <Notification {...notification} setOpen={setNotification} />
      <form onSubmit={handleSubmit} className="w-full max-w-sm">
        <div className="mb-4">
          <TextField
            type="email"
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="flex justify-center">
          <CreateBtn
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Reset Password"
            )}
          </CreateBtn>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
