import { FC, useEffect } from "react";
import { useApp } from "../states/AppContext";
import { useParams } from "react-router-dom";
declare global {
  interface Window {
    LokiChats: AbortController;
  }
}

const ChatbotWindow: FC = () => {
  const [state] = useApp();
  const { id } = useParams();
  const chatbot = state.ChatbotReducer.chatbots.find(
    (item) => item.id === state.ChatbotReducer.chatbotSelected
  );
  const channel = chatbot?.chatbotChannels[0];

  const removeChatbot = () => {
    if (window.LokiChats?.abort) {
      window.LokiChats.abort();
    }
    document.getElementById("chatbot-script")?.remove();
    document.getElementById("loki-chatbot")?.remove();
  };
  const getSrc = (scriptTag: string) => {
    return scriptTag.split("src=")[1].split(">")[0];
  };
  useEffect(() => {
    if (chatbot && id && channel) {
      removeChatbot();
      setTimeout(() => {
        const script = document.createElement("script");
        script.id = "chatbot-script";
        script.src = getSrc(channel?.scriptTag ?? "");
        document.body.appendChild(script);
      }, 1000);
    }
    return () => {
      removeChatbot();
    };
  }, [chatbot, id]);

  useEffect(() => {
    if (!id) {
      removeChatbot();
    } else {
    }
  }, [id]);

  return <></>;
};

export default ChatbotWindow;
