import { Autocomplete, TextField } from "@mui/material";
import { getTimeZones } from "@vvo/tzdb";
import { FC } from "react";

const timezones = [...getTimeZones({ includeUtc: true })];
interface TimeZoneProps {
  formValues: any;
  setFormValues: (formValue: any) => void;
}

export const TimeZone: FC<TimeZoneProps> = ({ formValues, setFormValues }) => {
  const getOptionLabel = (name: string) => {
    if (!name) {
      return "+00:00 Coordinated Universal Time (UTC)";
    }
    const timezone = timezones.find(
      (item) => item.name === name
    );
    return timezone ? timezone.rawFormat : "";
  };
  const handleChange = (value: string) => {
    if (value) {
      setFormValues({
        ...formValues,
        timezone: value,
      });
    }
  };

  return (
    <>
      <Autocomplete
        size="small"
        disablePortal
        disableClearable
        options={timezones.map((timezone) => timezone.name)}
        getOptionLabel={getOptionLabel}
        fullWidth
        value={formValues.timezone}
        onChange={(event, newValue) => {
          handleChange(newValue as string);
        }}
        renderInput={(params) => <TextField {...params} label="TimeZone" />}
      />
    </>
  );
};
