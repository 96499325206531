import { FC } from "react";
import ChatbotCreation from "../../organisms/ChatbotCreation";
import { TABENUM } from "../../organisms/ChatbotCreation/steps";
import { useParams } from "react-router-dom";

const KnowledgeBase: FC = () => {
  const { id } = useParams();
  return <ChatbotCreation id={id ?? ""} tab={TABENUM.KNOWLEDGE_BASE} />;
};

export default KnowledgeBase;
