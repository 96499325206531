import { Autocomplete, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../states/AppContext";
import { ChatbotAction } from "../interfaces/Chatbot";
import { useChatbot } from "../utils/chatbot";

const ChatbotSelection: FC = ({}) => {
  const navigation = useNavigate();
  const { id } = useParams();
  const [chatbotId, setChatbotId] = useState(id ?? "");

  const [state, dispatch] = useApp();
  const { getOneChatbot } = useChatbot();

  let chatbots = state?.ChatbotReducer?.onlyChatbots;

  const handleChange = async (value: string) => {
    if (value) {
      setChatbotId(value);
      const organizationId =
        state.ChatbotReducer.onlyChatbots[0]?.organizationId;
      dispatch({
        type: ChatbotAction.SET_CHATBOT_DATA,
        payload: { ...state.ChatbotReducer, chatbotSelected: value },
      });
      await getOneChatbot(value, organizationId);
      navigation(
        `/dashboard/chatbot/${value}/overview?organization_id=${organizationId}`
      );
    }
  };

  const getOptionLabel = (id: string) => {
    if (!id) {
      return "No Chatbots";
    }
    const chatbot = state.ChatbotReducer.onlyChatbots.find(
      (item) => item.id === id
    );
    return chatbot?.chatboxTitle ? chatbot?.chatboxTitle : "";
  };

  useEffect(() => {
    if (state.ChatbotReducer.onlyChatbots.length && !chatbotId) {
      setChatbotId(state.ChatbotReducer.onlyChatbots[0]?.id);
    }
  }, [state.ChatbotReducer]);

  return (
    <div className="mb-5 mt-5">
      <Autocomplete
        size="small"
        disablePortal
        disableClearable
        options={[...chatbots]
          ?.sort((a, b) => a.chatboxTitle.localeCompare(b.chatboxTitle))
          .map((item) => item?.id)}
        getOptionLabel={getOptionLabel}
        fullWidth
        value={chatbotId}
        onChange={(event, newValue) => {
          handleChange(newValue as string);
        }}
        renderInput={(params) => <TextField {...params} label="Chatbots" />}
      />
    </div>
  );
};

export default ChatbotSelection;
