import { useParams } from "react-router-dom";
import {
  useResponseTimeLazyQuery,
  useUserAndConversationLazyQuery,
} from "../../generated/graphql";
import { FC, useEffect } from "react";
import { UsageTabsEnum } from "../../pages/ChatbotPage/Usage";
import { CircularProgress, Typography } from "@mui/material";
import { useApp } from "../../states/AppContext";

const Statistics: FC<{ tab: UsageTabsEnum }> = ({ tab }) => {
  const [state] = useApp();
  const queryStats =
    state.UserReducer.organization.priceTierType.toLowerCase() !== "bronze";
  const { id } = useParams();
  const [getResponseTime, { loading, data: responseTime }] =
    useResponseTimeLazyQuery({
      variables: { chatbotId: id },
    });

  const [
    getUserAndConversation,
    { loading: userAndConversationLoading, data },
  ] = useUserAndConversationLazyQuery({ variables: { chatbotId: id } });
  useEffect(() => {
    if (tab === UsageTabsEnum.STATISTICS && queryStats) {
      getResponseTime();
      getUserAndConversation();
    }
  }, [tab]);

  return (
    <div className="flex flex-col w-[500px]">
      {queryStats ? (
        <>
          <div className="rounded border flex-col p-2">
            <div className="font-bold mb-2 text-center">
              Users & Conversations
            </div>
            {userAndConversationLoading ? (
              <CircularProgress />
            ) : (
              <>
                <div>
                  Number of unique chatbot users:{" "}
                  <span className="font-bold">
                    {data?.userAndConversation?.uniqueUsersCount}
                  </span>
                </div>
                <div>
                  Number of conversations:{" "}
                  <span className="font-bold">
                    {data?.userAndConversation?.conversationsCount}
                  </span>
                </div>
                <div>
                  Avg number of messages per conversation:{" "}
                  <span className="font-bold">
                    {data?.userAndConversation?.avgMessagesPerConversation}
                  </span>
                </div>
                <div>
                  Avg number of conversation per user:{" "}
                  <span className="font-bold">
                    {data?.userAndConversation?.avgConversationsPerUser}
                  </span>
                </div>
              </>
            )}
          </div>
          <div className="rounded border flex flex-col p-2">
            <div className="font-bold text-center mb-2">Response times</div>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <div className="">
                  Avg response time: {responseTime?.responseTime?.avg}s
                </div>
                <div className="">
                  Fastest response time:
                  <span className="text-green-500 pl-1">
                    {responseTime?.responseTime?.fastest}s
                  </span>
                </div>
                <div className="">
                  Longest response time:
                  <span className="text-red-500 pl-1">
                    {responseTime?.responseTime?.longest}s
                  </span>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <Typography variant="h6" className="text-black/[0.6]">
            You do not have access to Statistics. Please upgrade your
            subscription.
          </Typography>
        </>
      )}
    </div>
  );
};

export default Statistics;
