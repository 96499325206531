import { FC } from "react";
import ChatbotCreation from "../../organisms/ChatbotCreation";
import { useParams } from "react-router-dom";

const ChatbotFormPage: FC = () => {
  const { id = "" } = useParams();
  return <ChatbotCreation id={id} />;
};

export default ChatbotFormPage;
