import { useCallback, useState } from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import { useUser } from "../utils/auth";

function isFileTypeAccepted(
  fileType: string,
  acceptedFileTypes: string[]
): boolean {
  return acceptedFileTypes.some((type) => fileType.includes(type));
}

interface Props {
  acceptedFileTypes: string[];
  onFileUploaded: (file: File) => void;
  isMultiple?: boolean;
}

const FileUpload: React.FC<Props> = ({
  acceptedFileTypes,
  isMultiple,
  onFileUploaded,
}) => {
  const { user } = useUser();

  const [fileError, setFileError] = useState<string>("");
  const acceptDragFile = (file: File) => {
    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB > user.organization.priceTierConfig.fileSizeLimit) {
      setFileError(
        `File size should not exceed ${user.organization.priceTierConfig.fileSizeLimit}MB`
      );
      return;
    }
    if (isFileTypeAccepted(file.type, acceptedFileTypes)) {
      onFileUploaded(file);
      setFileError("");
    } else {
      setFileError(
        //"Only docx, pdf, xlsx, csv, pdf, pptx, txt files are allowed"
        `Only ${acceptedFileTypes.join(", ")} files are currently allowed.`
      );
    }
  };
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        if (isMultiple) {
          acceptedFiles.forEach((file, index) => {
            acceptDragFile(file);
          });
        } else {
          const file = acceptedFiles[0];
          acceptDragFile(file);
        }
      }
    },
    [onFileUploaded]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes.join(","),
    multiple: isMultiple ?? false,
  } as unknown as DropzoneOptions);

  return (
    <div
      {...getRootProps()}
      className="border-dashed border-2 p-5 mt-2 mb-2 border-gray-300"
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>
          Drag 'n' drop a {acceptedFileTypes.join(", ")} file here, or click to
          select a file
        </p>
      )}
      {fileError && <p className="text-red-500">{fileError}</p>}
    </div>
  );
};

export default FileUpload;
