import { Link, Outlet, useLocation } from "react-router-dom";
import { CreateBtn } from "../../atoms/CreateBtn";

const PreLoginPage = () => {
  const location = useLocation();
  const isSelectOrganization = location.pathname.includes(
    "select-organization"
  );
  const getTitle = () => {
    if (isSelectOrganization) {
      return "Select Organization";
    } else if (location.pathname.includes("activate")) {
      return "Activate your account";
    } else if (location.pathname.includes("forgot-password")) {
      return "Forgot Password";
    } else if (location.pathname.includes("reset-password")) {
      return "Reset Password";
    } else if (location.pathname.includes("signup")) {
      return "Signup";
    } else if (location.pathname.includes("login")) {
      return "Login";
    }
  };
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 flex flex-col justify-center">
        {isSelectOrganization && (
          <div className="flex justify-end mb-4">
            <Link to="/login/master-admin-create-organization">
              <CreateBtn variant="contained" color="primary">
                Create Organization
              </CreateBtn>
            </Link>
          </div>
        )}
        <div className="flex justify-center sm:w-full mt-8">
          <img
            className="logo-login"
            src="/icons/logo.png"
            alt="lokibots chats"
          />
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl text-gray-900">
            {getTitle()}
          </h2>
        </div>
        <div
          className={`mt-0 sm:mx-auto sm:w-full ${
            isSelectOrganization ? "" : "sm:max-w-md"
          }`}
        >
          <div className="py-8 px-4 sm:rounded-lg sm:px-10 text-center">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreLoginPage;
