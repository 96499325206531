import { FC } from "react";
import ChatbotTabs from "../../UI/ChatbotTabs";
import { IChatbotTabs } from "../../../interfaces/Chatbot";

import ChatbotDesign from "./ChatbotDesign";
import ChatbotWindow from "./ChatbotWindow";
import { ChatbotFormType } from "..";

import { configModalEnum } from "../Channel";

export interface ChatbotModalProps {
  formValues: ChatbotFormType;
  setFormValues: React.Dispatch<React.SetStateAction<ChatbotFormType>>;
  handleChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    checked?: boolean | string
  ) => void;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  open: boolean;
  activeTab: configModalEnum | null;
}
const getTabList = (props: ChatbotModalProps): IChatbotTabs => [
  { label: "Design", component: () => <ChatbotDesign {...props} /> },
  { label: "Deploy", component: () => <ChatbotWindow {...props} /> },
];

const Website: FC<ChatbotModalProps> = (props) => {
  const activeTab = props.activeTab;

  let tabs = getTabList(props);

  if (activeTab === configModalEnum.CONFIG) {
    tabs = tabs.filter((tab) => tab.label === "Deploy");
  } else if (activeTab === configModalEnum.DESIGN) {
    tabs = tabs.filter((tab) => tab.label === "Design");
  }

  return <ChatbotTabs tabs={tabs} />;
};

export default Website;
