import ChatbotCreation from "../../organisms/ChatbotCreation";
import { TABENUM } from "../../organisms/ChatbotCreation/steps";
import { useParams } from "react-router-dom";

const Channels = () => {
  const { id } = useParams();

  return <ChatbotCreation id={id ?? ""} tab={TABENUM.CHANNEL} />;
};

export default Channels;
