import { PriceTierTypeEnum } from "../../interfaces/Organization";
import { IUser, IUserState, UserAction } from "../../interfaces/Users";

export const userInitialState: IUser = {
  email: "",
  name: "",
  dateJoined: "",
  id: "",
  organization: {
    id: "",
    name: "",
    numberOfChatbots: 0,
    monthlyQuestionsLimit: 0,
    storageLimit: 0,
    timezone: "",
    priceTierConfig: {
      numberOfChatbots: 0,
      numberOfFilePerBot: 0,
      fileSizeLimit: 0,
      storageLimit: 0,
      monthlyQuestionsLimit: 0,
      chabotBranding: {
        logo: false,
        colors: false,
      },
      acl: false,
      price: 0,
    },
    priceTierType: PriceTierTypeEnum.BRONZE,
  },
  role: {
    id: "",
    role: "",
  },
};

export const UserReducer = (
  state: IUser = userInitialState,
  action: IUserState
) => {
  switch (action.type) {
    case UserAction.SET_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
  }
};
