import {
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { FC, useEffect, useState } from "react";
import {
  OrganizationType,
  useOrganizationsLazyQuery,
} from "../generated/graphql";
import { Link, useNavigate } from "react-router-dom";
import { useChatbot } from "../utils/chatbot";
import { useUser } from "../utils/auth";
interface UserOrganizationSelectType {
  value: string;
  setValue: (value: string) => void;
  componentyType?: "select" | "table";
}
export const UserOrganizationSelect: FC<UserOrganizationSelectType> = ({
  value,
  setValue,
  componentyType = "select",
}) => {
  const navigate = useNavigate();

  const { getOnlyChatbots, getOneChatbot, loading } = useChatbot();
  const { loginUser } = useUser();
  const [organizations, setOrganizations] = useState<OrganizationType[]>([]);

  const [getOrganizations, { loading: loadingOrganization }] =
    useOrganizationsLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data.organizations && data.organizations?.length) {
          setOrganizations(data.organizations as OrganizationType[]);
          setValue(data.organizations[0]?.id);
        }
      },
    });
  const handleChange = (event: SelectChangeEvent<string>) => {
    setValue(event.target.value);
  };

  const manageBots = async (organizationId: string) => {
    const chatbots = await getOnlyChatbots(organizationId);

    await loginUser(organizationId);
    if (chatbots.data?.chatbots?.length) {
      const chatbotId = chatbots.data?.chatbots[0]?.id;
      await getOneChatbot(chatbotId, organizationId);
      navigate(
        `/dashboard/chatbot/${chatbotId}/overview?organization_id=${organizationId}`
      );
    } else {
      navigate("/dashboard/chatbot?organization_id=" + organizationId);
    }
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  if (loadingOrganization) {
    return <CircularProgress size={48} />;
  }
  if (componentyType === "select") {
    return (
      <Select
        value={value}
        label="Organization"
        size="medium"
        onChange={handleChange}
      >
        {organizations.map((organization) => (
          <MenuItem key={organization.id} value={organization.id}>
            {organization.name}
          </MenuItem>
        ))}
      </Select>
    );
  }
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Organization</TableCell>
            <TableCell>Pricing Tier</TableCell>
            <TableCell>Bots</TableCell>
            <TableCell>Queries</TableCell>
            <TableCell>Storage (MB)</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {organizations.map((organization) => (
            <TableRow key={organization.id}>
              <TableCell>{organization.name}</TableCell>
              <TableCell>{organization.priceTierType}</TableCell>
              <TableCell>
                {organization.numberOfChatbots}/
                {organization.priceTierType === "unlimited"
                  ? "∞"
                  : organization.priceTierConfig?.numberOfChatbots}
              </TableCell>
              <TableCell>
                {organization.monthlyQuestionsLimit}/
                {organization.priceTierType === "unlimited" ||
                organization.priceTierType === "platinum"
                  ? "∞"
                  : organization.priceTierConfig?.monthlyQuestionsLimit}
              </TableCell>
              <TableCell>
                {organization.storageLimit?.toFixed(2)}/
                {organization.priceTierType === "unlimited" ||
                organization.priceTierType === "platinum"
                  ? "∞"
                  : organization.priceTierConfig?.storageLimit}
              </TableCell>
              <TableCell>
                <div className="flex">
                  <Link
                    to={`/login/master-admin-create-organization/${organization.id}`}
                    className="mr-2 underline cursor-pointer"
                  >
                    Edit
                  </Link>

                  <a
                    className="mr-2 underline cursor-pointer"
                    onClick={() => !loading && manageBots(organization.id)}
                  >
                    {loading ? <CircularProgress /> : "Manage Bots"}
                  </a>
                  {/* <a className="mr-1 underline cursor-pointer">Delete</a> */}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
