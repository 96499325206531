import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { purple } from '@mui/material/colors';

export const SecondaryBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "rgb(62, 59, 52)",
  // background: "linear-gradient(113.62deg, rgba(138, 35, 135, 0.1) 24.45%, rgba(233, 64, 87, 0.1) 58.83%, rgba(242, 113, 33, 0.1) 94.26%) repeat scroll 0% 0% / auto padding-box border-box;",
  // border: "1px solid rgb(62, 59, 52)",
  borderRadius: 32,
  background: "white",
  textTransform: "none",
  fontSize: "13px",
  boxShadow: "none",
  transition: "all ease 0.3s",
  '&:hover': {
    background: "white",
    backgroundColor: "rgba(138, 35, 135, 0.1)",
    boxShadow: "none",
  },
}));
