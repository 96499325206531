import { IOrganization } from "./Organization";

export interface IUser {
  id: string;
  name: string;
  email: string;
  dateJoined: string;
  organization: IOrganization;
  isActive?: boolean;
  role?: {
    id: string;
    role: string;
  };
  isOwner?: boolean;
  isMaster?: boolean;
}

export interface IUserState {
  type: UserAction;
  payload: IUser;
}

export enum UserAction {
  SET_USER_DATA = 1000,
}
