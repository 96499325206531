export interface IPriceTierConfig {
  numberOfChatbots: number;
  numberOfFilePerBot: number;
  fileSizeLimit: number;
  storageLimit: number;
  monthlyQuestionsLimit: number;
  chabotBranding: {
    logo: boolean;
    colors: boolean;
  };
  acl: boolean;
  price: number;
}

export enum PriceTierTypeEnum {
  BRONZE = "bronze",
  SILVER = "silver",
  GOLD = "gold",
  PLATINUM = "platinum",
  UNLIMITED = "unlimited",
}

export interface IOrganization {
  id: string;
  name: string;
  timezone: string;
  numberOfChatbots: number;
  monthlyQuestionsLimit: number;
  storageLimit: number;
  priceTierConfig: IPriceTierConfig;
  priceTierType: PriceTierTypeEnum;
}
