import { FC, useCallback } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  styled,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { Link, useLocation } from "react-router-dom";
import GridViewIcon from "@mui/icons-material/GridView";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import InfoIcon from "@mui/icons-material/Info";
import { Chat } from "@mui/icons-material";

const SideMenuAdminArea = [
  {
    label: "Dashboard",
    icon: GridViewIcon,
    url: "/dashboard/admin",
  },
  { icon: SettingsIcon, label: "Settings", url: "/dashboard/admin/settings" },
  { icon: PeopleIcon, label: "Members", url: "/dashboard/admin/members" },
  {
    icon: ChatBubbleOutlineIcon,
    label: "Chatbots",
    url: "/dashboard/admin/chatbots",
  },

  // {
  //   icon: ManageAccountsIcon,
  //   label: "Roles",
  //   url: "/dashboard/admin/roles-list",
  // },
  //{ icon: PaymentIcon, label: "Billing", url: "/dashboard/admin/billing" },
];

const ListItemIconCustom = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const SideMenu = (id?: string) => [
  {
    icon: GridViewIcon,
    label: "Overview",
    url: `/dashboard/chatbot/${id}/overview`,
  },
  {
    icon: Chat,
    label: "Usage",
    url: `/dashboard/chatbot/${id}/usage`,
  },
  {
    icon: InfoIcon,
    label: "Configuration",
    url: `/dashboard/chatbot/${id}/configuration`,
  },
  {
    icon: PermDataSettingIcon,
    label: "Knowledge Base",
    url: `/dashboard/chatbot/${id}/knowledge-base`,
  },
  {
    icon: DesignServicesIcon,
    label: "Automation",
    url: `/dashboard/chatbot/${id}/automation`,
  },
  {
    icon: RocketLaunchIcon,
    label: "Channels",
    url: `/dashboard/chatbot/${id}/channels`,
  },
  {
    icon: DataUsageIcon,
    label: "Personalisation",
    url: `/dashboard/chatbot/${id}/personalisation`,
  },
];

const DashboardMenu: FC<{
  plan: string;
  isOwner?: boolean;
  isAdminArea: boolean;
  id?: string;
}> = ({ plan, isOwner, isAdminArea, id }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const organizationId = queryParams.get("organization_id");
  const getMenus = useCallback(() => {
    if (isOwner && isAdminArea) {
      if (plan !== "unlimited") return SideMenuAdminArea.slice(1);
      else return SideMenuAdminArea;
    }

    return id ? SideMenu(id) : [];
  }, [isAdminArea, isOwner]);

  return (
    <MenuList>
      {getMenus().map((item) => (
        <Link
          to={item.url + `?organization_id=${organizationId}`}
          key={item.url}
        >
          <MenuItem selected={item.url === location.pathname}>
            <ListItemIconCustom>{<item.icon />}</ListItemIconCustom>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        </Link>
      ))}
    </MenuList>
  );
};

export default DashboardMenu;
