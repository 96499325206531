import { FC, useEffect, useState } from "react";
import FormItemContainer from "../../atoms/FormItemContainer";
import { FormControlLabel, Switch, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  MAX_CHATBOX_TITLE_SIZE,
  MAX_DESCRIPTION_SIZE,
  MAX_WELCOME_MESSAGE_SIZE,
} from "../../utils/config";
import { ChatbotFormType } from ".";
import AlertDialog from "../../molecules/Dialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { SecondaryBtn } from "../../atoms/SecondaryBtn";
import { CreateBtn } from "../../atoms/CreateBtn";

export interface HelperQuestionsProps {
  setFormValues: React.Dispatch<React.SetStateAction<ChatbotFormType>>;
  formValues: ChatbotFormType;
  loading: boolean;
  handleChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    checked?: boolean
  ) => void;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  open: boolean;
}

const HelperQuestions: FC<HelperQuestionsProps> = ({
  formValues,
  loading,
  setFormValues,
  onSubmit,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);

  const handleAddRow = () => {
    console.log("formValues", formValues);
    setFormValues({
      ...formValues,
      helpQuestions: [...formValues.helpQuestions, { question: "", id: "" }],
    });
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> | undefined = (
    event
  ) => {
    event.preventDefault();
    setIsSubmitted(true);

    onSubmit(event);
  };

  const handleQuestionChange = (index: number, value: string) => {
    const updatedData = [...formValues.helpQuestions];
    updatedData[index].question = value;
    setFormValues({ ...formValues, helpQuestions: updatedData });
  };

  const handleRemoveRow = (index: number) => {
    const updatedData = [...formValues.helpQuestions];
    updatedData.splice(index, 1);
    setFormValues({ ...formValues, helpQuestions: updatedData });
  };

  const handleDelete = (index: number) => {
    if (formValues.helpQuestions[index].question) {
      setOpen(true);
      setQuestionIndex(index);
    } else {
      handleRemoveRow(index);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <AlertDialog
          open={open}
          description="Are you sure you want to delete this question?"
          title="Delete"
          handleClose={() => setOpen(false)}
          handleConfirm={() => handleRemoveRow(questionIndex)}
        />
        {formValues.helpQuestions.map((row, index) => (
          <div
            key={row.id || index}
            className="flex space-x-4 items-center hover:bg-gray-100 p-2"
          >
            <div style={{ flex: 2 }}>
              <div className="flex items-center">
                <span className="mr-1"> {index + 1}.</span>
                <TextField
                  label="Question"
                  variant="outlined"
                  multiline
                  size="small"
                  value={row.question}
                  fullWidth
                  onChange={(e) => handleQuestionChange(index, e.target.value)}
                />
              </div>
            </div>

            {!loading && (
              <HighlightOffIcon
                onClick={() => handleDelete(index)}
                className="cursor-pointer"
              />
            )}
          </div>
        ))}
        <div className="flex justify-center">
          <SecondaryBtn
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            disabled={loading}
            onClick={handleAddRow}
          >
            New Question
          </SecondaryBtn>
        </div>
      </form>
    </>
  );
};

export default HelperQuestions;
