import { FC, useEffect, useState } from "react";
import { MuiColorInput, MuiColorInputColors } from "mui-color-input";
import { v4 as uuidv4 } from "uuid";

import FileUpload from "../molecules/FileUpload";
import {
  Typography,
  Grid,
  Paper,
  styled,
  Slider,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import FormItemContainer from "../atoms/FormItemContainer";
import { useRemoveChannelProfileLogoMutation } from "../generated/graphql";
import { useApp } from "../states/AppContext";
import { Notification } from "../molecules/Notification";
import { INotification } from "../interfaces/General";
import DocumentList from "../molecules/DocumentList";
import { IDocument } from "../interfaces/Documents";
import { useParams } from "react-router-dom";
import { ChatbotModalProps } from "./ChatbotCreation/Website";
import { customFonts } from "../constants";
import { useUser } from "../utils/auth";
import {
  UpgradeActionEnum,
  UpgradeLokichat,
} from "../molecules/PriceTier/UpgradeLokichat";
const acceptedFileTypes = ["png", "jpg", "jpeg"];

export const defaultColor = "#" + process.env.REACT_APP_CHATBOT_COLOR ?? "";
export const defaultHoverColor =
  "#" + process.env.REACT_APP_CHATBOT_COLOR ?? "";
export const defaultLogoSize = parseInt(
  process.env.REACT_APP_CHATBOT_LOGO_SIZE ?? ""
);
export const defaultTitleSize = parseInt(
  process.env.REACT_APP_CHATBOT_TITLE_SIZE ?? ""
);
export const defaultTitleColor =
  "#" + process.env.REACT_APP_CHATBOT_TITLE_COLOR ?? "";
export const defaultFont = process.env.REACT_APP_CHATBOT_FONT ?? "";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const Design: FC<ChatbotModalProps> = ({ formValues, setFormValues }) => {
  const [state] = useApp();
  const { user } = useUser();

  const { id } = useParams();
  const chatbot = state.ChatbotReducer.chatbots.find((item) => item.id === id);
  const channelId = state.ChatbotReducer.channelId;
  const channel = chatbot?.chatbotChannels.find(
    (item) => item.id === channelId
  );
  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [documents, setDocuments] = useState<IDocument[]>(
    channel?.channelProfile?.logo
      ? [
          {
            type: channel?.channelProfile?.fileType ?? "",
            name: channel?.channelProfile?.fileName ?? "",
            id: channel?.channelProfile?.id ?? "",
            url: channel?.channelProfile?.logo ?? "",
          },
        ]
      : formValues.design.logo
  );

  const [color, setColor] = useState<string>(formValues.design.color);
  const [hoverColor, setHoverColor] = useState<string>(
    formValues.design.hoverColor
  );
  const [logoSize, setLogoSize] = useState<number>(formValues.design.logoSize);
  const [chatbotTitleSize, setTitleSize] = useState<number>(
    formValues.design.chatbotTitleSize
  );
  const [chatbotTitleColor, setTitleColor] = useState<string>(
    formValues.design.chatbotTitleColor
  );
  const [chatbotFont, setFont] = useState<string>(
    formValues.design.chatbotFont
  );
  const [file, setFile] = useState<File | undefined>(formValues.design.file);
  const [removeChannelProfileLogo, { loading: lodaingDeleteDocument }] =
    useRemoveChannelProfileLogoMutation({
      onCompleted(data) {
        if (data.removeChannelProfileLogo?.id) {
          setDocuments([]);
          setFile(undefined);
          setNotification({
            message: "Chatbot logo successfully removed.",
            open: true,
            type: "success",
          });
        } else {
          setNotification({
            message: "Chatbot logo couldn't be removed.",
            open: true,
            type: "error",
          });
        }
      },
      onError(error) {
        if (error.message === "Chatbot not found") {
          setDocuments([]);
          setFile(undefined);
        } else {
          setNotification({
            message: error.message,
            open: true,
            type: "error",
          });
        }
      },
    });

  const handleChange = (_: string, colors: MuiColorInputColors) => {
    setColor(colors.hex8);
  };

  const handleChangeHover = (_: string, colors: MuiColorInputColors) => {
    setHoverColor(colors.hex8);
  };

  const handleLogoChange = (file: File) => {
    if (file) {
      setFile(file);
      setDocuments([
        {
          name: file.name,
          type: file.type,
          id: channel?.channelProfile?.id ?? uuidv4(),
        },
      ]);
    }
  };

  const handleDocumentDelete = async (file: IDocument) => {
    removeChannelProfileLogo({ variables: { id: file.id } });
  };

  const handleFontChange = (event: SelectChangeEvent) => {
    setFont(event.target.value as string);
  };
  const handleLogoSizeChange = (
    event: Event,
    value: any,
    activeThumb: number
  ) => {
    setLogoSize(value);
  };
  const handleTitleSizeChange = (
    event: Event,
    value: any,
    activeThumb: number
  ) => {
    setTitleSize(value);
  };
  const handleTitleColorChange = (_: string, colors: MuiColorInputColors) => {
    setTitleColor(colors.hex8);
  };

  useEffect(() => {
    if (channel) {
      setColor(channel?.channelProfile?.color ?? defaultColor);
      setHoverColor(channel?.channelProfile?.hoverColor ?? defaultHoverColor);
      setLogoSize(channel?.channelProfile?.logoSize ?? defaultLogoSize);
      setTitleSize(
        channel?.channelProfile?.chatbotTitleSize ?? defaultTitleSize
      );
      setTitleColor(
        channel?.channelProfile?.chatbotTitleColor ?? defaultTitleColor
      );
      setFont(channel?.channelProfile?.chatbotFont ?? defaultFont);
      setDocuments(
        channel?.channelProfile?.logo
          ? [
              {
                type: channel?.channelProfile?.fileType ?? "",
                name: channel?.channelProfile?.fileName ?? "",
                id: channel?.channelProfile?.id ?? "",
                url: channel?.channelProfile?.logo ?? "",
              },
            ]
          : []
      );
    }
  }, [channel]);

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      design: {
        ...prevState.design,
        file,
        color,
        hoverColor,
        logoSize,
        chatbotFont,
        chatbotTitleColor,
        chatbotTitleSize,
        logo: documents,
      },
    }));
  }, [
    color,
    hoverColor,
    documents,
    file,
    logoSize,
    chatbotFont,
    chatbotTitleColor,
    chatbotTitleSize,
  ]);
  return (
    <Grid container>
      <Notification {...notification} setOpen={setNotification} />
      <Grid item xs={12} style={{ marginBottom: 40, marginLeft: 92 }}>
        <div
          className={`font-bold text-[48px] flex h-[48px] w-[400px] rounded-md items-center`}
          style={{ background: color }}
        >
          <div className={`h-[48px] min-w-[48px] flex ml-[8px]`}>
            <img
              className={`m-auto`}
              style={{ height: logoSize + "px" }}
              src={
                documents[0]?.url
                  ? "https://storage.googleapis.com/chatbot_logo/" +
                    documents[0].url
                  : file
                  ? URL.createObjectURL(file as Blob)
                  : "https://storage.googleapis.com/chatbot-chatwindows/logo.png"
              }
              alt="logo"
            />
          </div>
          <span
            className={`ml-[8px]`}
            style={{
              color: chatbotTitleColor,
              fontFamily: chatbotFont,
              fontSize: chatbotTitleSize + "px",
            }}
          >
            {formValues.chatboxTitle}
          </span>
        </div>
      </Grid>
      <Grid item xs={6}>
        <Item>
          <Typography variant="h6">Color</Typography>
          <FormItemContainer>
            <>
              <MuiColorInput value={color} onChange={handleChange} />
            </>
          </FormItemContainer>
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item>
          <Typography variant="h6">Hover Color</Typography>
          {!user.organization.priceTierConfig.chabotBranding.colors ? (
            <UpgradeLokichat action={UpgradeActionEnum.CHATBOT_BRANDING} />
          ) : (
            <FormItemContainer>
              <>
                <MuiColorInput
                  value={hoverColor}
                  onChange={handleChangeHover}
                />
              </>
            </FormItemContainer>
          )}
        </Item>
      </Grid>
      <Grid item xs={3}>
        <Item>
          <Typography variant="h6">Logo Size</Typography>
          <FormItemContainer>
            <>
              <Slider
                value={logoSize}
                max={48}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={handleLogoSizeChange}
              />
            </>
          </FormItemContainer>
        </Item>
      </Grid>
      <Grid item xs={3}>
        <Item>
          <Typography variant="h6">Title Size</Typography>
          <FormItemContainer>
            <>
              <Slider
                value={chatbotTitleSize}
                max={48}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={handleTitleSizeChange}
              />
            </>
          </FormItemContainer>
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item>
          <Typography variant="h6">Title Color</Typography>
          <FormItemContainer>
            <>
              <MuiColorInput
                value={chatbotTitleColor}
                onChange={handleTitleColorChange}
              />
            </>
          </FormItemContainer>
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item>
          <Typography variant="h6">Font</Typography>
          <FormItemContainer>
            <>
              <Select
                className="text-black w-full"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={chatbotFont}
                onChange={handleFontChange}
              >
                {customFonts.map((fontInfo, idx) => (
                  <MenuItem value={fontInfo.fontFamily}>
                    {fontInfo.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          </FormItemContainer>
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item>
          <Typography variant="h6">Logo</Typography>
          <FormItemContainer>
            <>
              {!user.organization.priceTierConfig.chabotBranding.logo ? (
                <UpgradeLokichat action={UpgradeActionEnum.CHATBOT_BRANDING} />
              ) : (
                <FileUpload
                  acceptedFileTypes={acceptedFileTypes}
                  onFileUploaded={handleLogoChange}
                />
              )}
              {!!documents.length && (
                <DocumentList
                  documentList={documents}
                  onDelete={handleDocumentDelete}
                />
              )}
            </>
          </FormItemContainer>
        </Item>
      </Grid>
      {/* {!isModal && (
        <Grid item xs={12} style={{ marginTop: 50 }}>
          <Item>
            <FormItemContainer>
              <CreateActionBtn
                children={
                  loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Update"
                  )
                }
                type="submit"
                onClick={onSubmit}
                disabled={loading || lodaingDeleteDocument}
              />
            </FormItemContainer>
          </Item>
        </Grid>
      )} */}
    </Grid>
  );
};

export default Design;
