import { FC, useEffect, useState } from "react";
import FormItemContainer from "../../../atoms/FormItemContainer";
import { v4 as uuidv4 } from "uuid";
import { InputLabel } from "@mui/material";
import FileUpload from "../../../molecules/FileUpload";
import DocumentList from "../../../molecules/DocumentList";
import {
  ChatbotType,
  useDeleteDocumentMutation,
  useOrganizationStatsLazyQuery,
} from "../../../generated/graphql";
import { useApp } from "../../../states/AppContext";
import { INotification } from "../../../interfaces/General";
import { IDocument } from "../../../interfaces/Documents";
import { ChatbotAction } from "../../../interfaces/Chatbot";
import { useParams } from "react-router-dom";
import { KnowledgeBaseProps } from ".";
import { Notification } from "../../../molecules/Notification";
import { isQuestionPair } from "../utils";
import {
  UpgradeActionEnum,
  UpgradeLokichat,
} from "../../../molecules/PriceTier/UpgradeLokichat";
import { useUser } from "../../../utils/auth";
import { UserAction } from "../../../interfaces/Users";

const acceptedFileTypes = [
  "pdf",
  "docx",
  "doc",
  "xlsx",
  "xls",
  "csv",
  "pptx",
  "ppt",
  "txt",
  "text",
];

const getDocumentFromChatbot = (chatbot: ChatbotType) => {
  return chatbot.documents
    .filter((item) => !isQuestionPair(item))
    .map((item) => ({
      name: item.fileName ?? "",
      type: item.fileType ?? "",
      id: item.id,
    }));
};

const KnowledgeBaseFiles: FC<KnowledgeBaseProps> = ({
  files,
  open,
  formValues,
  setFormValues,
  setFiles,
}) => {
  const { id } = useParams();
  const [state, dispatch] = useApp();
  const { user } = useUser();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const chatbot = state.ChatbotReducer.chatbots.find((item) => item.id === id);

  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [getOrganizationStats] = useOrganizationStatsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(data) {
      dispatch({
        type: UserAction.SET_USER_DATA,
        payload: {
          ...state.UserReducer,
          organization: {
            ...state.UserReducer.organization,
            numberOfChatbots: data.organization?.numberOfChatbots ? data.organization?.numberOfChatbots : 0,
            monthlyQuestionsLimit: data.organization?.monthlyQuestionsLimit ? data.organization?.monthlyQuestionsLimit : 0,
            storageLimit: data.organization?.storageLimit ? data.organization?.storageLimit : 0,

          }
        },
      });
    },
  });
  const [deleteDocumentData] = useDeleteDocumentMutation({
    onCompleted(data) {
      if (data.deleteDocument?.success) {
        setNotification({
          type: "success",
          message: "Document was successfuly removed",
          open: true,
        });
        getOrganizationStats({
          variables: {
            organizationId: state.UserReducer.organization.id
          }
        })
      } else {
        setNotification({
          type: "error",
          message: "Something happened during remove",
          open: true,
        });
      }
    },
    onError(error) {
      setNotification({
        type: "error",
        message: error.message,
        open: true,
      });
    },
  });
  const [documents, setDocuments] = useState<IDocument[]>(
    chatbot ? getDocumentFromChatbot(chatbot) : formValues.documents
  );

  const handleDocumentChange = (file: File) => {
    if (file) {
      setFiles((files) => [...files, file]);
      setDocuments((documents) => [
        ...documents,
        { name: file.name, type: file.type, id: uuidv4() },
      ]);
    }
  };

  const handleDocumentDelete = async (file: IDocument) => {
    setDocuments(documents.filter((item) => item.id !== file.id));
    setFiles(files.filter((item) => item.name !== item.name));
    if (!chatbot) return;
    const document = await deleteDocumentData({ variables: { id: file.id } });
    if (document.data?.deleteDocument?.success) {
      dispatch({
        type: ChatbotAction.SET_CHATBOT_DATA,
        payload: {
          ...state.ChatbotReducer,
          chatbots: state.ChatbotReducer.chatbots.map((item) => {
            if (chatbot?.id === item.id) {
              item.documents = item.documents.filter(
                (doc) => doc.id !== file.id
              );
            }
            return item;
          }),
        },
      });
    }
  };

  useEffect(() => {
    if (open) {
      setIsSubmitted(true);
    }
  }, [open]);

  useEffect(() => {
    setFormValues((prevState) => ({ ...prevState, documents }));
  }, [documents]);

  useEffect(() => {
    if (chatbot) {
      setDocuments(getDocumentFromChatbot(chatbot));
    }
  }, [chatbot]);
  return (
    <FormItemContainer>
      <>
        <Notification {...notification} setOpen={setNotification} />
        <div className="text-left">
          <InputLabel>Documents</InputLabel>
        </div>
        {(chatbot?.documents?.length ?? 0) >=
          user.organization.priceTierConfig.numberOfFilePerBot ||
          documents.length >=
          user.organization.priceTierConfig.numberOfFilePerBot ? (
          <UpgradeLokichat action={UpgradeActionEnum.UPLOAD_MORE_FILE} />
        ) : (
          <FileUpload
            acceptedFileTypes={acceptedFileTypes}
            onFileUploaded={handleDocumentChange}
            isMultiple
          />
        )}
        {documents.length ? (
          <DocumentList
            documentList={documents}
            onDelete={handleDocumentDelete}
          />
        ) : null}
      </>
    </FormItemContainer>
  );
};

export default KnowledgeBaseFiles;
