import { FC, useState } from "react";
import FormItemContainer from "../atoms/FormItemContainer";
import {
  CircularProgress,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { RoleType, useInviteUserMutation } from "../generated/graphql";
import { Notification } from "../molecules/Notification";
import { INotification } from "../interfaces/General";
import { CreateBtn } from "../atoms/CreateBtn";
import { useLocation } from "react-router-dom";

const InviteMember: FC<{ onSuccess: () => void; roles: RoleType[] }> = ({
  roles,
  onSuccess,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const organizationId = queryParams.get("organization_id");

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    role: "",
    isOwner: false,
  });
  const [notification, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "error",
  });
  const [inviteUser, { loading }] = useInviteUserMutation({
    variables: {
      email: formValues.email,
      name: formValues.name,
      isOwner: formValues.isOwner,
      organizationId: organizationId,
      ...(formValues.role ? { role: formValues.role } : {}),
    },
    onCompleted(data) {
      if (data.register && data.register.success) {
        onSuccess();
      } else {
        setNotification({
          message: "Error happened while sending invitation",
          open: true,
          type: "error",
        });
      }
    },
    onError(error) {
      setNotification({
        message: error?.message ?? "",
        open: true,
        type: "error",
      });
    },
  });

  const handleChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClick = async () => {
    await inviteUser();
  };

  return (
    <div>
      <Notification {...notification} setOpen={setNotification} />
      <FormItemContainer>
        <TextField
          label="Email"
          value={formValues.email}
          name="email"
          onChange={handleChange}
          size="small"
          required
          fullWidth
        />
      </FormItemContainer>
      <FormItemContainer>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>User</Typography>
          <Switch
            disabled={loading}
            onChange={(e) =>
              setFormValues({ ...formValues, isOwner: e.target.checked })
            }
          />
          <Typography>Admin</Typography>
        </Stack>
        {/* <AssignRole
          roles={roles}
          getRole={(role, id) => setFormValues({ ...formValues, role: id })}
        /> */}
      </FormItemContainer>
      <FormItemContainer>
        <TextField
          label="Name"
          placeholder="Optional"
          value={formValues.name}
          name="name"
          onChange={handleChange}
          size="small"
          fullWidth
        />
      </FormItemContainer>

      <FormItemContainer>
        <CreateBtn
          variant="contained"
          color="primary"
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Invite"}
        </CreateBtn>
      </FormItemContainer>
    </div>
  );
};

export default InviteMember;
